import * as Types from '../../../generated/types';

import { gql } from '@apollo/client';
export type ListPatrolFragment = { __typename?: 'Patrol', id: any, startDate: any, endDate?: Types.Maybe<any> };

export type PatrolEventFragment = { __typename?: 'PatrolEvent', id: any, eventDate: any, location?: Types.Maybe<string>, name?: Types.Maybe<string>, description?: Types.Maybe<string>, action?: Types.Maybe<string>, createdAt: any, updatedAt: any };

export type PatrolFragment = { __typename?: 'Patrol', id: any, startDate: any, endDate?: Types.Maybe<any>, description?: Types.Maybe<string>, location?: Types.Maybe<string>, createdAt: any, updatedAt: any, assignees?: Types.Maybe<Array<{ __typename?: 'User', id: any, firstName?: Types.Maybe<string>, lastName?: Types.Maybe<string>, email: any }>>, createdBy?: Types.Maybe<{ __typename?: 'User', id: any, firstName?: Types.Maybe<string>, lastName?: Types.Maybe<string>, email: any }> };

export const ListPatrolFragmentDoc = gql`
    fragment ListPatrol on Patrol {
  id
  startDate
  endDate
}
    `;
export const PatrolEventFragmentDoc = gql`
    fragment PatrolEvent on PatrolEvent {
  id
  eventDate
  location
  name
  description
  action
  createdAt
  updatedAt
}
    `;
export const PatrolFragmentDoc = gql`
    fragment Patrol on Patrol {
  id
  startDate
  endDate
  description
  location
  assignees {
    id
    firstName
    lastName
    email
  }
  createdBy {
    id
    firstName
    lastName
    email
  }
  createdAt
  updatedAt
}
    `;