import { ApolloError } from "@apollo/client";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../../../components/ErrorDisplay/ErrorDisplay";
import FullScreenLoader from "../../../../../../components/FullScreenLoader/FullScreenLoader";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

interface Props {
  loading?: boolean;
  onPatrolEventDelete: () => void;
  error?: ApolloError;
}
export default function PatrolEventMoreMenuView({
  loading,
  onPatrolEventDelete,
  error,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <FullScreenLoader open={loading} />
      <IconButton
        size="small"
        aria-controls="more-menu"
        aria-haspopup="true"
        onClick={handleButtonClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={onPatrolEventDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>{t("Delete event")}</ListItemText>
        </MenuItem>
      </Menu>
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while deleting patrol event, please try again or contact us."
        )}
      />
    </>
  );
}
