import { Box, Dialog, IconButton, makeStyles } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import React from "react";

interface Props {
  imgSrc: string;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  img: { height: "auto", width: "100%" },
  closeIcon: { position: "absolute", right: 0, zIndex: 10 },
});

export default function ImageDialog({ imgSrc, onClose, open }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Dialog open={open} fullWidth fullScreen onClose={onClose}>
      <Box className={classes.closeIcon}>
        <IconButton onClick={(): void => onClose()}>
          <HighlightOffIcon color="secondary" />
        </IconButton>
      </Box>
      <Box width="100%" height="100%" display="flex" alignItems="center">
        <img src={imgSrc} className={classes.img} alt="Patrol event attachment" />
      </Box>
    </Dialog>
  );
}
