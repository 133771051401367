import React from "react";
import { useDeletePatrolEventAttachmentMutation } from "../../../../../graphql/mutation.generated";
import { useGetPatrolEventAttachmentsQuery } from "../../../../../graphql/query.generated";
import EventAttachmentsListView from "./EventAttachmentsListView";

interface Props {
  patrolEventId: string;
  canUserEdit: boolean;
}
export default function EventAttachmentsList({
  patrolEventId,
  canUserEdit,
}: Props): JSX.Element {
  const {
    data: attachmentsData,
    loading: attachmentsDataLoading,
    error: attachmentsFetchingError,
  } = useGetPatrolEventAttachmentsQuery({
    variables: { patrolEventId },
  });

  const [
    deletePatrolEventAttachmentMutation,
    { error: deletePatrolEventAttachmentError, loading: deleteAttachmentLoading },
  ] = useDeletePatrolEventAttachmentMutation();

  const onAttachmentDelete = async (patrolEventAttachmentId: string): Promise<void> => {
    try {
      await deletePatrolEventAttachmentMutation({
        variables: { id: patrolEventAttachmentId },
        update: (cache, res) => {
          if (res.data?.deletePatrolEventAttachment) {
            cache.modify({
              fields: {
                getPatrolEventAttachments(existingAttachments = [], { readField }) {
                  return existingAttachments.filter(
                    (attachmentRef) =>
                      patrolEventAttachmentId !== readField("id", attachmentRef)
                  );
                },
              },
            });
          }
        },
      });
    } catch (e) {
      //
    }
  };

  return (
    <EventAttachmentsListView
      canUserEdit={canUserEdit}
      onAttachmentDelete={onAttachmentDelete}
      attachmentsData={attachmentsData}
      deleteAttachmentLoading={deleteAttachmentLoading}
      attachmentsLoading={attachmentsDataLoading}
      deleteAttachmentError={deletePatrolEventAttachmentError}
      attachmentsFetchingError={attachmentsFetchingError}
    />
  );
}
