import React, { useState } from "react";
import { useCreateCommentMutation } from "../../../graphql/mutation.generated";
import { GET_TASK_COMMENTS } from "../../../graphql/query";
import { GetTaskCommentsQuery } from "../../../graphql/query.generated";
import CreateCommentView from "./CreateCommentView";

interface Props {
  taskId: string;
}

export default function CreateComment({ taskId }: Props): JSX.Element {
  const [commentContent, setCommentContent] = useState<string>("");

  const [
    createCommentMutation,
    { loading: createCommentLoading, error: createCommentError },
  ] = useCreateCommentMutation();

  const onCommentChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
    setCommentContent(event.target.value);

  const onCommentSubmit = async (
    e: React.ChangeEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    if (commentContent) {
      try {
        const res = await createCommentMutation({
          variables: { createCommentData: { content: commentContent, taskId } },
          update: (cache, response) => {
            const comments: GetTaskCommentsQuery | null = cache.readQuery({
              query: GET_TASK_COMMENTS,
              variables: { taskId },
            });
            const newComment = response.data?.createComment;
            if (comments && newComment) {
              const updatedComments: GetTaskCommentsQuery = {
                ...comments,
                getTaskComments: [...comments.getTaskComments, newComment],
              };

              cache.writeQuery({
                query: GET_TASK_COMMENTS,
                variables: { taskId },
                data: updatedComments,
              });
            }
          },
        });
        if (res.data) {
          setCommentContent("");
        }
      } catch (err) {
        //
      }
    }
  };

  return (
    <CreateCommentView
      commentContent={commentContent}
      onCommentChange={onCommentChange}
      onCommentSubmit={onCommentSubmit}
      createCommentError={createCommentError}
      createCommentLoading={createCommentLoading}
    />
  );
}
