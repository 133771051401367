import React, { useState } from "react";
import Description from "../../../../../../components/Description/Description";
import { useUpdatePatrolDescriptionMutation } from "../../../../graphql/mutation.generated";

interface Props {
  patrolId: string;
  description?: string;
  disabled?: boolean;
}
export default function PatrolDescription({
  description,
  patrolId,
  disabled,
}: Props): JSX.Element {
  const [
    updateDescriptionMutation,
    { loading, error },
  ] = useUpdatePatrolDescriptionMutation();

  const [descriptionState, setDescriptionState] = useState<string>(description || "");

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDescriptionState(event.target.value);
  };

  const onDescriptionBlur = async (): Promise<void> => {
    try {
      await updateDescriptionMutation({
        variables: {
          updatePatrolDescriptionData: { id: patrolId, description: descriptionState },
        },
      });
    } catch (e) {
      //
    }
  };

  return (
    <Description
      descriptionState={descriptionState}
      onDescriptionBlur={onDescriptionBlur}
      onDescriptionChange={onDescriptionChange}
      disabled={disabled}
      error={error}
      loading={loading}
    />
  );
}
