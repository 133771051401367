import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import React from "react";
import { useUpdatePatrolEndDateMutation } from "../../../../graphql/mutation.generated";
import PatrolFinishDateView from "./PatrolFinishDateView";

interface Props {
  patrolFinishDate?: string;
  disabled?: boolean;
  patrolId: string;
}
export default function PatrolFinishDate({
  patrolFinishDate,
  patrolId,
  disabled,
}: Props): JSX.Element {
  const [updateEndDateMutation, { error, loading }] = useUpdatePatrolEndDateMutation();

  const onFinishDateChange = async (date: MaterialUiPickersDate): Promise<void> => {
    const newValue = date ? moment(date).toISOString() : null;
    try {
      await updateEndDateMutation({
        variables: { updatePatrolEndDateData: { id: patrolId, endDate: newValue } },
      });
    } catch (e) {
      //
    }
  };

  const onDateClear = (): void => {
    onFinishDateChange(null);
  };

  return (
    <PatrolFinishDateView
      patrolFinishDate={patrolFinishDate}
      onFinishDateChange={onFinishDateChange}
      disabled={disabled}
      error={error}
      loading={loading}
      onDateClear={onDateClear}
    />
  );
}
