import React from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
} from "@material-ui/core";

interface Props {
  open: boolean;
  dialogTitle?: string;
  dialogContent?: string;
  onConfirm: () => void;
  onDecline: () => void;
}
export default function ConfirmDialog({
  open,
  onConfirm,
  onDecline,
  dialogContent,
  dialogTitle,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={(): void => onDecline()}>
      <Box px={4} py={3}>
        {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
        {dialogContent && (
          <DialogContent>
            <DialogContentText>{dialogContent}</DialogContentText>
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={(): void => onDecline()} color="secondary">
            {t("Decline")}
          </Button>
          <Button onClick={(): void => onConfirm()} color="primary" autoFocus>
            {t("Confirm")}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
