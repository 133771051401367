import { Box } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";

interface Props {
  display?: boolean;
}
export default function EditableInputAdornment({ display }: Props): JSX.Element {
  return (
    <>
      {display && (
        <Box height="100%" display="flex" alignItems="center" pb={0.5} mr={1}>
          <EditIcon style={{ fontSize: "0.9rem" }} />
        </Box>
      )}
    </>
  );
}
