import React from "react";
import { Route, Switch } from "react-router-dom";

import HelmetWrapper from "../components/HelmetWrapper/HelmetWrapper";
import ProtectedRoute from "../utils/AuthUtils/ProtectedRoute";
import SignInPage from "../views/Identity/SignInPage/SignInPage";
import { SIGN_IN } from "./paths";
import SignedInUserRoutes from "./SignedInUserRoutes";

export default function RouterComponent(): JSX.Element {
  return (
    <Switch>
      <Route
        exact
        path={SIGN_IN}
        render={(): JSX.Element => (
          <HelmetWrapper siteTitle="Sign in">
            <SignInPage />
          </HelmetWrapper>
        )}
      />

      {/* Routes after login */}
      <ProtectedRoute path="/" render={(): JSX.Element => <SignedInUserRoutes />} />
    </Switch>
  );
}
