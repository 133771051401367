import { useContext } from "react";
import { CompanyContext } from "../CompanyContext/CompanyContextProvider";
import { UserContext } from "../UserContext/UserContextProvider";
import { WorkplaceContext } from "../WorkplaceContext/WorkplaceContextProvider";

export default function useClearAllContexts(): () => void {
  const { clearUserCtx } = useContext(UserContext);
  const { clearWorkplacesContext } = useContext(WorkplaceContext);
  const { clearCompanyContext } = useContext(CompanyContext);

  const clearAll = (): void => {
    if (clearUserCtx) {
      clearUserCtx();
    }

    if (clearWorkplacesContext) {
      clearWorkplacesContext();
    }

    if (clearCompanyContext) {
      clearCompanyContext();
    }
  };

  return clearAll;
}
