import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import React from "react";
import { useUpdatePatrolEventEventDateMutation } from "../../../../../graphql/mutation.generated";
import PatrolEventEventDateView from "./PatrolEventEventDateView";

interface Props {
  eventDate: string;
  disabled?: boolean;
  patrolEventId: string;
}
export default function PatrolEventEventDate({
  eventDate,
  patrolEventId,
  disabled,
}: Props): JSX.Element {
  const [
    updateEventDateMutation,
    { loading, error },
  ] = useUpdatePatrolEventEventDateMutation();

  const onEventDateChange = async (date: MaterialUiPickersDate): Promise<void> => {
    if (date) {
      const newValue = moment(date).toISOString();
      if (newValue !== eventDate) {
        try {
          await updateEventDateMutation({
            variables: {
              updatePatrolEventEventDateData: { eventDate: newValue, id: patrolEventId },
            },
          });
        } catch (e) {
          //
        }
      }
    }
  };

  return (
    <PatrolEventEventDateView
      eventDate={eventDate}
      onEventDateChange={onEventDateChange}
      disabled={disabled}
      error={error}
      loading={loading}
    />
  );
}
