import { Box } from "@material-ui/core";
import React from "react";
import { PatrolEventFragment } from "../../graphql/fragments.generated";
import SinglePatrolEvent from "./singlePatrolEvent/SinglePatrolEvent";

interface Props {
  patrolEvents: PatrolEventFragment[];
  canUserEdit: boolean;
}
export default function PatrolEventsView({
  patrolEvents,
  canUserEdit,
}: Props): JSX.Element {
  return (
    <Box>
      {patrolEvents.map((event) => (
        <Box key={`singleEvent${event.id}`} mb={1}>
          <SinglePatrolEvent canUserEdit={canUserEdit} eventData={event} />
        </Box>
      ))}
    </Box>
  );
}
