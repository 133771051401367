import React, { useState } from "react";
import { updateCommentContentFragment } from "../../../graphql/fragment";
import { UpdateCommentContentFragment } from "../../../graphql/fragment.generated";
import {
  useDeleteCommentMutation,
  useUpdateCommentMutation,
} from "../../../graphql/mutation.generated";
import OwnCommentView from "./OwnCommentView";

interface Props {
  id: string;
  content: string;
  createdAt: string;
  updatedAt?: string;
  notDeletable?: boolean;
}

export default function OwnComment({
  content,
  updatedAt,
  createdAt,
  id,
  notDeletable,
}: Props): JSX.Element {
  const [deleteCommentDialogOpen, setDeleteCommentDialogOpen] = useState<boolean>(false);
  const [editCommentDialogOpen, setEditCommentDialogOpen] = useState<boolean>(false);

  const [
    deleteCommentMutation,
    { loading: deleteCommentLoading, error: deleteCommentError },
  ] = useDeleteCommentMutation({
    update: (cache, response) => {
      if (response.data?.deleteComment) {
        cache.modify({
          fields: {
            getTaskComments(existingComments = [], { readField }) {
              return existingComments.filter(
                (commentRef) => id !== readField("id", commentRef)
              );
            },
          },
        });
      }
    },
  });

  const [
    updateCommentMutation,
    { loading: updateCommentLoading, error: updateCommentError },
  ] = useUpdateCommentMutation({
    update: (cache, response) => {
      if (response.data?.updateComment) {
        const {
          content: updatedContent,
          updatedAt: updatedUpdatedDate,
        } = response.data.updateComment;
        const commentFragmentId = `Comment:${id}`;
        const commentFragment: UpdateCommentContentFragment | null = cache.readFragment({
          id: commentFragmentId,
          fragment: updateCommentContentFragment,
        });

        if (commentFragment) {
          const updatedCommentFragment: UpdateCommentContentFragment = {
            ...commentFragment,
            content: updatedContent,
            updatedAt: updatedUpdatedDate,
          };
          cache.writeFragment({
            id: commentFragmentId,
            fragment: updateCommentContentFragment,
            data: updatedCommentFragment,
          });
        }
      }
    },
  });

  const deleteComment = async (): Promise<void> => {
    setDeleteCommentDialogOpen(false);
    try {
      await deleteCommentMutation({ variables: { id } });
    } catch (err) {
      //
    }
  };

  const updateCommentContent = async (newContent: string): Promise<void> => {
    setEditCommentDialogOpen(false);
    try {
      await updateCommentMutation({
        variables: {
          updateCommentData: {
            content: newContent,
            id,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <OwnCommentView
      closeDeleteCommentDialog={(): void => setDeleteCommentDialogOpen(false)}
      closeEditCommentDialog={(): void => setEditCommentDialogOpen(false)}
      content={content}
      createdAt={createdAt}
      deleteComment={deleteComment}
      deleteCommentDialogOpen={deleteCommentDialogOpen}
      editComment={updateCommentContent}
      editCommentDialogOpen={editCommentDialogOpen}
      openDeleteCommentDialog={(): void => setDeleteCommentDialogOpen(true)}
      openEditCommentDialog={(): void => setEditCommentDialogOpen(true)}
      deleteCommentError={deleteCommentError}
      editCommentError={updateCommentError}
      loading={deleteCommentLoading || updateCommentLoading}
      notDeletable={notDeletable}
      updatedAt={updatedAt}
    />
  );
}
