import { ApolloError } from "@apollo/client";
import { Box, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import BasicMenuLayout from "../../../layouts/BasicMenuLayout";
import { TasksListFragment } from "../graphql/fragment.generated";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import SingleTask from "./SingleTask/SingleTask";
import React from "react";
import AddTask from "../AddTask/AddTask";

interface Props {
  tasksList: TasksListFragment[];
  loading?: boolean;
  tasksListError?: ApolloError;
  workplaceShortName?: string;
  workplaceId?: string;
  addNewTaskMode: boolean;
  onNewTaskAddCancel: () => void;
  onNewTaskAdded: (id: string) => void;
  onTaskAddClick: () => void;
}
export default function TasksListPageView({
  tasksList,
  loading,
  tasksListError,
  workplaceShortName,
  workplaceId,
  addNewTaskMode,
  onNewTaskAddCancel,
  onNewTaskAdded,
  onTaskAddClick,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <BasicMenuLayout loading={loading}>
      {addNewTaskMode ? (
        <AddTask
          onCancel={onNewTaskAddCancel}
          onTaskAdded={onNewTaskAdded}
          workplaceId={workplaceId}
        />
      ) : (
        <>
          <Box mb={3} display="flex" justifyContent="flex-end">
            {/* <Button size="small" variant="outlined">
          {t("Filters")}
        </Button> */}
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={onTaskAddClick}
            >
              {t("Add Task")}
            </Button>
          </Box>
          <Box>
            {tasksList.map((task) => (
              <SingleTask
                key={task.id}
                task={task}
                workplaceShortName={workplaceShortName}
                workplaceId={workplaceId}
              />
            ))}
          </Box>
        </>
      )}

      <ErrorDisplay
        error={tasksListError}
        message={t("Error occured while fetching tasks, please try again or contact us.")}
      />
    </BasicMenuLayout>
  );
}
