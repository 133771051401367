import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "../generated/translations/en.json";
import plTranslations from "../generated/translations/pl.json";

const resources = {
  en: {
    translation: enTranslations,
  },
  pl: {
    translation: plTranslations,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "pl",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    returnEmptyString: false,
  });

export default i18n;
