import React, { useState } from "react";
import Description from "../../../../../components/Description/Description";
import { useUpdateTaskDescriptionMutation } from "../../../graphql/mutation.generated";

interface Props {
  taskId: string;
  description?: string;
  disabled: boolean;
}
export default function TaskDescription({
  description,
  taskId,
  disabled,
}: Props): JSX.Element {
  const [descriptionState, setDescriptionState] = useState<string>(description || "");

  const [
    updateTaskDescriptionMutation,
    { error, loading },
  ] = useUpdateTaskDescriptionMutation();

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDescriptionState(event.target.value);
  };

  const onDescriptionBlur = async (): Promise<void> => {
    if (description !== descriptionState) {
      try {
        await updateTaskDescriptionMutation({
          variables: {
            updateTaskDescriptionData: {
              id: taskId,
              description: descriptionState,
            },
          },
        });
      } catch (e) {
        //
      }
    }
  };

  return (
    <Description
      descriptionState={descriptionState}
      onDescriptionBlur={onDescriptionBlur}
      onDescriptionChange={onDescriptionChange}
      disabled={disabled}
      error={error}
      loading={loading}
    />
  );
}
