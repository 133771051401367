import { Box, Paper } from "@material-ui/core";
import React from "react";
import PatrolEventAction from "./eventFields/action/PatrolEventAction";
import PatrolEventAttachments from "./eventFields/attachments/PatrolEventAttachments";
import PatrolEventDescription from "./eventFields/description/PatrolEventDescription";
import PatrolEventEventDate from "./eventFields/eventDate/PatrolEventEventDate";
import PatrolEventLocation from "./eventFields/location/PatrolEventLocation";
import PatrolEventName from "./eventFields/name/PatrolEventName";
import PatrolEventMoreMenu from "./eventMoreMenu/PatrolEventMoreMenu";

interface Props {
  eventDate: string;
  patrolEventId: string;
  eventDescription?: string;
  eventAction?: string;
  eventLocation?: string;
  eventName?: string;
  canUserEdit: boolean;
}
export default function SinglePatrolEventView({
  eventDate,
  patrolEventId,
  eventDescription,
  eventAction,
  eventLocation,
  eventName,
  canUserEdit,
}: Props): JSX.Element {
  return (
    <Paper variant="outlined">
      <Box px={1.5} py={1.5}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={1.3}
        >
          <Box width="90%" pt={1}>
            <PatrolEventName patrolEventId={patrolEventId} patrolEventName={eventName} />
          </Box>
          <PatrolEventMoreMenu patrolEventId={patrolEventId} />
        </Box>
        <Box mb={1.3}>
          <PatrolEventEventDate eventDate={eventDate} patrolEventId={patrolEventId} />
        </Box>
        <Box mb={1.3}>
          <PatrolEventLocation
            patrolEventId={patrolEventId}
            eventLocation={eventLocation}
          />
        </Box>
        <Box mb={2}>
          <PatrolEventAttachments
            canUserEdit={canUserEdit}
            patrolEventId={patrolEventId}
          />
        </Box>
        <Box mb={1.3}>
          <PatrolEventDescription
            patrolEventId={patrolEventId}
            eventDescription={eventDescription}
          />
        </Box>
        <Box mb={1.3}>
          <PatrolEventAction patrolEventId={patrolEventId} eventAction={eventAction} />
        </Box>
      </Box>
    </Paper>
  );
}
