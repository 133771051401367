import React, { useMemo } from "react";
import Assignees from "../../../../../../components/Assignees/Assignees";
import useGetWorkplaceUsers from "../../../../../../hooks/useGetWorkplaceUsers";
import { useChangePatrolAssigneesMutation } from "../../../../graphql/mutation.generated";

interface Props {
  assigneesIds: string[];
  patrolId: string;
  disabled?: boolean;
}
export default function PatrolAssignees({
  assigneesIds,
  patrolId,
  disabled,
}: Props): JSX.Element {
  const [
    changeAssigneesMutation,
    { loading: changeAssigneesLoading, error: changeAssigneesError },
  ] = useChangePatrolAssigneesMutation();

  const {
    error: fetchingUsersError,
    loading: fetchingUsersLoading,
    workplaceUsers,
  } = useGetWorkplaceUsers();

  const changePatrolAssignees = async (aIds: string[]): Promise<void> => {
    try {
      await changeAssigneesMutation({
        variables: { changePatrolAssigneesData: { assigneesIds: aIds, id: patrolId } },
      });
    } catch (e) {
      //
    }
  };

  const assignees = useMemo(() => {
    return assigneesIds.map((id) => ({ id }));
  }, [assigneesIds]);

  const namesToDisplay = useMemo(() => {
    if (workplaceUsers) {
      return workplaceUsers.map((wpUser) => ({
        name: `${wpUser.firstName} ${wpUser.lastName}`,
        optionId: wpUser.id,
      }));
    }
    return [];
  }, [workplaceUsers]);

  const usersToPick = useMemo(() => {
    if (workplaceUsers) {
      return workplaceUsers.map((wpUser) => ({
        id: wpUser.id,
      }));
    }
    return [];
  }, [workplaceUsers]);

  const onAssigneesChange = (newAssignees: { id: string }[]): void => {
    changePatrolAssignees(newAssignees.map((a) => a.id));
  };

  return (
    <Assignees
      assignees={assignees}
      namesToDisplay={namesToDisplay}
      usersToPick={usersToPick}
      onChange={onAssigneesChange}
      disabled={disabled}
      loading={fetchingUsersLoading || changeAssigneesLoading}
      fetchingUsersError={fetchingUsersError}
      changeAssigneesError={changeAssigneesError}
    />
  );
}
