import { ApolloError } from "@apollo/client";
import { Box, CircularProgress } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../../components/ErrorDisplay/ErrorDisplay";
import MultiSelectWithSearch from "../../../../../components/MultiSelectWithSearch/MultiSelectWithSearch";

interface Props {
  labelsToPick: { id: string }[];
  labels: { id: string }[];
  namesToDisplay: { name: string; optionId: string }[];
  onChange: (value: { id: string }[]) => void;
  loading?: boolean;
  changingLabelsError?: ApolloError;
  fetchingLabelsError?: ApolloError;
  disabled?: boolean;
}
export default function TaskLabelsView({
  labels,
  labelsToPick,
  namesToDisplay,
  onChange,
  changingLabelsError,
  disabled,
  fetchingLabelsError,
  loading,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <Box position="relative">
        <MultiSelectWithSearch<{ id: string }>
          size="small"
          options={labelsToPick}
          pickedValues={labels}
          namesToDisplay={namesToDisplay}
          label={t("Labels")}
          placeholder={t("+ Add task label")}
          onChange={onChange}
          disabled={disabled || loading}
        />
        {loading && (
          <Box position="absolute" top="50%" left="50%">
            <CircularProgress size={20} />
          </Box>
        )}
      </Box>
      <ErrorDisplay
        error={changingLabelsError}
        message={t(
          "Error occured while changing labels, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={fetchingLabelsError}
        message={t("Error occured while loading labels, please try again or contact us.")}
      />
    </>
  );
}
