import { ApolloError } from "@apollo/client";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";
import { useTranslation } from "react-i18next";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { Box, IconButton } from "@material-ui/core";
import CustomDateTimePicker from "../CustomDateTimePicker/CustomDateTimePicker";
import EditableInputAdornment from "../EditableInputAdornment/EditableInputAdornment";
import ErrorDisplay from "../ErrorDisplay/ErrorDisplay";

interface Props {
  loading?: boolean;
  disabled?: boolean;
  date?: string;
  error?: ApolloError;
  onDateChange: (date: MaterialUiPickersDate) => void;
  onDateClear: () => void;
  label?: string;
  placeholder?: string;
  fullWidth?: boolean;
}
export default function ListedDateTimePicker({
  loading,
  date,
  error,
  disabled,
  onDateChange,
  onDateClear,
  label,
  placeholder,
  fullWidth,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <Box display="flex" alignItems="flex-end">
        <CustomDateTimePicker
          disabled={disabled || loading}
          placeholder={placeholder}
          label={label}
          value={date}
          onChange={(newDate: MaterialUiPickersDate) => onDateChange(newDate)}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <EditableInputAdornment display={Boolean(date) && !disabled} />
            ),
          }}
          size="small"
          fullWidth={fullWidth}
        />
        {date && !disabled && (
          <Box display="flex" alignItems="center" pb={0.3}>
            <IconButton size="small" onClick={onDateClear}>
              <CloseOutlinedIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
      <ErrorDisplay
        error={error}
        message={t("Error occured while changing date, please try again or contact us")}
        codes={{
          BAD_USER_INPUT: t(
            "Provided date is invalid, check if date is not in collision with other dates."
          ),
        }}
      />
    </>
  );
}
