import React, { useEffect, useState } from "react";
import { useUpdatePatrolEventNameMutation } from "../../../../../graphql/mutation.generated";
import PatrolEventNameView from "./PatrolEventNameView";

interface Props {
  patrolEventName?: string;
  patrolEventId: string;
  disabled?: boolean;
}

export default function PatrolEventName({
  patrolEventId,
  patrolEventName,
  disabled,
}: Props): JSX.Element {
  const [name, setName] = useState<string>("");

  const [
    updatePatrolEventNameMutation,
    { loading, error },
  ] = useUpdatePatrolEventNameMutation();

  useEffect(() => {
    if (patrolEventName) {
      setName(patrolEventName);
    }
  }, [patrolEventName]);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value);
  };

  const onNameBlur = async (): Promise<void> => {
    if (name !== patrolEventName) {
      try {
        updatePatrolEventNameMutation({
          variables: { updatePatrolEventNameData: { id: patrolEventId, name } },
        });
      } catch (e) {
        //
      }
    }
  };

  return (
    <PatrolEventNameView
      name={name}
      onNameBlur={onNameBlur}
      onNameChange={onNameChange}
      disabled={disabled}
      error={error}
      loading={loading}
    />
  );
}
