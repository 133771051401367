import { ApolloError } from "@apollo/client";
import { InputAdornment, makeStyles, TextField } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../../components/ErrorDisplay/ErrorDisplay";
import theme from "../../../../../utils/theme";
import LocationOnIcon from "@material-ui/icons/LocationOn";

interface Props {
  location: string;
  onBlur: () => void;
  changeLocationState: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: ApolloError;
}

const useStyles = makeStyles({
  locationInput: {
    "& .MuiInputBase-input": {
      ...theme.typography.body1,
      padding: 0,
    },
    "& .MuiInputAdornment-root": {
      color: theme.palette.secondary.main,
    },
  },
});

export default function TaskLocationView({
  changeLocationState,
  error,
  location,
  onBlur,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <TextField
        disabled={disabled}
        size="small"
        multiline
        fullWidth
        className={classes.locationInput}
        value={location}
        onChange={changeLocationState}
        onBlur={onBlur}
        placeholder={t("Add location")}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocationOnIcon />
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
      />
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while updating task location, try again or contact us."
        )}
      />
    </>
  );
}
