import { useCookies } from "react-cookie";

const TOKEN_NAME = "authToken";

const COOKIE_EXPIRE_DAYS = 14;

export const useAuthToken = (): [
  { [name: string]: any },
  (authToken: string) => void,
  () => void
] => {
  const [cookies, setCookie, removeCookie] = useCookies([TOKEN_NAME]);

  const setAuthToken = (authToken: string): void =>
    setCookie(TOKEN_NAME, authToken, {
      maxAge: COOKIE_EXPIRE_DAYS * 24 * 60 * 60,
      path: "/",
    });

  const removeAuthToken = (): void => removeCookie(TOKEN_NAME, { path: "/" });

  return [cookies[TOKEN_NAME], setAuthToken, removeAuthToken];
};
