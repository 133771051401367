export interface QueryVariables {
  workplaceId: string;
  taskId: string;
  taskFilters: string;
  pageSize: string;
  pageNumber: string; // pagination page number
  patrolId: string;
}

export enum QueryVariablesEnum {
  WORKPLACEID = "workplaceId",
  TASKID = "taskId",
  TASKFILTERS = "taskFilters",
  PAGENUMBER = "pageNumber",
  PAGESIZE = "pageSize",
  PATROLID = "patrolId",
}
