import React from "react";
import { PatrolEventFragment } from "../../../graphql/fragments.generated";
import SinglePatrolEventView from "./SinglePatrolEventView";

interface Props {
  eventData: PatrolEventFragment;
  canUserEdit: boolean;
}
export default function SinglePatrolEvent({
  eventData,
  canUserEdit,
}: Props): JSX.Element {
  return (
    <SinglePatrolEventView
      canUserEdit={canUserEdit}
      patrolEventId={eventData.id}
      eventDate={eventData.eventDate}
      eventDescription={eventData.description || undefined}
      eventAction={eventData.action || undefined}
      eventName={eventData.name || undefined}
      eventLocation={eventData.location || undefined}
    />
  );
}
