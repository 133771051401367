import React, { useState } from "react";
import { useUpdatePatrolEventDescriptionMutation } from "../../../../../graphql/mutation.generated";
import PatrolEventDescriptionView from "./PatrolEventDescriptionView";

interface Props {
  patrolEventId: string;
  eventDescription?: string;
  disabled?: boolean;
}

export default function PatrolEventDescription({
  eventDescription,
  patrolEventId,
  disabled,
}: Props): JSX.Element {
  const [
    updateDescriptionMutation,
    { loading, error },
  ] = useUpdatePatrolEventDescriptionMutation();

  const [descriptionState, setDescriptionState] = useState<string | undefined>(
    eventDescription
  );

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDescriptionState(event.target.value);
  };

  const onDescriptionBlur = async (): Promise<void> => {
    if (descriptionState !== eventDescription) {
      try {
        await updateDescriptionMutation({
          variables: {
            updatePatrolEventDescriptionData: {
              description: descriptionState,
              id: patrolEventId,
            },
          },
        });
      } catch (e) {
        //
      }
    }
  };

  return (
    <PatrolEventDescriptionView
      descriptionState={descriptionState || ""}
      onDescriptionBlur={onDescriptionBlur}
      onDescriptionChange={onDescriptionChange}
      disabled={disabled}
      error={error}
      loading={loading}
    />
  );
}
