import React, { useState } from "react";
import useGetWorkplaceDisplayData from "../../../../hooks/useGetWorkplaceDisplayData";
import { TaskDetailsFragment } from "../../graphql/fragment.generated";
import TaskBasicInfoView from "./TaskBasicInfoView";

interface Props {
  task: TaskDetailsFragment;
  canUserEditTask: boolean;
  canUserEditTaskStatus: boolean;
}
export default function TaskBasicInfo({
  task,
  canUserEditTask,
  canUserEditTaskStatus,
}: Props): JSX.Element {
  const { currentWorkplaceShortName } = useGetWorkplaceDisplayData();
  const [viewMore, setViewMore] = useState(false);

  const onViewMoreChange = (): void => {
    setViewMore(!viewMore);
  };
  return (
    <TaskBasicInfoView
      viewMore={viewMore}
      onViewMoreChange={onViewMoreChange}
      task={task}
      workplaceShortName={currentWorkplaceShortName}
      canUserEditTask={canUserEditTask}
      canUserEditTaskStatus={canUserEditTaskStatus}
    />
  );
}
