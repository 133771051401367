import { Box, Dialog, IconButton, makeStyles } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  videoSrc: string;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  video: { height: "100%", width: "100%" },
  closeIcon: { position: "absolute", right: 0, zIndex: 10 },
});

export default function VideoDialog({ videoSrc, open, onClose }: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose}>
      <Box className={classes.closeIcon}>
        <IconButton onClick={(): void => onClose()}>
          <HighlightOffIcon color="secondary" />
        </IconButton>
      </Box>
      <video controls autoPlay src={videoSrc} className={classes.video}>
        {t("Sorry, your browser doesn't support embedded videos.")}
      </video>
    </Dialog>
  );
}
