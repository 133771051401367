import { ApolloError } from "@apollo/client";
import { Box, IconButton } from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDateTimePicker from "../../../../../../components/CustomDateTimePicker/CustomDateTimePicker";
import EditableInputAdornment from "../../../../../../components/EditableInputAdornment/EditableInputAdornment";
import ErrorDisplay from "../../../../../../components/ErrorDisplay/ErrorDisplay";

interface Props {
  patrolFinishDate?: string;
  onFinishDateChange: (date: MaterialUiPickersDate) => void;
  disabled?: boolean;
  loading?: boolean;
  onDateClear: () => void;
  error?: ApolloError;
}
export default function PatrolFinishDateView({
  onFinishDateChange,
  patrolFinishDate,
  disabled,
  loading,
  onDateClear,
  error,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <Box display="flex" alignItems="flex-end">
        <CustomDateTimePicker
          disabled={disabled || loading}
          placeholder={t("Pick patrol finish date")}
          label={t("Finish date")}
          value={patrolFinishDate}
          onChange={(date: MaterialUiPickersDate) => onFinishDateChange(date)}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <EditableInputAdornment display={Boolean(patrolFinishDate) && !disabled} />
            ),
          }}
          size="small"
        />
        {patrolFinishDate && !disabled && (
          <Box display="flex" alignItems="center" pb={0.3}>
            <IconButton size="small" onClick={onDateClear}>
              <CloseOutlinedIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>

      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while changing finish date, please try again or contact us"
        )}
        codes={{
          BAD_USER_INPUT: t(
            "Provided date is invalid, check if date is not before patrol start date."
          ),
        }}
      />
    </>
  );
}
