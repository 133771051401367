import { gql } from "@apollo/client";
import {
  taskAttachmentFragment,
  taskDetailsFragment,
  tasksListFragment,
} from "./fragment";

export const GET_TASKS_LIST = gql`
  ${tasksListFragment}
  query GetTasksList($filters: TaskFilters, $workplaceId: UUID!) {
    getWorkplaceTasks(filters: $filters, workplaceId: $workplaceId) {
      ...TasksList
    }
  }
`;

export const GET_TASK_DETAILS = gql`
  ${taskDetailsFragment}
  query GetTaskDetails($id: UUID!) {
    getTask(id: $id) {
      ...TaskDetails
    }
  }
`;

export const GET_WORKPLACE_TASK_STATUSES = gql`
  query GetWorkplaceTaskStatuses($workplaceId: UUID!) {
    getWorkplaceTaskStatuses(workplaceId: $workplaceId) {
      id
      name
      order
      type
    }
  }
`;

export const GET_WORKPLACE_CATEGORIES = gql`
  query GetWorkplaceCategories($companyId: UUID!) {
    getCategories(companyId: $companyId) {
      id
      name
    }
  }
`;

export const GET_WORKPLACE_LABELS = gql`
  query GetWorkplaceLabels($companyId: UUID!) {
    getLabels(companyId: $companyId) {
      id
      name
    }
  }
`;

export const GET_TASK_ATTACHMENTS = gql`
  ${taskAttachmentFragment}
  query GetTaskAttachments($taskId: UUID!) {
    getTaskAttachments(taskId: $taskId) {
      ...TaskAttachment
    }
  }
`;

export const GET_TASK_COMMENTS = gql`
  query GetTaskComments($taskId: UUID!) {
    getTaskComments(taskId: $taskId) {
      id
      content
      author {
        id
        firstName
        lastName
        email
        isActive
      }
      createdAt
      updatedAt
    }
  }
`;
