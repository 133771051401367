import { ApolloError } from "@apollo/client";
import { TextField, MenuItem } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../../components/ErrorDisplay/ErrorDisplay";
import { TaskStatus } from "../../../../../generated/types";

interface Props {
  onStatusChange: (newStatus: string) => void;
  statuses: TaskStatus[];
  statusId: string;
  changeStatusError?: ApolloError;
  statusesFetchingError?: ApolloError;
  disabled: boolean;
}
export default function TaskStatusPickerView({
  onStatusChange,
  statusId,
  statuses,
  changeStatusError,
  statusesFetchingError,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <TextField
        select
        fullWidth
        variant="outlined"
        label={t("Task status")}
        value={statusId}
        size="small"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onStatusChange(e.target.value)
        }
        disabled={disabled}
      >
        {statuses.map((status) => (
          <MenuItem value={status.id} key={`status${status.id}`}>
            {status.name}
          </MenuItem>
        ))}
      </TextField>

      <ErrorDisplay
        error={changeStatusError}
        message={t(
          "Error occured while changing tasks status, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={statusesFetchingError}
        message={t(
          "Error occured while loading tasks statuses for current workplace, please try again or contact us."
        )}
      />
    </>
  );
}
