import React, { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../../../context/UserContext/UserContextProvider";
import { WorkplaceContext } from "../../../context/WorkplaceContext/WorkplaceContextProvider";
import { Permissions } from "../../../generated/types";
import useManageUrlQueryVariables from "../../../hooks/useManageUrlQueryVariables";
import useLazyIsUserAuthorised from "../../../utils/AuthUtils/useLazyIsUserAuthorised";
import { useGetTaskDetailsLazyQuery } from "../graphql/query.generated";
import TaskPageView from "./TaskPageView";

export default function TaskPage(): JSX.Element {
  const { taskId } = useManageUrlQueryVariables();
  const { isUserAuthorised } = useLazyIsUserAuthorised();
  const { chosenWorkplaceId } = useContext(WorkplaceContext);
  const { id: userId } = useContext(UserContext);

  const [messagesOpen, setMessagesOpen] = useState(false);

  const [
    getTaskDetails,
    { data: taskData, error: taskDataError, loading: taskDataLoading },
  ] = useGetTaskDetailsLazyQuery();

  useEffect(() => {
    if (taskId) {
      getTaskDetails({
        variables: {
          id: taskId,
        },
      });
    }
  }, [getTaskDetails, taskId]);

  const canUserEditTask = useMemo<boolean>(() => {
    if (taskData?.getTask.isArchived || taskData?.getTask.parent?.isArchived) {
      return false;
    }
    if (chosenWorkplaceId) {
      if (isUserAuthorised(Permissions.EditTask, chosenWorkplaceId)) {
        return true;
      }
      if (userId && taskData) {
        const taskAssigneesIds = taskData.getTask.assignees.map((a) => a.id);
        if (taskAssigneesIds.includes(userId)) {
          return isUserAuthorised(Permissions.EditTaskOwn, chosenWorkplaceId);
        }
      }
    }
    return false;
  }, [taskData, chosenWorkplaceId, isUserAuthorised, userId]);

  const canUserEditAttachments = useMemo<boolean>(() => {
    if (chosenWorkplaceId) {
      if (isUserAuthorised(Permissions.EditTask, chosenWorkplaceId)) {
        return true;
      }
      if (userId && taskData) {
        const taskAssigneesIds = taskData.getTask.assignees.map((a) => a.id);
        if (taskAssigneesIds.includes(userId)) {
          return isUserAuthorised(Permissions.EditTaskOwn, chosenWorkplaceId);
        }
      }
    }
    return false;
  }, [chosenWorkplaceId, isUserAuthorised, userId, taskData]);

  const canUserEditTaskStatus = useMemo<boolean>(() => {
    if (taskData?.getTask.isArchived || taskData?.getTask.parent?.isArchived) {
      return false;
    }
    if (chosenWorkplaceId) {
      if (isUserAuthorised(Permissions.EditTask, chosenWorkplaceId)) {
        return true;
      }
      if (userId && taskData) {
        const taskAssigneesIds = taskData.getTask.assignees.map((a) => a.id);
        if (taskAssigneesIds.includes(userId)) {
          return isUserAuthorised(Permissions.EditTaskStatusOwn, chosenWorkplaceId);
        }
      }
    }
    return false;
  }, [taskData, chosenWorkplaceId, isUserAuthorised, userId]);

  const canUserArchiveTask = useMemo<boolean>(() => {
    // TODO check this approach, when we no longer get tree tasks from backend, it prevents from chancing task when parent is archived
    if (taskData?.getTask.parent || taskData?.getTask.parent?.isArchived) {
      return false;
    }
    if (chosenWorkplaceId) {
      if (isUserAuthorised(Permissions.ArchiveTask, chosenWorkplaceId)) {
        return true;
      }
      if (userId && taskData) {
        const taskAssigneesIds = taskData.getTask.assignees.map((a) => a.id);
        if (taskAssigneesIds.includes(userId)) {
          return isUserAuthorised(Permissions.ArchiveTaskOwn, chosenWorkplaceId);
        }
      }
    }
    return false;
  }, [taskData, chosenWorkplaceId, isUserAuthorised, userId]);

  return (
    <TaskPageView
      task={taskData?.getTask || undefined}
      loading={taskDataLoading}
      taskDataError={taskDataError}
      canUserEditAttachments={canUserEditAttachments}
      onCommentsButtonClick={(): void => setMessagesOpen(!messagesOpen)}
      commentsOpen={messagesOpen}
      onCommentsClose={(): void => setMessagesOpen(false)}
      workplaceId={chosenWorkplaceId}
      canUserEditTask={canUserEditTask}
      canUserEditTaskStatus={canUserEditTaskStatus}
      canUserArchiveTask={canUserArchiveTask}
    />
  );
}
