import * as Types from '../../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SignUpMutationVariables = Types.Exact<{
  request: Types.SignUpData;
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUp: boolean };

export type SignInMutationVariables = Types.Exact<{
  request: Types.SignInData;
}>;


export type SignInMutation = { __typename?: 'Mutation', signIn: { __typename?: 'SignInResponse', access_token: string, token_type: string } };

export type ActivateAccountMutationVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type ActivateAccountMutation = { __typename?: 'Mutation', activateAccount: boolean };

export type RequestPasswordResetMailMutationVariables = Types.Exact<{
  email: Types.Scalars['EmailAddress'];
}>;


export type RequestPasswordResetMailMutation = { __typename?: 'Mutation', requestPasswordResetMail: boolean };

export type ResetPasswordMutationVariables = Types.Exact<{
  resetPasswordData: Types.ResetPasswordData;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type SignUpFromInvitationMutationVariables = Types.Exact<{
  signUpFromInvitationData: Types.SignUpFromInvitationData;
}>;


export type SignUpFromInvitationMutation = { __typename?: 'Mutation', signUpFromInvitation: boolean };


export const SignUpDocument = gql`
    mutation SignUp($request: SignUpData!) {
  signUp(signUpData: $request)
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const SignInDocument = gql`
    mutation SignIn($request: SignInData!) {
  signIn(signInData: $request) {
    access_token
    token_type
  }
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const ActivateAccountDocument = gql`
    mutation ActivateAccount($token: String!) {
  activateAccount(token: $token)
}
    `;
export type ActivateAccountMutationFn = Apollo.MutationFunction<ActivateAccountMutation, ActivateAccountMutationVariables>;

/**
 * __useActivateAccountMutation__
 *
 * To run a mutation, you first call `useActivateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAccountMutation, { data, loading, error }] = useActivateAccountMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useActivateAccountMutation(baseOptions?: Apollo.MutationHookOptions<ActivateAccountMutation, ActivateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateAccountMutation, ActivateAccountMutationVariables>(ActivateAccountDocument, options);
      }
export type ActivateAccountMutationHookResult = ReturnType<typeof useActivateAccountMutation>;
export type ActivateAccountMutationResult = Apollo.MutationResult<ActivateAccountMutation>;
export type ActivateAccountMutationOptions = Apollo.BaseMutationOptions<ActivateAccountMutation, ActivateAccountMutationVariables>;
export const RequestPasswordResetMailDocument = gql`
    mutation RequestPasswordResetMail($email: EmailAddress!) {
  requestPasswordResetMail(email: $email)
}
    `;
export type RequestPasswordResetMailMutationFn = Apollo.MutationFunction<RequestPasswordResetMailMutation, RequestPasswordResetMailMutationVariables>;

/**
 * __useRequestPasswordResetMailMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMailMutation, { data, loading, error }] = useRequestPasswordResetMailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestPasswordResetMailMutation(baseOptions?: Apollo.MutationHookOptions<RequestPasswordResetMailMutation, RequestPasswordResetMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPasswordResetMailMutation, RequestPasswordResetMailMutationVariables>(RequestPasswordResetMailDocument, options);
      }
export type RequestPasswordResetMailMutationHookResult = ReturnType<typeof useRequestPasswordResetMailMutation>;
export type RequestPasswordResetMailMutationResult = Apollo.MutationResult<RequestPasswordResetMailMutation>;
export type RequestPasswordResetMailMutationOptions = Apollo.BaseMutationOptions<RequestPasswordResetMailMutation, RequestPasswordResetMailMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($resetPasswordData: ResetPasswordData!) {
  resetPassword(resetPasswordData: $resetPasswordData)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      resetPasswordData: // value for 'resetPasswordData'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SignUpFromInvitationDocument = gql`
    mutation SignUpFromInvitation($signUpFromInvitationData: SignUpFromInvitationData!) {
  signUpFromInvitation(signUpFromInvitationData: $signUpFromInvitationData)
}
    `;
export type SignUpFromInvitationMutationFn = Apollo.MutationFunction<SignUpFromInvitationMutation, SignUpFromInvitationMutationVariables>;

/**
 * __useSignUpFromInvitationMutation__
 *
 * To run a mutation, you first call `useSignUpFromInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpFromInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpFromInvitationMutation, { data, loading, error }] = useSignUpFromInvitationMutation({
 *   variables: {
 *      signUpFromInvitationData: // value for 'signUpFromInvitationData'
 *   },
 * });
 */
export function useSignUpFromInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SignUpFromInvitationMutation, SignUpFromInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpFromInvitationMutation, SignUpFromInvitationMutationVariables>(SignUpFromInvitationDocument, options);
      }
export type SignUpFromInvitationMutationHookResult = ReturnType<typeof useSignUpFromInvitationMutation>;
export type SignUpFromInvitationMutationResult = Apollo.MutationResult<SignUpFromInvitationMutation>;
export type SignUpFromInvitationMutationOptions = Apollo.BaseMutationOptions<SignUpFromInvitationMutation, SignUpFromInvitationMutationVariables>;