import { Box, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import FullScreenLoader from "../../../components/FullScreenLoader/FullScreenLoader";
import { CompanyContext } from "../../../context/CompanyContext/CompanyContextProvider";
import { WorkplaceContext } from "../../../context/WorkplaceContext/WorkplaceContextProvider";
import { Permissions } from "../../../generated/types";
import { QueryVariablesEnum } from "../../../interfaces/QueryVariables";
import BasicMenuLayout from "../../../layouts/BasicMenuLayout";
import { PATROLS_LIST, WORKPLACE } from "../../../routing/paths";
import IsUserAuthorised from "../../../utils/AuthUtils/IsUserAuthorised";

export default function NoCompanyPage(): JSX.Element {
  const { t } = useTranslation();

  const { chosenCompanyId, loading: companyContextLoading } = useContext(CompanyContext);
  const { chosenWorkplaceId, loading: workplaceContextLoading } = useContext(
    WorkplaceContext
  );
  if (!companyContextLoading && !workplaceContextLoading) {
    if (!chosenCompanyId) {
      return (
        <BasicMenuLayout>
          <Box height="100%" display="flex" justifyContent="center" alignItems="center">
            <Box>
              <Typography variant="h4">
                {t("Your account is not added to any company")}
              </Typography>
              <Typography>
                {t(
                  "Please contact your collegues so they can add you to company or contact us, if you want to create new company"
                )}
              </Typography>
            </Box>
          </Box>
        </BasicMenuLayout>
      );
    }
    if (!chosenWorkplaceId) {
      return (
        <BasicMenuLayout>
          <Box height="100%" display="flex" justifyContent="center" alignItems="center">
            <Box>
              <Typography variant="h4">
                {t("You are not added to any workplace")}
              </Typography>
              <Typography>
                {t(
                  "Please contact your collegues so they can add you to workplace or create workplace if can and want to."
                )}
              </Typography>
            </Box>
          </Box>
        </BasicMenuLayout>
      );
    }
    return (
      <IsUserAuthorised permission={Permissions.ViewWorkplace} display404Page>
        <Redirect
          to={`${WORKPLACE + PATROLS_LIST}?${
            QueryVariablesEnum.WORKPLACEID
          }=${chosenWorkplaceId}`}
        />
      </IsUserAuthorised>
    );
  }
  return <FullScreenLoader open />;
}
