import { createUploadLink } from "apollo-upload-client";
import {
  ApolloClient,
  ApolloLink,
  concat,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";

function getCookie(name: string): string | undefined {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const v = parts.pop();
    if (v) {
      return v.split(";").shift();
    }
  }
  return undefined;
}

const authMiddleware = (): ApolloLink =>
  new ApolloLink((operation, forward) => {
    const authToken = getCookie("authToken");
    if (authToken) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
    }

    return forward(operation);
  });

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GATEWAY_URL,
  headers: {
    "accept-language": "pl",
  },
});

export const useAppApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    link: concat(authMiddleware(), httpLink),
    cache: new InMemoryCache(),
    connectToDevTools: process.env.NODE_ENV === "development",
  });
};
