import { makeStyles } from "@material-ui/core";
import React from "react";
import logoBlack from "../../images/logoblack.png";
import logoWhite from "../../images/logowhite.png";

const useStyles = makeStyles({
  img: {
    height: "auto",
  },
});

export default function Logo({
  isWhite,
  width,
}: {
  isWhite?: boolean;
  width?: string;
}): JSX.Element {
  const classes = useStyles();
  return (
    <div>
      <img
        src={isWhite ? logoWhite : logoBlack}
        alt="RIDS logo"
        className={classes.img}
        style={{ width: width || "80px" }}
      />
    </div>
  );
}
