import React, { useState } from "react";
import { useUploadPatrolEventAttachmentsMutation } from "../../../../../graphql/mutation.generated";
import { GET_PATROL_EVENT_ATTACHMENTS } from "../../../../../graphql/query";
import { GetPatrolEventAttachmentsQuery } from "../../../../../graphql/query.generated";
import PatrolEventAttachmentsView from "./PatrolEventAttachmentsView";

interface Props {
  patrolEventId: string;
  canUserEdit: boolean;
}
export default function PatrolEventAttachments({
  patrolEventId,
  canUserEdit,
}: Props): JSX.Element {
  const [addAttachmentSuccess, setAddAttachmentSuccess] = useState(false);

  const [
    uploadPatrolEventAttachmentsMutation,
    { loading, error },
  ] = useUploadPatrolEventAttachmentsMutation({
    update: (cache, res) => {
      if (res.data?.uploadPatrolEventAttachments) {
        const resUpload = res.data.uploadPatrolEventAttachments;
        const cachedData = cache.readQuery<GetPatrolEventAttachmentsQuery>({
          query: GET_PATROL_EVENT_ATTACHMENTS,
          variables: { patrolEventId },
        });
        if (cachedData?.getPatrolEventAttachments) {
          const newPatrolEventAttachments = [
            ...cachedData.getPatrolEventAttachments,
            ...resUpload,
          ];
          const newPatrolEventAttachmentsQuery: GetPatrolEventAttachmentsQuery = {
            getPatrolEventAttachments: newPatrolEventAttachments,
          };

          cache.writeQuery({
            query: GET_PATROL_EVENT_ATTACHMENTS,
            variables: { patrolEventId },
            data: newPatrolEventAttachmentsQuery,
          });
        }
      }
    },
  });

  const onFileUpload = async (files: File[]): Promise<void> => {
    try {
      const res = await uploadPatrolEventAttachmentsMutation({
        variables: {
          files,
          patrolEventId,
        },
      });
      if (res.data) {
        setAddAttachmentSuccess(true);
      }
    } catch (err) {
      //
    }
  };

  const onFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const { validity, files } = event.target;
    const filesToUpload = Object.values(files || {});
    if (validity.valid && filesToUpload.length) {
      onFileUpload(filesToUpload);
    }
  };

  return (
    <PatrolEventAttachmentsView
      canUserEdit={canUserEdit}
      addAttachmentSuccess={addAttachmentSuccess}
      closeAttachmentAddSuccess={(): void => setAddAttachmentSuccess(false)}
      onFileChange={onFileChange}
      patrolEventId={patrolEventId}
      uploadFileLoading={loading}
      uploadFileError={error}
    />
  );
}
