import { Box, Button, Typography } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { useTranslation } from "react-i18next";
import FinishDateDisplay from "../../../../components/FinishDateDisplay/FinishDateDisplay";
import TaskFriendlyId from "../../common/TaskFriendlyId";
import { TaskDetailsFragment } from "../../graphql/fragment.generated";
import TaskAssignees from "./TaskAssignees/TaskAssignees";
import TaskCategories from "./TaskCategories/TaskCategories";
import TaskDescription from "./TaskDescription/TaskDescription";
import TaskDueDate from "./TaskDueDate/TaskDueDate";
import TaskLabels from "./TaskLabels/TaskLabels";
import TaskLocation from "./TaskLocation/TaskLocation";
import TaskName from "./TaskName/TaskName";
import TaskReportedDate from "./TaskReportedDate/TaskReportedDate";
import TaskReporter from "./TaskReporter/TaskReporter";
import TaskStatusPicker from "./TaskStatusPicker/TaskStatusPicker";

interface Props {
  task: TaskDetailsFragment;
  workplaceShortName?: string;
  viewMore: boolean;
  onViewMoreChange: () => void;
  canUserEditTask: boolean;
  canUserEditTaskStatus: boolean;
}
export default function TaskBasicInfoView({
  task: {
    id,
    assignees,
    categories,
    friendlyId,
    labels,
    name,
    status,
    description,
    dueDate,
    finishedDate,
    location,
    parent,
    reportedDate,
    reporter,
  },
  workplaceShortName,
  viewMore,
  onViewMoreChange,
  canUserEditTask,
  canUserEditTaskStatus,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Box>
      <Box mb={0.5}>
        <TaskFriendlyId
          friendlyId={friendlyId}
          workplaceShortName={workplaceShortName}
          isChild={Boolean(parent)}
        />
        <TaskName taskId={id} taskName={name} disabled={!canUserEditTask} />
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Box mr={2}>
          <TaskLocation
            location={location || undefined}
            taskId={id}
            disabled={!canUserEditTask}
          />
        </Box>
        {finishedDate && <FinishDateDisplay finishDate={finishedDate} withTime />}
        {!finishedDate && dueDate && (
          <TaskDueDate taskId={id} taskDueDate={dueDate} disabled={!canUserEditTask} />
        )}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
        <Box width="47.5%">
          <TaskStatusPicker
            statusId={status.id}
            taskId={id}
            disabled={!canUserEditTaskStatus}
          />
        </Box>
        <Box width="47.5%">
          <TaskAssignees assignees={assignees} taskId={id} disabled={!canUserEditTask} />
        </Box>
      </Box>

      <Box textAlign="center">
        <Button size="small" onClick={() => onViewMoreChange()}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box mr={1}>
              {viewMore ? (
                <Typography variant="caption">{t("Show less details")}</Typography>
              ) : (
                <Typography variant="caption">{t("View more details")}</Typography>
              )}
            </Box>
            {viewMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
        </Button>
      </Box>

      {viewMore && (
        <>
          <Box mb={1}>
            <TaskDescription
              description={description || undefined}
              taskId={id}
              disabled={!canUserEditTask}
            />
          </Box>
          <Box mb={1}>
            <TaskDueDate
              taskId={id}
              taskDueDate={dueDate}
              listedView
              disabled={!canUserEditTask}
            />
          </Box>
          <Box mb={1}>
            <TaskReportedDate
              taskId={id}
              reportedDate={reportedDate}
              disabled={!canUserEditTask}
            />
          </Box>
          <Box mb={1}>
            <TaskReporter
              taskId={id}
              reporterId={reporter?.id}
              disabled={!canUserEditTask}
            />
          </Box>
          <Box mb={1}>
            <TaskCategories
              taskId={id}
              categories={categories}
              disabled={!canUserEditTask}
            />
          </Box>
          <Box mb={1}>
            <TaskLabels taskId={id} labels={labels} disabled={!canUserEditTask} />
          </Box>
        </>
      )}
    </Box>
  );
}
