import { yupResolver } from "@hookform/resolvers/yup";
import { TFunction } from "i18next";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { UserContext } from "../../../context/UserContext/UserContextProvider";
import { SIGN_IN } from "../../../routing/paths";
import useLogout from "../../../utils/AuthUtils/useLogout";
import { phoneValidator, requiredStringValidator } from "../../../utils/formValidators";
import { useUpdateUserMutation } from "../graphql/mutation.generated";
import { useGetUserDataQuery } from "../graphql/query.generated";
import { AccountSettingsFormData } from "./AccountSettingsFormData";
import AccountSettingsPageView from "./AccountSettingsPageView";

const schema = (t: TFunction): yup.AnyObjectSchema =>
  yup.object().shape({
    firstName: requiredStringValidator(t),
    lastName: requiredStringValidator(t),
    phoneNumber: phoneValidator(t),
  });

export default function AccountSettingsPage(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const [logout] = useLogout();
  const { email } = useContext(UserContext);

  const formMethods = useForm<AccountSettingsFormData>({
    resolver: yupResolver(schema(t)),
  });

  const { loading: userDataLoading, error: userDataError } = useGetUserDataQuery({
    onCompleted: (fetchedData) => {
      formMethods.setValue("firstName", fetchedData.getUser.firstName || "");
      formMethods.setValue("lastName", fetchedData.getUser.lastName || "");
      formMethods.setValue("phoneNumber", fetchedData.getUser.phoneNumber || "");
    },
  });

  const [
    updateUserMutation,
    { loading: userUpdateLoading, error: userUpdateError },
  ] = useUpdateUserMutation({});

  const onFormSubmit = async (formData: AccountSettingsFormData): Promise<void> => {
    try {
      const res = await updateUserMutation({
        variables: {
          updateUserData: {
            ...formData,
          },
        },
      });

      if (res.data) {
        formMethods.reset({ ...formData });
      }
    } catch (e) {
      //
    }
  };

  const onLogout = (): void => {
    logout();
    history.push(SIGN_IN);
  };

  return (
    <AccountSettingsPageView
      formMethods={formMethods}
      onFormSubmit={onFormSubmit}
      userEmail={email || ""}
      error={userDataError || userUpdateError}
      loading={userDataLoading || userUpdateLoading}
      onLogout={onLogout}
    />
  );
}
