import {
  Box,
  CircularProgress,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { Comment } from "../../../../generated/types";
import theme from "../../../../utils/theme";
import SpeakerNotesOffIcon from "@material-ui/icons/SpeakerNotesOff";
import { ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";
import CreateComment from "./CreateComment/CreateComment";
import SingleCommentView from "./SingleComment/SingleCommentView";
import OwnComment from "./OwnComment/OwnComment";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

interface Props {
  comments: Comment[];
  userId?: string;
  commentsLoading?: boolean;
  commentsLoadingError?: ApolloError;
  notDeletableComments?: boolean;
  taskId: string;
  onCommentsClose: () => void;
}

const useStyles = makeStyles({
  wrapper: {
    overflowY: "auto",
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
});

export default function TaskCommentsView({
  comments,
  commentsLoading,
  userId,
  commentsLoadingError,
  notDeletableComments,
  taskId,
  onCommentsClose,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = (): void => {
    if (messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current.scrollBy(0, messagesEndRef.current.clientHeight);
    }
  };

  useEffect(scrollToBottom, [comments]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      border={`1px solid ${theme.palette.divider}`}
      borderRadius={theme.shape.borderRadius}
    >
      <Box display="flex" justifyContent="flex-end" pr={1} height="7%">
        <IconButton onClick={onCommentsClose} size="small">
          <HighlightOffIcon />
        </IconButton>
      </Box>

      {commentsLoading && (
        <Box display="flex" justifyContent="center" alignItems="center" height="150px">
          <CircularProgress size={30} />
        </Box>
      )}
      {Boolean(comments.length) && (
        <div ref={messagesEndRef} className={classes.wrapper} style={{ height: "83%" }}>
          {comments.map((commentData) => {
            const { id, content, createdAt, updatedAt, author } = commentData;
            if (commentData.author && commentData.author.id === userId) {
              return (
                <Box mb={1} key={`owncomment${id}`}>
                  <OwnComment
                    id={id}
                    content={content}
                    createdAt={createdAt}
                    updatedAt={updatedAt}
                    notDeletable={notDeletableComments}
                  />
                </Box>
              );
            }
            return (
              <Box mb={1} key={`singlecomment${id}`}>
                <SingleCommentView
                  author={author || undefined}
                  content={content}
                  createdAt={createdAt}
                  updatedAt={updatedAt}
                />
              </Box>
            );
          })}
        </div>
      )}
      {comments.length === 0 && (
        <Box
          mb={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="80%"
        >
          <Box mr={1}>
            <SpeakerNotesOffIcon fontSize="small" color="secondary" />
          </Box>
          <Typography variant="body2" color="secondary" align="center">
            {t("Comments are empty.")}
          </Typography>
        </Box>
      )}
      {userId && (
        <Box mt={1} height="10%" display="flex" alignItems="flex-end">
          <Box width="100%" pb={1}>
            <CreateComment taskId={taskId} />
          </Box>
        </Box>
      )}
      <ErrorDisplay
        error={commentsLoadingError}
        message={t(
          "Error occured while getting task's comments, please try again or contact us."
        )}
      />
    </Box>
  );
}
