import React, { useContext, useEffect, useMemo } from "react";
import { CompanyContext } from "../../../../../context/CompanyContext/CompanyContextProvider";
import { Label } from "../../../../../generated/types";
import { useChangeTaskLabelsMutation } from "../../../graphql/mutation.generated";
import { useGetWorkplaceLabelsLazyQuery } from "../../../graphql/query.generated";
import TaskLabelsView from "./TaskLabelsView";

interface Props {
  taskId: string;
  labels?: Label[];
  disabled: boolean;
}
export default function TaskLabels({ taskId, labels, disabled }: Props): JSX.Element {
  const { chosenCompanyId } = useContext(CompanyContext);

  const [
    changeTaskLabelsMutation,
    { error: labelsChangeError, loading: changeLabelsLoading },
  ] = useChangeTaskLabelsMutation();

  const [
    getWorkplaceLabelsQuery,
    { data: labelsData, error: fetchingLabelsError, loading: labelsDataLoading },
  ] = useGetWorkplaceLabelsLazyQuery();

  useEffect(() => {
    if (chosenCompanyId) {
      getWorkplaceLabelsQuery({ variables: { companyId: chosenCompanyId } });
    }
  }, [getWorkplaceLabelsQuery, chosenCompanyId]);

  const parsedLabels = useMemo(() => {
    return labels?.map((c) => ({ id: c.id })) || [];
  }, [labels]);

  const parsedLabelsToPick = useMemo(() => {
    return labelsData?.getLabels.map((c) => ({ id: c.id })) || [];
  }, [labelsData?.getLabels]);

  const namesToDisplay = useMemo(() => {
    return labelsData?.getLabels.map((c) => ({ optionId: c.id, name: c.name })) || [];
  }, [labelsData?.getLabels]);

  const onLabelsChange = async (value: { id: string }[]): Promise<void> => {
    const parsedValue = value.map((v) => v.id);
    try {
      await changeTaskLabelsMutation({
        variables: {
          changeTaskLabelsData: {
            labelsIds: parsedValue,
            id: taskId,
          },
        },
      });
    } catch (e) {
      //
    }
  };
  return (
    <TaskLabelsView
      labels={parsedLabels}
      labelsToPick={parsedLabelsToPick}
      namesToDisplay={namesToDisplay}
      onChange={onLabelsChange}
      changingLabelsError={labelsChangeError}
      disabled={disabled}
      loading={changeLabelsLoading || labelsDataLoading}
      fetchingLabelsError={fetchingLabelsError}
    />
  );
}
