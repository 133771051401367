import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import React from "react";
import { useUpdatePatrolStartDateMutation } from "../../../../graphql/mutation.generated";
import PatrolStartDateView from "./PatrolStartDateView";

interface Props {
  patrolStartDate: string;
  patrolId: string;
  disabled?: boolean;
}
export default function PatrolStartDate({
  patrolStartDate,
  patrolId,
  disabled,
}: Props): JSX.Element {
  const [
    updateStartDateMutation,
    { error, loading },
  ] = useUpdatePatrolStartDateMutation();

  const onStartDateChange = async (date: MaterialUiPickersDate): Promise<void> => {
    if (date) {
      const newValue = moment(date).toISOString();
      try {
        await updateStartDateMutation({
          variables: { updatePatrolStartDateData: { id: patrolId, startDate: newValue } },
        });
      } catch (e) {
        //
      }
    }
  };
  return (
    <PatrolStartDateView
      onStartDateChange={onStartDateChange}
      patrolStartDate={patrolStartDate}
      error={error}
      loading={loading}
      disabled={disabled}
    />
  );
}
