import { ApolloError } from "@apollo/client";
import { Box, Button, Typography } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../../../../components/ErrorDisplay/ErrorDisplay";
import LoadingSnackbar from "../../../../../../../components/LoadingSnackbar/LoadingSnackbar";
import SuccessDisplay from "../../../../../../../components/SuccessDisplay/SuccessDisplay";
import EventAttachmentsList from "./EventAttachmentsList";

interface Props {
  uploadFileLoading?: boolean;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  patrolEventId: string;
  addAttachmentSuccess: boolean;
  closeAttachmentAddSuccess: () => void;
  uploadFileError?: ApolloError;
  canUserEdit: boolean;
}

export default function PatrolEventAttachmentsView({
  uploadFileLoading,
  onFileChange,
  patrolEventId,
  addAttachmentSuccess,
  closeAttachmentAddSuccess,
  canUserEdit,
  uploadFileError,
}: Props): JSX.Element {
  const [moreInfoExpanded, setMoreInfoExpanded] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <>
      <Box mb={1.5}>
        <label htmlFor="camera-file">
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="camera-file"
            type="file"
            capture="camera"
            onChange={onFileChange}
          />
          <Button fullWidth variant="contained" color="primary" component="span">
            {t("Make photo")}
          </Button>
        </label>
      </Box>
      <Box mb={1.5}>
        <label htmlFor="file">
          <input
            style={{ display: "none" }}
            id="file"
            type="file"
            accept="image/*,video/*,audio/*"
            onChange={onFileChange}
          />
          <Button fullWidth variant="contained" color="primary" component="span">
            {t("+ Add file")}
          </Button>
        </label>
      </Box>
      <Box textAlign="center">
        <Button size="small" onClick={() => setMoreInfoExpanded(!moreInfoExpanded)}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box mr={1}>
              {moreInfoExpanded ? (
                <Typography variant="caption">{t("Hide attachments")}</Typography>
              ) : (
                <Typography variant="caption">{t("Display attachments")}</Typography>
              )}
            </Box>
            {moreInfoExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
        </Button>
      </Box>
      {moreInfoExpanded && (
        <Box my={1}>
          <EventAttachmentsList canUserEdit={canUserEdit} patrolEventId={patrolEventId} />
        </Box>
      )}
      <SuccessDisplay
        message={t("File added.")}
        open={addAttachmentSuccess}
        onClose={closeAttachmentAddSuccess}
      />
      <LoadingSnackbar
        open={Boolean(uploadFileLoading)}
        message={`${t("Adding file")}...`}
      />
      <ErrorDisplay
        error={uploadFileError}
        message={t(
          "Error occured while uploading files, please try again or contact us."
        )}
      />
    </>
  );
}
