import React, { useEffect, useState } from "react";
import { useUpdateTaskNameMutation } from "../../../graphql/mutation.generated";
import TaskNameView from "./TaskNameView";

interface Props {
  taskId: string;
  taskName: string;
  disabled: boolean;
}
export default function TaskName({ taskId, taskName, disabled }: Props): JSX.Element {
  const [updateTaskNameMutation, { error }] = useUpdateTaskNameMutation();

  const [name, setName] = useState<string>(taskName);

  useEffect(() => {
    if (taskName) {
      setName(taskName);
    }
  }, [taskName]);

  const changeNameState = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value);
  };

  const onNameBlur = async (): Promise<void> => {
    if (name !== taskName) {
      try {
        await updateTaskNameMutation({
          variables: {
            updateTaskNameData: {
              id: taskId,
              name,
            },
          },
        });
      } catch (e) {
        //
      }
    }
  };

  return (
    <TaskNameView
      changeNameState={changeNameState}
      error={error}
      name={name}
      onNameBlur={onNameBlur}
      disabled={disabled}
    />
  );
}
