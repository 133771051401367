import { ApolloError } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { UseFormMethods } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../../components/ErrorDisplay/ErrorDisplay";
import FormTextFieldWrapper from "../../../../../components/FormTextFieldWrapper/FormTextFieldWrapper";
import { CreateChildFormData } from "./CreateChildFormData";

interface Props {
  onClose: () => void;
  formMethods: UseFormMethods<CreateChildFormData>;
  onFormSubmit: (formData: CreateChildFormData) => void;
  addChildLoading?: boolean;
  addChildError?: ApolloError;

  addParentValuesChecked: boolean;
  changeAddParentValuesCheck: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: boolean
  ) => void;
}
export default function AddChildTaskDialogView({
  onClose,
  formMethods: { handleSubmit, register, errors },
  onFormSubmit,
  addChildError,
  addChildLoading,
  addParentValuesChecked,
  changeAddParentValuesCheck,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <Box px={4} py={3}>
        <Box mb={3}>
          <Typography variant="h6">{t("Add child task")}</Typography>
        </Box>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <FormTextFieldWrapper>
            <TextField
              name="name"
              label={`${t("Task name")}*`}
              fullWidth
              variant="outlined"
              autoFocus
              inputRef={register}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </FormTextFieldWrapper>
          <Box mb={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={addParentValuesChecked}
                  onChange={changeAddParentValuesCheck}
                  color="primary"
                />
              }
              label={t("Add parent task values")}
            />
          </Box>
          <Box display="flex" justifyContent="space-between" pt={2}>
            <Button onClick={onClose} variant="outlined">
              {t("Cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={addChildLoading}
            >
              {t("Add Task")}
            </Button>
          </Box>
        </form>
      </Box>
      <ErrorDisplay
        error={addChildError}
        message={t("Error occured while creating task, please try again or contact us.")}
        codes={{
          BAD_USER_INPUT: t(
            "Provided data is invalid. Check if provided dates are not colliding with each other."
          ),
        }}
      />
    </Dialog>
  );
}
