import { ApolloError } from "@apollo/client";
import { TextField } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import EditableInputAdornment from "../../../../../../components/EditableInputAdornment/EditableInputAdornment";
import ErrorDisplay from "../../../../../../components/ErrorDisplay/ErrorDisplay";

interface Props {
  error?: ApolloError;
  loading?: boolean;
  disabled?: boolean;
  locationState?: string;
  onLocationChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onLocationBlur: () => void;
}
export default function PatrolLocationView({
  onLocationBlur,
  onLocationChange,
  disabled,
  error,
  loading,
  locationState,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <TextField
        fullWidth
        value={locationState}
        onChange={onLocationChange}
        onBlur={onLocationBlur}
        label={t("Location")}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <EditableInputAdornment display={Boolean(locationState) && !disabled} />
          ),
        }}
        placeholder={t("Add location")}
        disabled={disabled || loading}
      />
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while changing location, please try again or contact us."
        )}
      />
    </>
  );
}
