import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { WorkplaceContext } from "../../context/WorkplaceContext/WorkplaceContextProvider";
import { Workplace } from "../../generated/types";
import useManageUrlQueryVariables from "../../hooks/useManageUrlQueryVariables";
import WorkplacePickerView from "./WorkplacePickerView";

interface Props {
  workplaces: Workplace[];
  workplaceChooseDisabled?: boolean;
}
export default function WorkplacePicker({
  workplaces,
  workplaceChooseDisabled,
}: Props): JSX.Element {
  const { chosenWorkplaceId, setCurrentWorkplace } = useContext(WorkplaceContext);
  const history = useHistory();
  const { workplaceId: urlWorkplaceId } = useManageUrlQueryVariables();

  const onWorkplaceChange = (event): void => {
    if (setCurrentWorkplace) {
      setCurrentWorkplace(event.target.value);
    }
    if (urlWorkplaceId && event.target.value !== urlWorkplaceId) {
      const newPath = history.location.pathname;
      const newSearch = history.location.search.replace(
        urlWorkplaceId,
        event.target.value
      );
      history.push({
        pathname: newPath,
        search: newSearch,
      });
    }
  };

  return (
    <WorkplacePickerView
      chosenWorkplaceId={chosenWorkplaceId}
      onWorkplaceChange={onWorkplaceChange}
      workplaces={workplaces}
      workplaceChooseDisabled={workplaceChooseDisabled}
    />
  );
}
