import { ApolloError } from "@apollo/client";
import { Box } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDateTimePicker from "../../../../../../../components/CustomDateTimePicker/CustomDateTimePicker";
import EditableInputAdornment from "../../../../../../../components/EditableInputAdornment/EditableInputAdornment";
import ErrorDisplay from "../../../../../../../components/ErrorDisplay/ErrorDisplay";

interface Props {
  error?: ApolloError;
  disabled?: boolean;
  loading?: boolean;
  eventDate: string;
  onEventDateChange: (date: MaterialUiPickersDate) => Promise<void>;
}
export default function PatrolEventEventDateView({
  eventDate,
  disabled,
  error,
  loading,
  onEventDateChange,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <Box display="flex">
        <CustomDateTimePicker
          disabled={disabled || loading}
          label={t("Event date")}
          placeholder={t("Pick event date")}
          value={eventDate}
          onChange={onEventDateChange}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <EditableInputAdornment display={Boolean(eventDate) && !disabled} />
            ),
          }}
          size="small"
        />
      </Box>
      <ErrorDisplay
        error={error}
        codes={{
          BAD_USER_INPUT: t(
            "Provided date is incorrect, please check if event date does not exceed patrol dates."
          ),
        }}
        message={t(
          "Error occured while changing event date, please try again or contact us"
        )}
      />
    </>
  );
}
