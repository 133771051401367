import React, { createContext, useEffect, useReducer } from "react";
import { Permissions } from "../../generated/types";
import { PropsChild } from "../../interfaces/PropsChild";
import { useAuthToken } from "../../utils/AuthUtils/authToken";
import { useGetUserLazyQuery } from "../graphql/query.generated";

export interface UserContextData {
  loading?: boolean;
  name?: string | null;
  email?: string | null;
  permissions?: UserPermissions;
  id?: string | null;
  clearUserCtx?: () => void;
}

interface UserPermissions {
  systemPermissions: Permissions[];
  companyPermissions: Permissions[];
  workplacePermissions: {
    [key: string]: Permissions[];
  };
}

interface UserBasicInfo {
  name: string;
  email: string;
  id: string;
}

const initContextData: UserContextData = {
  permissions: {
    systemPermissions: [],
    companyPermissions: [],
    workplacePermissions: {},
  },
};

export const UserContext = createContext<UserContextData>(initContextData);

enum StateActions {
  SET_USER_BASIC_INFO = "SET_USER_BASIC_INFO",
  SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS",
  CLEAR_USER_CONTEXT = "CLEAR_USER_CONTEXT",
  SET_USER_LOADING = "SET_USER_LOADING",
}

const reducer = (
  state: UserContextData,
  action: { type: string; payload?: UserBasicInfo | UserPermissions | boolean }
): UserContextData => {
  switch (action.type) {
    case StateActions.SET_USER_BASIC_INFO:
      if (action.payload) {
        return {
          ...state,
          ...(action.payload as UserBasicInfo),
        };
      }
      return { ...state };
    case StateActions.SET_USER_LOADING:
      return { ...state, loading: action.payload as boolean };
    case StateActions.SET_USER_PERMISSIONS:
      return { ...state, permissions: action.payload as UserPermissions };
    case StateActions.CLEAR_USER_CONTEXT:
      return {};
    default:
      throw new Error();
  }
};

export default function UserContextProvider({ children }: PropsChild): JSX.Element {
  const [token] = useAuthToken();
  const [getUserQuery, { data }] = useGetUserLazyQuery({ fetchPolicy: "network-only" });
  const initState: UserContextData = {
    ...initContextData,
    loading: true,
    clearUserCtx: () => {
      dispatch({ type: StateActions.CLEAR_USER_CONTEXT });
    },
  };
  const [state, dispatch] = useReducer(reducer, initState);

  useEffect(() => {
    if (token) {
      getUserQuery();
    }
  }, [getUserQuery, token]);

  useEffect(() => {
    if (data?.getUser) {
      const {
        firstName,
        userCompanyRoles,
        userSystemRole,
        email,
        userWorkplaceRoles,
        id,
      } = data.getUser;

      dispatch({
        type: StateActions.SET_USER_BASIC_INFO,
        payload: { name: firstName, email, id } as UserBasicInfo,
      });

      const userPermissions: UserPermissions = {
        systemPermissions: [],
        companyPermissions: [],
        workplacePermissions: {},
      };
      userPermissions.systemPermissions = userSystemRole?.systemRole?.permissions || [];

      if (userCompanyRoles?.length) {
        // Assume that user has only 1 company
        userPermissions.companyPermissions =
          userCompanyRoles[0]?.companyRole?.permissions || [];
      }

      if (userWorkplaceRoles?.length) {
        userWorkplaceRoles.forEach((workplaceRole) => {
          userPermissions.workplacePermissions[workplaceRole.workplace.id] =
            workplaceRole.workplaceRole.permissions;
        });
      }

      dispatch({
        type: StateActions.SET_USER_PERMISSIONS,
        payload: userPermissions,
      });
      dispatch({
        type: StateActions.SET_USER_LOADING,
        payload: false,
      });
    }
  }, [data]);

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
}
