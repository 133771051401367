import React, { useState } from "react";
import { useUpdatePatrolEventActionMutation } from "../../../../../graphql/mutation.generated";
import PatrolEventActionView from "./PatrolEventActionView";

interface Props {
  patrolEventId: string;
  eventAction?: string;
  disabled?: boolean;
}

export default function PatrolEventAction({
  eventAction,
  patrolEventId,
  disabled,
}: Props): JSX.Element {
  const [
    updateDescriptionMutation,
    { loading, error },
  ] = useUpdatePatrolEventActionMutation();

  const [actionState, setActionState] = useState<string | undefined>(eventAction);

  const onActionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setActionState(event.target.value);
  };

  const onActionBlur = async (): Promise<void> => {
    if (actionState !== eventAction) {
      try {
        await updateDescriptionMutation({
          variables: {
            updatePatrolEventActionData: {
              id: patrolEventId,
              action: actionState,
            },
          },
        });
      } catch (e) {
        //
      }
    }
  };

  return (
    <PatrolEventActionView
      actionState={actionState || ""}
      onActionBlur={onActionBlur}
      onActionChange={onActionChange}
      disabled={disabled}
      error={error}
      loading={loading}
    />
  );
}
