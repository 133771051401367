import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";
import { useTranslation } from "react-i18next";
import ListedDateTimePicker from "../../../../../components/ListedDateTimePicker/ListedDateTimePicker";
import { useUpdateTaskReportedDateMutation } from "../../../graphql/mutation.generated";

interface Props {
  taskId: string;
  reportedDate?: string;
  disabled: boolean;
}
export default function TaskReportedDate({
  taskId,
  reportedDate,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [
    updateTaskReportedDateMutation,
    { error, loading },
  ] = useUpdateTaskReportedDateMutation();

  const changeReportedDate = async (newDate?: MaterialUiPickersDate): Promise<void> => {
    try {
      await updateTaskReportedDateMutation({
        variables: {
          updateTaskReportedDateData: {
            id: taskId,
            reportedDate: newDate?.toISOString() || null,
          },
        },
      });
    } catch (e) {
      //
    }
  };

  const onDateClear = (): void => {
    changeReportedDate(null);
  };

  return (
    <ListedDateTimePicker
      onDateChange={changeReportedDate}
      disabled={disabled}
      error={error}
      loading={loading}
      onDateClear={onDateClear}
      date={reportedDate}
      label={t("Reported date")}
      placeholder={t("Pick report date")}
    />
  );
}
