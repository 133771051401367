import { ApolloError } from "@apollo/client";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDateTimePicker from "../../../../../../components/CustomDateTimePicker/CustomDateTimePicker";
import EditableInputAdornment from "../../../../../../components/EditableInputAdornment/EditableInputAdornment";
import ErrorDisplay from "../../../../../../components/ErrorDisplay/ErrorDisplay";

interface Props {
  loading?: boolean;
  disabled?: boolean;
  patrolStartDate: string;
  error?: ApolloError;
  onStartDateChange: (date: MaterialUiPickersDate) => void;
}
export default function PatrolStartDateView({
  loading,
  patrolStartDate,
  error,
  disabled,
  onStartDateChange,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <CustomDateTimePicker
        label={t("Start date")}
        disabled={loading}
        placeholder={t("Pick patrol start date")}
        value={patrolStartDate}
        onChange={(date: MaterialUiPickersDate) => onStartDateChange(date)}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <EditableInputAdornment display={Boolean(patrolStartDate) && !disabled} />
          ),
        }}
        size="small"
      />
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while changing start date, please try again or contact us"
        )}
        codes={{
          BAD_USER_INPUT: t(
            "Provided date is invalid, check if date is not exceeding patrol end date."
          ),
        }}
      />
    </>
  );
}
