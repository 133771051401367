import { ApolloError } from "@apollo/client";
import { Box, CircularProgress } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Attachments from "../../../../../../../components/Attachments/Attachments";
import ErrorDisplay from "../../../../../../../components/ErrorDisplay/ErrorDisplay";
import { GetPatrolEventAttachmentsQuery } from "../../../../../graphql/query.generated";

interface Props {
  attachmentsLoading?: boolean;
  deleteAttachmentLoading?: boolean;
  canUserEdit: boolean;
  attachmentsData?: GetPatrolEventAttachmentsQuery;
  onAttachmentDelete: (patrolEventAttachmentId: string) => Promise<void>;
  deleteAttachmentError?: ApolloError;
  attachmentsFetchingError?: ApolloError;
}
export default function EventAttachmentsListView({
  canUserEdit,
  deleteAttachmentLoading,
  onAttachmentDelete,
  attachmentsData,
  attachmentsLoading,
  deleteAttachmentError,
  attachmentsFetchingError,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      {(attachmentsLoading || deleteAttachmentLoading) && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {attachmentsData?.getPatrolEventAttachments && (
        <Attachments
          attachments={attachmentsData.getPatrolEventAttachments.map((a) => ({
            ...a.attachment,
            attachmentWrapperId: a.id,
          }))}
          onAttachmentDelete={onAttachmentDelete}
          fetchingLoading={attachmentsLoading}
          canUserEdit={canUserEdit}
        />
      )}
      <ErrorDisplay
        error={deleteAttachmentError}
        message={t(
          "Error occured while deleting patrol event attachment, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={attachmentsFetchingError}
        message={t(
          "Error occured while getting patrol event attachments, please try again or contact us."
        )}
      />
    </>
  );
}
