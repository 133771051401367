import React from "react";
import { Switch } from "react-router-dom";
import HelmetWrapper from "../components/HelmetWrapper/HelmetWrapper";
import CompanyContextProvider from "../context/CompanyContext/CompanyContextProvider";
import UserContextProvider from "../context/UserContext/UserContextProvider";
import WorkplaceContextProvider from "../context/WorkplaceContext/WorkplaceContextProvider";
import { Permissions } from "../generated/types";
import IsUserAuthorised from "../utils/AuthUtils/IsUserAuthorised";
import ProtectedRoute from "../utils/AuthUtils/ProtectedRoute";
import AccountSettingsPage from "../views/Account/AccountSettingsPage/AccountSettingsPage";
import AfterLoginPage from "../views/Identity/AfterLoginPage/AfterLoginPage";
import PatrolPage from "../views/Patrols/PatrolPage/PatrolPage";
import PatrolsListPage from "../views/Patrols/PatrolsListPage/PatrolsListPage";
import TaskPage from "../views/Tasks/TaskPage/TaskPage";
import TasksListPage from "../views/Tasks/TasksListPage/TasksListPage";
import {
  ACCOUNT_SETTINGS,
  PATROL,
  PATROLS_LIST,
  TASKS,
  TASK_DETAILS,
  WORKPLACE,
} from "./paths";

export default function SignedInUserRoutes(): JSX.Element {
  return (
    <UserContextProvider>
      <CompanyContextProvider>
        <WorkplaceContextProvider>
          <Switch>
            <ProtectedRoute
              exact
              path="/"
              render={(): JSX.Element => (
                <HelmetWrapper siteTitle="Start page">
                  <AfterLoginPage />
                </HelmetWrapper>
              )}
            />
            <ProtectedRoute
              exact
              path={ACCOUNT_SETTINGS}
              render={(): JSX.Element => (
                <HelmetWrapper siteTitle="Account settings">
                  <AccountSettingsPage />
                </HelmetWrapper>
              )}
            />

            {/* Workplace routes */}
            <ProtectedRoute
              path={WORKPLACE}
              render={(routeData): JSX.Element => (
                <>
                  <ProtectedRoute
                    path={routeData.match.url + TASKS}
                    render={(): JSX.Element => (
                      <IsUserAuthorised
                        permission={[Permissions.ViewTasks, Permissions.ViewTasksOwn]}
                        display404Page
                      >
                        <HelmetWrapper siteTitle="Tasks">
                          <TasksListPage />
                        </HelmetWrapper>
                      </IsUserAuthorised>
                    )}
                  />

                  <ProtectedRoute
                    path={routeData.match.url + TASK_DETAILS}
                    render={(): JSX.Element => (
                      <IsUserAuthorised
                        permission={[Permissions.ViewTasks, Permissions.ViewTasksOwn]}
                        display404Page
                      >
                        <HelmetWrapper siteTitle="Task details">
                          <TaskPage />
                        </HelmetWrapper>
                      </IsUserAuthorised>
                    )}
                  />

                  <ProtectedRoute
                    exact
                    path={routeData.match.url + PATROLS_LIST}
                    render={(): JSX.Element => (
                      <IsUserAuthorised
                        permission={[Permissions.ViewPatrols, Permissions.ViewPatrolsOwn]}
                        display404Page
                      >
                        <HelmetWrapper siteTitle="Patrols - List">
                          <PatrolsListPage />
                        </HelmetWrapper>
                      </IsUserAuthorised>
                    )}
                  />

                  <ProtectedRoute
                    exact
                    path={routeData.match.url + PATROL}
                    render={(): JSX.Element => (
                      <IsUserAuthorised
                        permission={[Permissions.ViewPatrols, Permissions.ViewPatrolsOwn]}
                        display404Page
                      >
                        <HelmetWrapper siteTitle="Patrol">
                          <PatrolPage />
                        </HelmetWrapper>
                      </IsUserAuthorised>
                    )}
                  />
                </>
              )}
            />
          </Switch>
        </WorkplaceContextProvider>
      </CompanyContextProvider>
    </UserContextProvider>
  );
}
