import React, { useMemo } from "react";
import useGetWorkplaceUsers from "../../../../../hooks/useGetWorkplaceUsers";
import generateUserNameString from "../../../../../utils/generateUserNameString";
import { useChangeTaskReporterMutation } from "../../../graphql/mutation.generated";
import TaskReporterView from "./TaskReporterView";

interface Props {
  taskId: string;
  reporterId?: string;
  disabled: boolean;
}
export default function TaskReporter({
  taskId,
  reporterId,
  disabled,
}: Props): JSX.Element {
  const [
    changeTaskReporterMutation,
    { error: changeReporterError, loading: changeReporterLoading },
  ] = useChangeTaskReporterMutation();

  const { loading: loadingUsers, workplaceUsers } = useGetWorkplaceUsers();

  const onReporterChange = async (value: string | null): Promise<void> => {
    try {
      await changeTaskReporterMutation({
        variables: {
          changeTaskReporterData: {
            id: taskId,
            reporterId: value,
          },
        },
      });
    } catch (e) {
      //
    }
  };

  const usersToPick = useMemo(() => {
    if (workplaceUsers) {
      return workplaceUsers.map((u) => ({ id: u.id, name: generateUserNameString(u) }));
    }
    return [];
  }, [workplaceUsers]);

  const reporterIdToView = useMemo(() => {
    if (usersToPick.length && reporterId) {
      return reporterId;
    }
    return "";
  }, [reporterId, usersToPick]);

  return (
    <TaskReporterView
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onReporterChange(e.target.value)
      }
      usersToPick={usersToPick}
      disabled={disabled}
      error={changeReporterError}
      loading={loadingUsers || changeReporterLoading}
      reporterId={reporterIdToView}
      onReporterClear={() => onReporterChange(null)}
    />
  );
}
