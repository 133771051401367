import moment from "moment";
import React from "react";
import { PatrolEventFragment } from "../../graphql/fragments.generated";
import PatrolEventsView from "./PatrolEventsView";

interface Props {
  patrolEvents: PatrolEventFragment[];
  canUserEdit: boolean;
}
export default function PatrolEvents({ patrolEvents, canUserEdit }: Props): JSX.Element {
  const sortedPatrolEvents = [...patrolEvents].sort((a, b) => {
    if (moment(a.eventDate).isAfter(moment(b.eventDate))) {
      return -1;
    }
    return 1;
  });
  return <PatrolEventsView canUserEdit={canUserEdit} patrolEvents={sortedPatrolEvents} />;
}
