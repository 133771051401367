import { yupResolver } from "@hookform/resolvers/yup";
import { TFunction } from "i18next";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import useGetWorkplaceUsers from "../../../hooks/useGetWorkplaceUsers";
import { requiredStringValidator } from "../../../utils/formValidators";
import { useCreateTaskMutation } from "../graphql/mutation.generated";
import AddTaskView from "./AddTaskView";
import { TaskFormData } from "./TaskFormData";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { GET_TASKS_LIST } from "../graphql/query";
import moment from "moment";
import generateUserNameString from "../../../utils/generateUserNameString";

interface Props {
  onTaskAdded: (id: string) => void;
  onCancel: () => void;
  workplaceId?: string;
}

const schema = (t: TFunction): yup.AnyObjectSchema =>
  yup.object().shape({
    name: requiredStringValidator(t),
  });

export default function AddTask({
  onCancel,
  onTaskAdded,
  workplaceId,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const formMethods = useForm<TaskFormData>({
    resolver: yupResolver(schema(t)),
  });

  const [
    createTaskMutation,
    { error: createTaskError, loading: createTaskLoading },
  ] = useCreateTaskMutation();

  const { error: usersFetchingError, workplaceUsers } = useGetWorkplaceUsers();

  const onFormSubmit = async (formData: TaskFormData): Promise<void> => {
    if (workplaceId) {
      const assigneesIds = formData.assigneesIds
        ? formData.assigneesIds.map((a) => a.id)
        : undefined;
      const dueDate = formData.dueDate
        ? moment(formData.dueDate).toISOString()
        : undefined;

      try {
        const res = await createTaskMutation({
          variables: {
            createTaskData: {
              ...formData,
              workplaceId,
              assigneesIds,
              dueDate,
            },
          },
          // TODO check if this refetch can be deleted
          refetchQueries: [
            {
              query: GET_TASKS_LIST,
              variables: { workplaceId, filters: null },
            },
          ],
        });

        if (res.data) {
          onTaskAdded(res.data.createTask.id);
        }
      } catch (e) {
        //
      }
    }
  };

  const namesToDisplay = useMemo(() => {
    return (
      workplaceUsers?.map((u) => ({ optionId: u.id, name: generateUserNameString(u) })) ||
      []
    );
  }, [workplaceUsers]);

  const usersToPick = useMemo(() => {
    return workplaceUsers?.map((u) => ({ id: u.id })) || [];
  }, [workplaceUsers]);

  return (
    <AddTaskView
      formMethods={formMethods}
      onCancel={onCancel}
      onFormSubmit={onFormSubmit}
      usersNamesToDisplay={namesToDisplay}
      usersToPick={usersToPick}
      createTaskError={createTaskError}
      fetchingUsersError={usersFetchingError}
      createTaskLoading={createTaskLoading}
    />
  );
}
