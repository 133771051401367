import * as Types from '../../../generated/types';

import { ListPatrolFragment, PatrolFragment, PatrolEventFragment } from './fragments.generated';
import { gql } from '@apollo/client';
import { ListPatrolFragmentDoc, PatrolFragmentDoc, PatrolEventFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPatrolsQueryVariables = Types.Exact<{
  paginationData: Types.PaginationData;
  workplaceId: Types.Scalars['UUID'];
}>;


export type GetPatrolsQuery = { __typename?: 'Query', getPatrols: { __typename?: 'GetPatrolsResponse', totalCount: number, items: Array<(
      { __typename?: 'Patrol' }
      & ListPatrolFragment
    )> } };

export type GetPatrolQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type GetPatrolQuery = { __typename?: 'Query', getPatrol: (
    { __typename?: 'Patrol' }
    & PatrolFragment
  ), getPatrolEvents: Array<(
    { __typename?: 'PatrolEvent' }
    & PatrolEventFragment
  )> };

export type GetPatrolEventAttachmentsQueryVariables = Types.Exact<{
  patrolEventId: Types.Scalars['UUID'];
}>;


export type GetPatrolEventAttachmentsQuery = { __typename?: 'Query', getPatrolEventAttachments: Array<{ __typename?: 'PatrolEventAttachment', id: any, createdAt: any, updatedAt: any, attachment: { __typename?: 'Attachment', id: any, filename: string, mimetype: Types.MimeType, uri: string, createdAt: any, encoding: string, updatedAt: any } }> };


export const GetPatrolsDocument = gql`
    query getPatrols($paginationData: PaginationData!, $workplaceId: UUID!) {
  getPatrols(paginationData: $paginationData, workplaceId: $workplaceId) {
    items {
      ...ListPatrol
    }
    totalCount
  }
}
    ${ListPatrolFragmentDoc}`;

/**
 * __useGetPatrolsQuery__
 *
 * To run a query within a React component, call `useGetPatrolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatrolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatrolsQuery({
 *   variables: {
 *      paginationData: // value for 'paginationData'
 *      workplaceId: // value for 'workplaceId'
 *   },
 * });
 */
export function useGetPatrolsQuery(baseOptions: Apollo.QueryHookOptions<GetPatrolsQuery, GetPatrolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatrolsQuery, GetPatrolsQueryVariables>(GetPatrolsDocument, options);
      }
export function useGetPatrolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatrolsQuery, GetPatrolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatrolsQuery, GetPatrolsQueryVariables>(GetPatrolsDocument, options);
        }
export type GetPatrolsQueryHookResult = ReturnType<typeof useGetPatrolsQuery>;
export type GetPatrolsLazyQueryHookResult = ReturnType<typeof useGetPatrolsLazyQuery>;
export type GetPatrolsQueryResult = Apollo.QueryResult<GetPatrolsQuery, GetPatrolsQueryVariables>;
export const GetPatrolDocument = gql`
    query getPatrol($id: UUID!) {
  getPatrol(id: $id) {
    ...Patrol
  }
  getPatrolEvents(patrolId: $id) {
    ...PatrolEvent
  }
}
    ${PatrolFragmentDoc}
${PatrolEventFragmentDoc}`;

/**
 * __useGetPatrolQuery__
 *
 * To run a query within a React component, call `useGetPatrolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatrolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatrolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPatrolQuery(baseOptions: Apollo.QueryHookOptions<GetPatrolQuery, GetPatrolQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatrolQuery, GetPatrolQueryVariables>(GetPatrolDocument, options);
      }
export function useGetPatrolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatrolQuery, GetPatrolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatrolQuery, GetPatrolQueryVariables>(GetPatrolDocument, options);
        }
export type GetPatrolQueryHookResult = ReturnType<typeof useGetPatrolQuery>;
export type GetPatrolLazyQueryHookResult = ReturnType<typeof useGetPatrolLazyQuery>;
export type GetPatrolQueryResult = Apollo.QueryResult<GetPatrolQuery, GetPatrolQueryVariables>;
export const GetPatrolEventAttachmentsDocument = gql`
    query getPatrolEventAttachments($patrolEventId: UUID!) {
  getPatrolEventAttachments(patrolEventId: $patrolEventId) {
    id
    attachment {
      id
      filename
      mimetype
      uri
      createdAt
      encoding
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetPatrolEventAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetPatrolEventAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatrolEventAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatrolEventAttachmentsQuery({
 *   variables: {
 *      patrolEventId: // value for 'patrolEventId'
 *   },
 * });
 */
export function useGetPatrolEventAttachmentsQuery(baseOptions: Apollo.QueryHookOptions<GetPatrolEventAttachmentsQuery, GetPatrolEventAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatrolEventAttachmentsQuery, GetPatrolEventAttachmentsQueryVariables>(GetPatrolEventAttachmentsDocument, options);
      }
export function useGetPatrolEventAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatrolEventAttachmentsQuery, GetPatrolEventAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatrolEventAttachmentsQuery, GetPatrolEventAttachmentsQueryVariables>(GetPatrolEventAttachmentsDocument, options);
        }
export type GetPatrolEventAttachmentsQueryHookResult = ReturnType<typeof useGetPatrolEventAttachmentsQuery>;
export type GetPatrolEventAttachmentsLazyQueryHookResult = ReturnType<typeof useGetPatrolEventAttachmentsLazyQuery>;
export type GetPatrolEventAttachmentsQueryResult = Apollo.QueryResult<GetPatrolEventAttachmentsQuery, GetPatrolEventAttachmentsQueryVariables>;