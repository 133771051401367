import React, { useContext, useEffect, useMemo } from "react";
import { CompanyContext } from "../../../../../context/CompanyContext/CompanyContextProvider";
import { Category } from "../../../../../generated/types";
import { useChangeTaskCategoriesMutation } from "../../../graphql/mutation.generated";
import { useGetWorkplaceCategoriesLazyQuery } from "../../../graphql/query.generated";
import TaskCategoriesView from "./TaskCategoriesView";

interface Props {
  taskId: string;
  categories?: Category[];
  disabled: boolean;
}
export default function TaskCategories({
  taskId,
  categories,
  disabled,
}: Props): JSX.Element {
  const { chosenCompanyId } = useContext(CompanyContext);
  const [
    getWorkplaceCategoriesQuery,
    {
      data: categoriesData,
      error: categoriesLoadingError,
      loading: categoriesDataLoading,
    },
  ] = useGetWorkplaceCategoriesLazyQuery();

  const [
    changeTaskCategoriesMutation,
    { error: categoriesChangeError, loading: categoriesChangeLoading },
  ] = useChangeTaskCategoriesMutation();

  useEffect(() => {
    if (chosenCompanyId) {
      getWorkplaceCategoriesQuery({ variables: { companyId: chosenCompanyId } });
    }
  }, [getWorkplaceCategoriesQuery, chosenCompanyId]);

  const parsedCategories = useMemo(() => {
    return categories?.map((c) => ({ id: c.id })) || [];
  }, [categories]);

  const parsedCategoriesToPick = useMemo(() => {
    return categoriesData?.getCategories.map((c) => ({ id: c.id })) || [];
  }, [categoriesData?.getCategories]);

  const namesToDisplay = useMemo(() => {
    return (
      categoriesData?.getCategories.map((c) => ({ optionId: c.id, name: c.name })) || []
    );
  }, [categoriesData?.getCategories]);

  const onCategoriesChange = async (value: { id: string }[]): Promise<void> => {
    const parsedValue = value.map((v) => v.id);
    try {
      await changeTaskCategoriesMutation({
        variables: {
          changeTaskCategoriesData: {
            categoriesIds: parsedValue,
            id: taskId,
          },
        },
      });
    } catch (e) {
      //
    }
  };
  return (
    <TaskCategoriesView
      categories={parsedCategories}
      categoriesToPick={parsedCategoriesToPick}
      namesToDisplay={namesToDisplay}
      onChange={onCategoriesChange}
      changingCategoryError={categoriesChangeError}
      disabled={disabled}
      loading={categoriesChangeLoading || categoriesDataLoading}
      fetchingCategoryError={categoriesLoadingError}
    />
  );
}
