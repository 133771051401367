import { TFunction } from "i18next";
import * as yup from "yup";

import { phoneRegex } from "./regex";

export function emailValidator(t: TFunction): any {
  return yup
    .string()
    .email(t("Please eneter correct email address"))
    .required(t("This field is required"));
}

export function requiredStringValidator(t: TFunction): any {
  return yup.string().required(t("This field is required"));
}

export function phoneValidator(t: TFunction): any {
  return yup.string().matches(phoneRegex, {
    message: t("Please enter correct phone number"),
    excludeEmptyString: true,
  });
}

export function repeatPasswordValidator(t: TFunction): any {
  return yup
    .string()
    .required(t("This field is required"))
    .oneOf([yup.ref("password")], t("Passwords must be identical"));
}

export function requiredStringMaxLengthValidator(length: number, t: TFunction): any {
  return yup
    .string()
    .required(t("This field is required"))
    .test("length", `${t("Max length of string equals")}: ${length}`, (val) =>
      val ? val.length <= length : false
    );
}

export function passwordValidator(t: TFunction): any {
  return yup
    .string()
    .required(t("Password is required"))
    .test("length", `${t("Max length of string equals")}: 80`, (val) =>
      val ? val.length <= 80 : false
    )
    .test("length", `${t("Min length of string equals")}: 6`, (val) =>
      val ? val.length >= 6 : false
    )
    .matches(/[a-z]/, t("Password should contain at least one lowercase letter"))
    .matches(/[A-Z]/, t("Password should contain at least one uppercase letter"));
}
