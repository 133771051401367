import { ApolloError } from "@apollo/client";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDateTimePicker from "../../../../../components/CustomDateTimePicker/CustomDateTimePicker";
import DueDateDisplay from "../../../../../components/DueDateDisplay/DueDateDisplay";
import ErrorDisplay from "../../../../../components/ErrorDisplay/ErrorDisplay";

interface Props {
  dueDate?: string;
  loading?: boolean;
  disabled?: boolean;
  error?: ApolloError;
  onDateChange: (date: MaterialUiPickersDate) => void;
}
export default function TaskDueDateView({
  dueDate,
  disabled,
  loading,
  error,
  onDateChange,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <CustomDateTimePicker
        value={dueDate}
        disabled={disabled || loading}
        onChange={(date: MaterialUiPickersDate) => onDateChange(date)}
        TextFieldComponent={({ onClick }) => (
          <div onClick={onClick}>
            <DueDateDisplay date={dueDate} withTime />
          </div>
        )}
      />
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while changing due date, please try again or contact us"
        )}
        codes={{
          BAD_USER_INPUT: t(
            "Provided date is invalid, check if date is not in collision with other dates."
          ),
        }}
      />
    </>
  );
}
