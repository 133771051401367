import { gql } from "@apollo/client";
import { listPatrolFragment, patrolEventFragment, patrolFragment } from "./fragments";

export const GET_PATROLS = gql`
  ${listPatrolFragment}
  query getPatrols($paginationData: PaginationData!, $workplaceId: UUID!) {
    getPatrols(paginationData: $paginationData, workplaceId: $workplaceId) {
      items {
        ...ListPatrol
      }
      totalCount
    }
  }
`;

export const GET_PATROL = gql`
  ${patrolEventFragment}
  ${patrolFragment}
  query getPatrol($id: UUID!) {
    getPatrol(id: $id) {
      ...Patrol
    }
    getPatrolEvents(patrolId: $id) {
      ...PatrolEvent
    }
  }
`;

export const GET_PATROL_EVENT_ATTACHMENTS = gql`
  query getPatrolEventAttachments($patrolEventId: UUID!) {
    getPatrolEventAttachments(patrolEventId: $patrolEventId) {
      id
      attachment {
        id
        filename
        mimetype
        uri
        createdAt
        encoding
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
