import { ApolloError } from "@apollo/client";
import { TextField } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../../../../components/ErrorDisplay/ErrorDisplay";

interface Props {
  descriptionState: string;
  onDescriptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDescriptionBlur: () => void;
  disabled?: boolean;
  loading?: boolean;
  error?: ApolloError;
}
export default function PatrolEventDescriptionView({
  descriptionState,
  onDescriptionBlur,
  onDescriptionChange,
  disabled,
  error,
  loading,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <TextField
        fullWidth
        multiline
        rows={2}
        rowsMax={4}
        variant="outlined"
        value={descriptionState}
        onChange={onDescriptionChange}
        onBlur={onDescriptionBlur}
        placeholder={t("Describe what happened")}
        label={t("Event description")}
        disabled={disabled || loading}
      />
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while changing description, please try again or contact us."
        )}
      />
    </>
  );
}
