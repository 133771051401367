import { ApolloError } from "@apollo/client";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { useTranslation } from "react-i18next";
import Attachments from "../../../../components/Attachments/Attachments";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import { Attachment } from "../../../../generated/types";
import AttachFileIcon from "@material-ui/icons/AttachFile";

interface Props {
  attachmentsOpened: boolean;
  changeAttachmentsOpen: () => void;
  attachmentsData?: { attachment: Attachment; attachmentWrapperId: string }[];
  canUserEdit: boolean;
  onAttachmentDelete: (patrolEventAttachmentId: string) => Promise<void>;

  loading?: boolean;

  attachmentsFetchingError?: ApolloError;
  deleteAttachmentError?: ApolloError;
}

export default function TaskAttachmentsView({
  attachmentsOpened,
  changeAttachmentsOpen,
  attachmentsData,
  canUserEdit,
  onAttachmentDelete,
  loading,
  attachmentsFetchingError,
  deleteAttachmentError,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <Box display="flex" alignItems="center" mb={1}>
        <AttachFileIcon color="secondary" />
        <Box ml={0.5} mr={1.5}>
          <Typography variant="h6">{t("Attachments")}</Typography>
        </Box>
        {loading && <CircularProgress size={16} />}
      </Box>
      <Box textAlign="center">
        <Button size="small" onClick={() => changeAttachmentsOpen()}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box mr={1}>
              {attachmentsOpened ? (
                <Typography variant="caption">{t("Hide attachments")}</Typography>
              ) : (
                <Typography variant="caption">{t("Display attachments")}</Typography>
              )}
            </Box>
            {attachmentsOpened ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
        </Button>
      </Box>
      {attachmentsOpened && (
        <Box my={1}>
          {attachmentsData && (
            <Attachments
              attachments={attachmentsData.map((a) => ({
                ...a.attachment,
                attachmentWrapperId: a.attachmentWrapperId,
              }))}
              onAttachmentDelete={onAttachmentDelete}
              canUserEdit={canUserEdit}
            />
          )}
        </Box>
      )}
      <ErrorDisplay
        error={deleteAttachmentError}
        message={t(
          "Error occured while deleting attachment, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={attachmentsFetchingError}
        message={t(
          "Error occured while getting attachments, please try again or contact us."
        )}
      />
    </>
  );
}
