import { ApolloError } from "@apollo/client";
import { TextField } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../../../../components/ErrorDisplay/ErrorDisplay";

interface Props {
  actionState: string;
  onActionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onActionBlur: () => void;
  disabled?: boolean;
  error?: ApolloError;
  loading?: boolean;
}

export default function PatrolEventActionView({
  actionState,
  onActionBlur,
  onActionChange,
  disabled,
  error,
  loading,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <TextField
        fullWidth
        multiline
        rows={2}
        rowsMax={4}
        variant="outlined"
        value={actionState}
        onChange={onActionChange}
        onBlur={onActionBlur}
        placeholder={t("Describe what actions have been taken")}
        label={t("Action taken")}
        disabled={disabled || loading}
      />
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while changing description, please try again or contact us."
        )}
      />
    </>
  );
}
