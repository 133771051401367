import React, { createContext, useEffect, useReducer, useState } from "react";
import { Company } from "../../generated/types";
import { PropsChild } from "../../interfaces/PropsChild";
import { useGetCompaniesQuery } from "../graphql/query.generated";

interface CompanyContextData {
  chosenCompanyId?: string;
  companies?: Company[];
  setCurrentCompany?: (companyId: string) => void;
  clearCompanyContext?: () => void;
  loading?: boolean;
}

enum StateActions {
  SET_CURRENT_COMPANY = "SET_CURRENT_COMPANY",
  SET_COMPANIES = "SET_COMPANIES",
  CLEAR_COMPANY_CONTEXT = "CLEAR_COMPANY_CONTEXT",
  SET_LOADING = "SET_LOADING",
}

export const CompanyContext = createContext<CompanyContextData>({});

const reducer = (
  state: CompanyContextData,
  action: { type: string; payload?: string | Company[] | boolean }
): CompanyContextData => {
  switch (action.type) {
    case StateActions.SET_CURRENT_COMPANY:
      return { ...state, chosenCompanyId: action.payload as string };
    case StateActions.SET_COMPANIES:
      return { ...state, companies: action.payload as Company[] };
    case StateActions.CLEAR_COMPANY_CONTEXT:
      return {};
    case StateActions.SET_LOADING:
      return { ...state, loading: action.payload as boolean };
    default:
      throw new Error();
  }
};

export default function CompanyContextProvider({ children }: PropsChild): JSX.Element {
  const [companiesQueryCompleted, setCompaniesQueryCompleted] = useState<boolean>(false);
  const { data: companiesData } = useGetCompaniesQuery({
    onError: () => {
      setCompaniesQueryCompleted(true);
    },
  });
  const initialState: CompanyContextData = {
    setCurrentCompany: (companyId) => {
      dispatch({ type: StateActions.SET_CURRENT_COMPANY, payload: companyId });
    },
    clearCompanyContext: () => {
      dispatch({ type: StateActions.CLEAR_COMPANY_CONTEXT });
    },
    loading: true,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (companiesData?.getCompanies.length) {
      dispatch({
        type: StateActions.SET_COMPANIES,
        payload: companiesData.getCompanies as Company[],
      });
      dispatch({
        type: StateActions.SET_CURRENT_COMPANY,
        payload: companiesData.getCompanies.length
          ? companiesData.getCompanies[0].id
          : "",
      });
      dispatch({
        type: StateActions.SET_LOADING,
        payload: false,
      });
    } else if (companiesQueryCompleted) {
      dispatch({
        type: StateActions.SET_COMPANIES,
        payload: [] as Company[],
      });
      dispatch({
        type: StateActions.SET_LOADING,
        payload: false,
      });
    } else if (companiesData?.getCompanies.length === 0) {
      dispatch({
        type: StateActions.SET_COMPANIES,
        payload: [] as Company[],
      });
      dispatch({
        type: StateActions.SET_LOADING,
        payload: false,
      });
    }
  }, [companiesQueryCompleted, companiesData]);

  return <CompanyContext.Provider value={state}>{children}</CompanyContext.Provider>;
}
