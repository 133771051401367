import { ApolloError } from "@apollo/client";
import { TextField } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import EditableInputAdornment from "../EditableInputAdornment/EditableInputAdornment";
import ErrorDisplay from "../ErrorDisplay/ErrorDisplay";

interface Props {
  error?: ApolloError;
  loading?: boolean;
  disabled?: boolean;
  descriptionState: string;
  onDescriptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDescriptionBlur: () => void;
}

export default function Description({
  disabled,
  error,
  loading,
  descriptionState,
  onDescriptionBlur,
  onDescriptionChange,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <TextField
        fullWidth
        multiline
        rowsMax={5}
        value={descriptionState}
        onChange={onDescriptionChange}
        onBlur={onDescriptionBlur}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <EditableInputAdornment display={Boolean(descriptionState) && !disabled} />
          ),
        }}
        placeholder={t("Add description")}
        label={t("Description")}
        disabled={disabled || loading}
      />
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while changing description, please try again or contact us."
        )}
      />
    </>
  );
}
