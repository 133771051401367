import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { WorkplaceContext } from "../../../../context/WorkplaceContext/WorkplaceContextProvider";
import useGetWorkplaceDisplayData from "../../../../hooks/useGetWorkplaceDisplayData";
import { QueryVariablesEnum } from "../../../../interfaces/QueryVariables";
import { WORKPLACE, TASK_DETAILS } from "../../../../routing/paths";
import {
  TaskDetailsChildFragment,
  TaskDetailsFragment,
} from "../../graphql/fragment.generated";
import ChildTasksView from "./ChildTasksView";

interface Props {
  childTasks?: TaskDetailsChildFragment[];
  parentTask: TaskDetailsFragment;
  workplaceId?: string;
  canUserEditTask: boolean;
}
export default function ChildTasks({
  childTasks,
  parentTask,
  workplaceId,
  canUserEditTask,
}: Props): JSX.Element {
  const history = useHistory();
  const { chosenWorkplaceId } = useContext(WorkplaceContext);
  const { currentWorkplaceShortName } = useGetWorkplaceDisplayData();

  const [childAddMode, setChildAddMode] = useState(false);

  const onChildClick = (childId: string) => {
    if (chosenWorkplaceId) {
      history.push(
        `${WORKPLACE}${TASK_DETAILS}?${QueryVariablesEnum.WORKPLACEID}=${chosenWorkplaceId}&${QueryVariablesEnum.TASKID}=${childId}`
      );
    }
  };

  return (
    <ChildTasksView
      childTasks={childTasks}
      onChildClick={onChildClick}
      workplaceShortName={currentWorkplaceShortName}
      childAddMode={childAddMode}
      onChildAddModeChange={(open: boolean) => setChildAddMode(open)}
      parentTask={parentTask}
      workplaceId={workplaceId}
      canUserEditTask={canUserEditTask}
    />
  );
}
