import { ApolloError } from "@apollo/client";
import { Box, IconButton, MenuItem, TextField } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../../components/ErrorDisplay/ErrorDisplay";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

interface Props {
  reporterId?: string | null;
  usersToPick: { id: string; name: string }[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  loading?: boolean;
  error?: ApolloError;
  onReporterClear: () => void;
}
export default function TaskReporterView({
  onChange,
  usersToPick,
  reporterId,
  disabled,
  loading,
  error,
  onReporterClear,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <Box display="flex" alignItems="flex-end">
        <TextField
          select
          InputProps={{
            disableUnderline: true,
          }}
          disabled={disabled || loading}
          label={t("Task reporter")}
          InputLabelProps={{ shrink: true }}
          placeholder={t("Select reporter")}
          value={reporterId}
          size="small"
          onChange={onChange}
          style={{ minWidth: "169px" }}
        >
          {usersToPick.map((u) => (
            <MenuItem value={u.id} key={`reporterUser${u.id}`}>
              {u.name}
            </MenuItem>
          ))}
        </TextField>
        {reporterId && !disabled && (
          <Box display="flex" alignItems="center" pb={0.3}>
            <IconButton size="small" onClick={onReporterClear}>
              <CloseOutlinedIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>

      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while changing reporter, please try again or contact us."
        )}
      />
    </>
  );
}
