import React, { useState } from "react";
import { useUpdatePatrolLocationMutation } from "../../../../graphql/mutation.generated";
import PatrolLocationView from "./PatrolLocationView";

interface Props {
  patrolId: string;
  location?: string;
  disabled?: boolean;
}
export default function PatrolLocation({
  location,
  patrolId,
  disabled,
}: Props): JSX.Element {
  const [locationState, setLocationState] = useState<string>(location || "");

  const [updateLocationMutation, { error, loading }] = useUpdatePatrolLocationMutation();

  const onLocationChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setLocationState(event.target.value);
  };

  const onLocationBlur = async (): Promise<void> => {
    try {
      await updateLocationMutation({
        variables: {
          updatePatrolLocationData: { id: patrolId, location: locationState },
        },
      });
    } catch (e) {
      //
    }
  };

  return (
    <PatrolLocationView
      onLocationBlur={onLocationBlur}
      onLocationChange={onLocationChange}
      disabled={disabled}
      error={error}
      loading={loading}
      locationState={locationState}
    />
  );
}
