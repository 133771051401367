import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import React from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useTranslation } from "react-i18next";
import { ApolloError } from "@apollo/client";
import DeleteIcon from "@material-ui/icons/Delete";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";

interface Props {
  handleButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  onDeleteTask: () => void;
  onArchiveTask: () => void;
  isTaskArchived: boolean;
  deleteTaskError?: ApolloError;
  archiveTaskError?: ApolloError;
  loading?: boolean;
  canUserArchiveTask: boolean;
  canUserEditTask: boolean;
}
export default function TaskMoreMenuView({
  anchorEl,
  handleButtonClick,
  handleClose,
  isTaskArchived,
  onDeleteTask,
  deleteTaskError,
  loading,
  onArchiveTask,
  archiveTaskError,
  canUserArchiveTask,
  canUserEditTask,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <IconButton
        size="small"
        aria-controls="more-menu"
        aria-haspopup="true"
        onClick={handleButtonClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="more-menu"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={onDeleteTask}
          disabled={isTaskArchived || loading || !canUserEditTask}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>{t("Delete task")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={onArchiveTask} disabled={loading || !canUserArchiveTask}>
          <ListItemIcon>
            {isTaskArchived ? <UnarchiveIcon /> : <ArchiveIcon />}
          </ListItemIcon>
          <ListItemText>
            {isTaskArchived ? t("Unarchive task") : t("Archive task")}
          </ListItemText>
        </MenuItem>
      </Menu>
      <ErrorDisplay
        error={deleteTaskError}
        message={t("Error occured while deleting task, please try again or contact us.")}
      />
      <ErrorDisplay
        error={archiveTaskError}
        message={t("Error occured while archiving task, please try again or contact us.")}
      />
    </>
  );
}
