import { gql } from "@apollo/client";

export const taskListDataWithoutChildrenFragment = gql`
  fragment TaskListDataWithoutChildren on Task {
    id
    friendlyId
    name
    status {
      id
      name
      type
    }
    location
    dueDate
    finishedDate
    assignees {
      id
      firstName
      lastName
      email
    }
  }
`;

export const tasksListFragment = gql`
  ${taskListDataWithoutChildrenFragment}
  fragment TasksList on Task {
    ...TaskListDataWithoutChildren
    children {
      ...TaskListDataWithoutChildren
    }
  }
`;

export const taskDetailsChildFragment = gql`
  fragment TaskDetailsChild on Task {
    id
    friendlyId
    name
    dueDate
    finishedDate
    status {
      id
      name
      type
    }
    assignees {
      id
      firstName
      lastName
    }
  }
`;

export const taskWithoutChildrenAndParentsFragment = gql`
  fragment TaskWithoutChildrenAndParents on Task {
    id
    friendlyId
    name
    description
    location
    status {
      id
      name
      type
    }
    reportedDate
    dueDate
    finishedDate
    isArchived
    categories {
      id
      name
    }
    labels {
      id
      name
    }
    createdBy {
      id
      firstName
      lastName
    }
    reporter {
      id
      firstName
      lastName
    }
    assignees {
      id
      email
      firstName
      lastName
    }
  }
`;

export const taskDetailsFragment = gql`
  ${taskDetailsChildFragment}
  ${taskWithoutChildrenAndParentsFragment}
  fragment TaskDetails on Task {
    ...TaskWithoutChildrenAndParents
    children {
      ...TaskDetailsChild
    }
    parent {
      id
      friendlyId
      isArchived
    }
  }
`;

export const taskAttachmentFragment = gql`
  fragment TaskAttachment on TaskAttachment {
    id
    attachment {
      id
      filename
      mimetype
      uri
      createdAt
      encoding
      updatedAt
    }
    createdAt
    updatedAt
  }
`;

export const updateCommentContentFragment = gql`
  fragment UpdateCommentContent on Comment {
    id
    content
    updatedAt
  }
`;
