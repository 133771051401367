import * as Types from '../../generated/types';

import { WorkplaceUserFragment } from './fragment.generated';
import { gql } from '@apollo/client';
import { WorkplaceUserFragmentDoc } from './fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetWorkplaceUsersQueryVariables = Types.Exact<{
  includeInactive?: Types.Maybe<Types.Scalars['Boolean']>;
  workplaceId: Types.Scalars['UUID'];
}>;


export type GetWorkplaceUsersQuery = { __typename?: 'Query', getWorkplaceUsers: Array<(
    { __typename?: 'User' }
    & WorkplaceUserFragment
  )> };


export const GetWorkplaceUsersDocument = gql`
    query GetWorkplaceUsers($includeInactive: Boolean, $workplaceId: UUID!) {
  getWorkplaceUsers(includeInactive: $includeInactive, workplaceId: $workplaceId) {
    ...WorkplaceUser
  }
}
    ${WorkplaceUserFragmentDoc}`;

/**
 * __useGetWorkplaceUsersQuery__
 *
 * To run a query within a React component, call `useGetWorkplaceUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkplaceUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkplaceUsersQuery({
 *   variables: {
 *      includeInactive: // value for 'includeInactive'
 *      workplaceId: // value for 'workplaceId'
 *   },
 * });
 */
export function useGetWorkplaceUsersQuery(baseOptions: Apollo.QueryHookOptions<GetWorkplaceUsersQuery, GetWorkplaceUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkplaceUsersQuery, GetWorkplaceUsersQueryVariables>(GetWorkplaceUsersDocument, options);
      }
export function useGetWorkplaceUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkplaceUsersQuery, GetWorkplaceUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkplaceUsersQuery, GetWorkplaceUsersQueryVariables>(GetWorkplaceUsersDocument, options);
        }
export type GetWorkplaceUsersQueryHookResult = ReturnType<typeof useGetWorkplaceUsersQuery>;
export type GetWorkplaceUsersLazyQueryHookResult = ReturnType<typeof useGetWorkplaceUsersLazyQuery>;
export type GetWorkplaceUsersQueryResult = Apollo.QueryResult<GetWorkplaceUsersQuery, GetWorkplaceUsersQueryVariables>;