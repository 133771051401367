import { yupResolver } from "@hookform/resolvers/yup";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { requiredStringValidator } from "../../../../../utils/formValidators";
import { CreateChildFormData } from "./CreateChildFormData";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import AddChildTaskDialogView from "./AddChildTaskDialogView";
import { useCreateTaskMutation } from "../../../graphql/mutation.generated";
import { GET_TASK_DETAILS } from "../../../graphql/query";
import { TaskDetailsFragment } from "../../../graphql/fragment.generated";
import { CreateTaskData } from "../../../../../generated/types";

interface Props {
  parentTask: TaskDetailsFragment;
  workplaceId: string;
  onChildTaskAdded: () => void;
  onClose: () => void;
}

const schema = (t: TFunction): yup.AnyObjectSchema =>
  yup.object().shape({
    name: requiredStringValidator(t),
  });

export default function AddChildTaskDialog({
  parentTask,
  workplaceId,
  onChildTaskAdded,
  onClose,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [addParentValues, setAddParentValues] = useState(true);

  const formMethods = useForm<CreateChildFormData>({
    resolver: yupResolver(schema(t)),
  });

  const [
    createTaskMutation,
    { error: createTaskError, loading: createTaskLoading },
  ] = useCreateTaskMutation();

  const onFormSubmit = async (formData: CreateChildFormData): Promise<void> => {
    let createTaskData: CreateTaskData = {
      name: formData.name,
      parentId: parentTask.id,
      workplaceId,
    };
    if (addParentValues) {
      createTaskData = {
        ...createTaskData,
        location: parentTask.location,
        assigneesIds: parentTask.assignees.map((a) => a.id),
        dueDate: parentTask.dueDate,
        reportedDate: parentTask.reportedDate,
      };
    }
    try {
      const res = await createTaskMutation({
        variables: {
          createTaskData,
        },
        // TODO check if this can be ommited
        refetchQueries: [
          {
            query: GET_TASK_DETAILS,
            variables: {
              id: parentTask.id,
            },
          },
        ],
      });

      if (res.data) {
        onChildTaskAdded();
      }
    } catch (e) {
      //
    }
  };
  return (
    <AddChildTaskDialogView
      formMethods={formMethods}
      onClose={onClose}
      onFormSubmit={onFormSubmit}
      addChildError={createTaskError}
      addChildLoading={createTaskLoading}
      addParentValuesChecked={addParentValues}
      changeAddParentValuesCheck={(x, checked) => setAddParentValues(checked)}
    />
  );
}
