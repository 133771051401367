import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Permissions, Workplace } from "../../generated/types";
import useLazyIsUserAuthorised from "../../utils/AuthUtils/useLazyIsUserAuthorised";
import theme, { black100Opacity } from "../../utils/theme";
import { MenuLink } from "./MenuLink";
import WorkplacePicker from "./WorkplacePicker";
import {
  Box,
  CircularProgress,
  makeStyles,
  SwipeableDrawer,
  Typography,
} from "@material-ui/core";

interface Props {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  workplaces?: Workplace[];
  loading?: boolean;
  workplaceChooseDisabled?: boolean;
  menuLinks: MenuLink[];
}

const useStyles = makeStyles({
  navLink: {
    display: "block",
  },
  navLinkActive: {
    "& > *": {
      color: `${theme.palette.primary.main} !important`,
      opacity: `1 !important`,
    },
  },
  menuItem: {
    color: black100Opacity,
    textTransform: "uppercase",
    fontWeight: 900,
    marginBottom: theme.spacing(2),
    opacity: 0.6,
    "&:hover": {
      opacity: 1,
      color: `${theme.palette.primary.light} !important`,
    },
  },
  menuSubItem: {
    marginBottom: theme.spacing(1),
    color: "#fff",
    opacity: 0.6,
    "&:hover": {
      opacity: 1,
      color: `${theme.palette.primary.light} !important`,
    },
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },
});

export default function MenuView({
  onClose,
  onOpen,
  open,
  workplaces,
  loading,
  workplaceChooseDisabled,
  menuLinks,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isUserAuthorised } = useLazyIsUserAuthorised();
  return (
    <SwipeableDrawer anchor="left" open={open} onClose={onClose} onOpen={onOpen}>
      <Box minWidth="200px" px={2} py={4}>
        {workplaces && workplaces.length && (
          <>
            <Box mb={4}>
              <WorkplacePicker
                workplaceChooseDisabled={workplaceChooseDisabled}
                workplaces={workplaces}
              />
            </Box>
            <Box>
              {menuLinks.map((link, linkIndex) => (
                <div key={`menuItem${linkIndex}`}>
                  {isUserAuthorised(link.permission || Permissions.ViewWorkplace) && (
                    <Box mb={2}>
                      <NavLink
                        to={link.url}
                        className={classes.navLink}
                        activeClassName={classes.navLinkActive}
                      >
                        <Typography variant="body1" className={classes.menuItem}>
                          {link.name}
                        </Typography>
                      </NavLink>
                      {/* {link.subMenu?.length && (
                          <Box pl={1}>
                            {link.subMenu.map((subLink, subMenuIndex) => {
                              if (
                                isUserAuthorised(
                                  subLink.permission || Permissions.ViewWorkplace
                                )
                              ) {
                                return (
                                  <NavLink
                                    key={`topMenuSubLink${subMenuIndex}`}
                                    to={subLink.url}
                                    className={classes.navLink}
                                    activeClassName={classes.navLinkActive}
                                  >
                                    <Typography
                                      variant="caption"
                                      className={classes.menuSubItem}
                                    >
                                      {subLink.name}
                                    </Typography>
                                  </NavLink>
                                );
                              }
                              return <></>;
                            })}
                          </Box>
                        )} */}
                    </Box>
                  )}
                </div>
              ))}
            </Box>
          </>
        )}
        {workplaces && !workplaces.length && (
          <Box py={5}>
            <Typography align="center">{t("No workplaces to display")}</Typography>
          </Box>
        )}
        {loading && (
          <Box py={5} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
      </Box>
    </SwipeableDrawer>
  );
}
