import React, { useState } from "react";
import { useUploadTaskAttachmentsMutation } from "../../graphql/mutation.generated";
import { GET_TASK_ATTACHMENTS } from "../../graphql/query";
import { GetTaskAttachmentsQuery } from "../../graphql/query.generated";
import ActionButtonsView from "./ActionButtonsView";

interface Props {
  taskId: string;
  onCommentsButtonClick: () => void;
  canUserEditAttachments: boolean;
}
export default function ActionButtons({
  taskId,
  onCommentsButtonClick,
  canUserEditAttachments,
}: Props): JSX.Element {
  const [addAttachmentSuccess, setAddAttachmentSuccess] = useState(false);

  const [
    updateTaskAttachmentsMutation,
    { loading: updateAttachmentsLoading, error: updateAttachmentsError },
  ] = useUploadTaskAttachmentsMutation({
    update: (cache, res) => {
      if (res.data?.uploadTaskAttachments) {
        const resUpload = res.data.uploadTaskAttachments;
        const taskAttachmentsQuery = cache.readQuery<GetTaskAttachmentsQuery>({
          query: GET_TASK_ATTACHMENTS,
          variables: { taskId },
        });
        if (taskAttachmentsQuery?.getTaskAttachments) {
          const newTaskAttachments = [
            ...taskAttachmentsQuery.getTaskAttachments,
            ...resUpload,
          ];
          const newTaskAttachmentsQuery: GetTaskAttachmentsQuery = {
            ...taskAttachmentsQuery,
            getTaskAttachments: newTaskAttachments,
          };

          cache.writeQuery({
            query: GET_TASK_ATTACHMENTS,
            variables: { taskId },
            data: newTaskAttachmentsQuery,
          });
        }
      }
    },
  });

  const onFileUpload = async (files: File[]): Promise<void> => {
    try {
      const res = await updateTaskAttachmentsMutation({
        variables: {
          files,
          taskId,
        },
      });
      if (res.data) {
        setAddAttachmentSuccess(true);
      }
    } catch (err) {
      //
    }
  };

  const onFileAdd = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { validity, files } = event.target;
    const filesToUpload = Object.values(files || {});
    if (validity.valid && filesToUpload.length) {
      onFileUpload(filesToUpload);
    }
  };

  return (
    <ActionButtonsView
      addAttachmentSuccess={addAttachmentSuccess}
      closeAttachmentAddSuccess={(): void => setAddAttachmentSuccess(false)}
      onFileAdd={onFileAdd}
      uploadFileError={updateAttachmentsError}
      loading={updateAttachmentsLoading}
      onMessagesOpen={onCommentsButtonClick}
      canUserEditAttachments={canUserEditAttachments}
    />
  );
}
