import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";
import { useTranslation } from "react-i18next";
import ListedDateTimePicker from "../../../../../components/ListedDateTimePicker/ListedDateTimePicker";
import { useUpdateTaskDueDateMutation } from "../../../graphql/mutation.generated";
import TaskDueDateView from "./TaskDueDateView";

interface Props {
  taskId: string;
  taskDueDate?: string;
  listedView?: boolean;
  disabled: boolean;
}
export default function TaskDueDate({
  taskId,
  taskDueDate,
  listedView,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [updateTaskDueDateMutation, { error, loading }] = useUpdateTaskDueDateMutation();

  const onDateChange = async (newDate?: MaterialUiPickersDate): Promise<void> => {
    try {
      await updateTaskDueDateMutation({
        variables: {
          updateTaskDueDateData: {
            id: taskId,
            dueDate: newDate?.toISOString() || null,
          },
        },
      });
    } catch (e) {
      //
    }
  };

  const onDateClear = () => {
    onDateChange(null);
  };
  if (listedView) {
    return (
      <ListedDateTimePicker
        onDateChange={onDateChange}
        disabled={disabled}
        error={error}
        loading={loading}
        onDateClear={onDateClear}
        date={taskDueDate}
        label={t("Due date")}
        placeholder={t("Pick due date")}
      />
    );
  }
  return (
    <TaskDueDateView
      onDateChange={onDateChange}
      disabled={disabled}
      dueDate={taskDueDate}
      error={error}
      loading={loading}
    />
  );
}
