import { Box, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useContext, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import FullScreenLoader from "../components/FullScreenLoader/FullScreenLoader";
import { WorkplaceContext } from "../context/WorkplaceContext/WorkplaceContextProvider";
import { PropsChild } from "../interfaces/PropsChild";
import { ACCOUNT_SETTINGS } from "../routing/paths";
import theme from "../utils/theme";
import Menu from "./menu/Menu";

interface Props extends PropsChild {
  loading?: boolean;
  workplaceChooseDisabled?: boolean;
  px?: number;
}

const useStyles = makeStyles({
  siteWrapper: {
    overflow: "hidden",
    maxHeight: "100vh",
  },
  topBar: {
    backgroundColor: theme.palette.primary.main,
    height: theme.spacing(6),
  },
  wpName: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  content: {
    height: `calc(100vh - ${theme.spacing(6)}px)`,
    overflow: "auto",
    boxSizing: "border-box",
  },
});

export default function BasicMenuLayout({
  children,
  loading,
  workplaceChooseDisabled,
  px,
}: Props): JSX.Element {
  const classes = useStyles();
  const { chosenWorkplaceId, workplaces, loading: workplaceLoading } = useContext(
    WorkplaceContext
  );

  const [menuOpenState, setMenuOpenState] = useState<boolean>(false);

  const workplaceName = useMemo(() => {
    if (workplaces && chosenWorkplaceId) {
      return workplaces?.find((w) => w.id === chosenWorkplaceId)?.name;
    }
    return "";
  }, [workplaces, chosenWorkplaceId]);

  return (
    <div className={classes.siteWrapper}>
      <Box
        className={classes.topBar}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={1.5}
      >
        <Box onClick={() => setMenuOpenState(true)}>
          <MenuIcon />
        </Box>
        <Box maxWidth="65%">
          {workplaceLoading && <CircularProgress />}
          {workplaceName && (
            <Typography variant="h6" className={classes.wpName}>
              {workplaceName}
            </Typography>
          )}
        </Box>
        <Box>
          <NavLink to={ACCOUNT_SETTINGS}>
            <AccountCircleIcon />
          </NavLink>
        </Box>
      </Box>
      <Box pt={1.5} px={px === undefined ? 1 : px} pb={2} className={classes.content}>
        {children}
      </Box>
      {loading && <FullScreenLoader open />}
      <Menu
        onClose={() => setMenuOpenState(false)}
        onOpen={() => setMenuOpenState(true)}
        open={menuOpenState}
        workplaceChooseDisabled={workplaceChooseDisabled}
      />
    </div>
  );
}
