import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { WorkplaceContext } from "../../../context/WorkplaceContext/WorkplaceContextProvider";
import useManageUrlQueryVariables from "../../../hooks/useManageUrlQueryVariables";
import { QueryVariablesEnum } from "../../../interfaces/QueryVariables";
import { PATROL, WORKPLACE } from "../../../routing/paths";
import { useCreatePatrolMutation } from "../graphql/mutation.generated";
import { useGetPatrolsLazyQuery } from "../graphql/query.generated";
import PatrolsListPageView from "./PatrolsListPageView";

const PATROLS_PAGE_LENGTH = 10;

export default function PatrolsListPage(): JSX.Element {
  const { chosenWorkplaceId, loading: workplaceContextLoading } = useContext(
    WorkplaceContext
  );
  const {
    setUrlQueryVariables,
    pageNumber: urlPageNumber,
    pageSize: urlPageSize,
  } = useManageUrlQueryVariables();
  const history = useHistory();

  const [pageSize, setPageSize] = useState<number>(PATROLS_PAGE_LENGTH);
  const [pageNumber, setPageNumber] = useState<number>(1);

  useEffect(() => {
    if (urlPageNumber) {
      setPageNumber(Number(urlPageNumber));
    }
    if (urlPageSize) {
      setPageSize(Number(urlPageSize));
    }
  }, [urlPageNumber, urlPageSize]);

  const [
    getPatrolsQuery,
    { data: patrolsData, loading: patrolsLoading, error: patrolsLoadingError },
  ] = useGetPatrolsLazyQuery({ fetchPolicy: "network-only" });

  const [
    createPatrolMutation,
    { loading: createPatrolLoading, error: createPatrolError },
  ] = useCreatePatrolMutation();

  useEffect(() => {
    if (chosenWorkplaceId) {
      getPatrolsQuery({
        variables: {
          paginationData: { limit: pageSize, offset: pageSize * (pageNumber - 1) },
          workplaceId: chosenWorkplaceId,
        },
      });
    }
  }, [chosenWorkplaceId, pageNumber, getPatrolsQuery, pageSize]);

  const pagesCount = useMemo<number>(() => {
    if (patrolsData?.getPatrols.totalCount) {
      return Math.ceil(patrolsData.getPatrols.totalCount / pageSize);
    }
    return 0;
  }, [pageSize, patrolsData]);

  const onPageSizeChange = (size: number): void => {
    setUrlQueryVariables(QueryVariablesEnum.PAGESIZE, `${size}`);
  };

  const onPageNumberChange = (page: number): void => {
    setUrlQueryVariables(QueryVariablesEnum.PAGENUMBER, String(page));
  };

  const addPatrol = async (): Promise<void> => {
    if (chosenWorkplaceId) {
      try {
        const res = await createPatrolMutation({
          variables: {
            createPatrolData: {
              startDate: moment().toISOString(),
              workplaceId: chosenWorkplaceId,
            },
          },
        });
        if (res.data) {
          history.push(
            `${WORKPLACE + PATROL}?${
              QueryVariablesEnum.WORKPLACEID
            }=${chosenWorkplaceId}&${QueryVariablesEnum.PATROLID}=${
              res.data.createPatrol.id
            }`
          );
        }
      } catch (e) {
        //
      }
    }
  };

  return (
    <PatrolsListPageView
      onPageNumberChange={onPageNumberChange}
      onPageSizeChange={onPageSizeChange}
      pageNumber={pageNumber}
      pageSize={pageSize}
      pagesCount={pagesCount}
      workplaceId={chosenWorkplaceId}
      loading={patrolsLoading || workplaceContextLoading || createPatrolLoading}
      patrols={patrolsData?.getPatrols.items}
      patrolsLoadingError={patrolsLoadingError}
      addPatrol={addPatrol}
      createPatrolError={createPatrolError}
    />
  );
}
