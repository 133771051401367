import { ApolloError } from "@apollo/client";
import { makeStyles, TextField } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../../components/ErrorDisplay/ErrorDisplay";
import theme from "../../../../../utils/theme";

interface Props {
  name: string;
  onNameBlur: () => void;
  changeNameState: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: ApolloError;
}

const useStyles = makeStyles({
  nameInplace: {
    "& .MuiInputBase-input": {
      ...theme.typography.h6,
    },
  },
});

export default function TaskNameView({
  changeNameState,
  error,
  name,
  onNameBlur,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <TextField
        className={classes.nameInplace}
        fullWidth
        multiline
        InputProps={{ disableUnderline: true }}
        value={name}
        placeholder={t("Task name must be filled!")}
        onBlur={onNameBlur}
        onChange={changeNameState}
        disabled={disabled}
      />
      <ErrorDisplay
        error={error}
        message={t("Error occured while updating task name, try again or contact us.")}
      />
    </>
  );
}
