export const DASHBOARD = "/dashboard";

export const SIGN_IN = "/sign-in";

export const SIGN_UP = "/sign-up";
export const SIGN_UP_FROM_LINK = "/sign-up/:token";
export const VERIFY_EMAIL = "/verify-email";
export const ACTIVATE_ACCOUNT = "/activate-account/:token";
export const SIGN_UP_COMPLETED = "/sign-up-completed";

export const RESET_PASSWORD = "/reset-password";
export const RESET_PASSWORD_EMAIL_SENT = "/reset-password-email-sent";
export const SET_NEW_PASSWORD = "/set-new-password/:token";
export const PASSWORD_CHANGED = "/password-changed";

export const ACCOUNT_SETTINGS = "/account-settings";

export const WORKPLACE = "/workplace";

export const TASKS = "/tasks";
export const TASK_DETAILS = "/task-details";

export const PATROLS_LIST = "/patrolslist";
export const PATROL = "/patrol";
