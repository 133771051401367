import { DateTimePicker, DateTimePickerProps } from "@material-ui/pickers";
import React from "react";
import { useTranslation } from "react-i18next";

export default function CustomDateTimePicker(props: DateTimePickerProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <DateTimePicker
      ampm={false}
      format="DD MMM, YYYY, HH:mm"
      cancelLabel={t("Cancel")}
      clearLabel={t("Clear")}
      okLabel={t("OK")}
      todayLabel={t("Today")}
      {...props}
    />
  );
}
