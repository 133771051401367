import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { WorkplaceContext } from "../../../context/WorkplaceContext/WorkplaceContextProvider";
import useGetWorkplaceDisplayData from "../../../hooks/useGetWorkplaceDisplayData";
import useManageUrlQueryVariables from "../../../hooks/useManageUrlQueryVariables";
import { QueryVariablesEnum } from "../../../interfaces/QueryVariables";
import { WORKPLACE, TASK_DETAILS } from "../../../routing/paths";
import { useGetTasksListLazyQuery } from "../graphql/query.generated";
import TasksListPageView from "./TasksListPageView";

export default function TasksListPage(): JSX.Element {
  const { taskFilters } = useManageUrlQueryVariables();
  const { chosenWorkplaceId, loading: workplaceContextLoading } = useContext(
    WorkplaceContext
  );
  const { currentWorkplaceShortName } = useGetWorkplaceDisplayData();
  const history = useHistory();

  const [addNewTaskMode, setAddNewTaskMode] = useState(false);

  const [
    getTasksListQuery,
    { data: tasksList, loading: tasksListLoading, error: tasksListError },
  ] = useGetTasksListLazyQuery();

  useEffect(() => {
    if (chosenWorkplaceId) {
      const parsedFilters = taskFilters ? JSON.parse(taskFilters) : null;
      getTasksListQuery({
        variables: { workplaceId: chosenWorkplaceId, filters: parsedFilters },
      });
    }
  }, [chosenWorkplaceId, getTasksListQuery, taskFilters]);

  const onNewTaskAddCancel = () => setAddNewTaskMode(false);

  const onNewTaskAdded = (taskId: string) => {
    if (chosenWorkplaceId) {
      history.push(
        `${WORKPLACE}${TASK_DETAILS}?${QueryVariablesEnum.WORKPLACEID}=${chosenWorkplaceId}&${QueryVariablesEnum.TASKID}=${taskId}`
      );
    }
  };

  return (
    <TasksListPageView
      tasksList={tasksList ? tasksList.getWorkplaceTasks : []}
      loading={tasksListLoading || workplaceContextLoading}
      tasksListError={tasksListError}
      workplaceShortName={currentWorkplaceShortName}
      workplaceId={chosenWorkplaceId}
      addNewTaskMode={addNewTaskMode}
      onTaskAddClick={() => setAddNewTaskMode(true)}
      onNewTaskAddCancel={onNewTaskAddCancel}
      onNewTaskAdded={onNewTaskAdded}
    />
  );
}
