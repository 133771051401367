import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";

interface Props {
  message: string;
  open: boolean;
  handleClose?: () => void;
  severity?: "error" | "success" | "warning" | "info";
  autoHideDuration?: number;
}

export default function CustomSnackbar({
  message,
  open,
  handleClose,
  severity,
  autoHideDuration,
}: Props): JSX.Element {
  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
      <MuiAlert onClose={handleClose} severity={severity || "error"} elevation={6}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
}
