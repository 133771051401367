import { ApolloError } from "@apollo/client";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import React from "react";
import { Controller, UseFormMethods } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Assignees from "../../../components/Assignees/Assignees";
import CustomDateTimePicker from "../../../components/CustomDateTimePicker/CustomDateTimePicker";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import FormTextFieldWrapper from "../../../components/FormTextFieldWrapper/FormTextFieldWrapper";
import { TaskFormData } from "./TaskFormData";

interface Props {
  formMethods: UseFormMethods<TaskFormData>;
  onFormSubmit: (formData: TaskFormData) => void;
  onCancel: () => void;
  createTaskLoading?: boolean;
  createTaskError?: ApolloError;

  usersNamesToDisplay: { name: string; optionId: string }[];
  usersToPick: { id: string }[];
  fetchingUsersError?: ApolloError;
}
export default function AddTaskView({
  formMethods: { control, handleSubmit, errors, register },
  onFormSubmit,
  onCancel,
  createTaskLoading,
  createTaskError,
  usersNamesToDisplay,
  usersToPick,
  fetchingUsersError,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Box px={1.5} py={2}>
      <Box mb={3}>
        <Typography variant="h6" align="center">
          {t("Add new task")}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <FormTextFieldWrapper>
          <TextField
            name="name"
            label={`${t("Task name")}*`}
            fullWidth
            variant="outlined"
            autoFocus
            inputRef={register}
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </FormTextFieldWrapper>
        <FormTextFieldWrapper>
          <TextField
            name="location"
            label={t("Location")}
            fullWidth
            variant="outlined"
            inputRef={register}
          />
        </FormTextFieldWrapper>
        <FormTextFieldWrapper>
          <TextField
            name="description"
            label={t("Description")}
            fullWidth
            variant="outlined"
            inputRef={register}
            multiline
            rows={3}
          />
        </FormTextFieldWrapper>

        <FormTextFieldWrapper>
          <Controller
            control={control}
            name="dueDate"
            render={({ value, onChange }): JSX.Element => (
              <CustomDateTimePicker
                label={t("Due date")}
                minutesStep={15}
                value={value}
                onChange={onChange}
                fullWidth
                inputVariant="outlined"
                clearable
                showTodayButton
              />
            )}
          />
        </FormTextFieldWrapper>

        <FormTextFieldWrapper>
          {usersToPick && (
            <Controller
              control={control}
              name="assigneesIds"
              render={({ value, onChange }): JSX.Element => (
                <Assignees
                  assignees={value}
                  onChange={onChange}
                  namesToDisplay={usersNamesToDisplay}
                  usersToPick={usersToPick}
                  fetchingUsersError={fetchingUsersError}
                />
              )}
            />
          )}
        </FormTextFieldWrapper>
        <Box display="flex" justifyContent="space-between" pt={2}>
          <Button onClick={onCancel} variant="outlined">
            {t("Cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={createTaskLoading}
          >
            {t("Add Task")}
          </Button>
        </Box>
      </form>
      <ErrorDisplay
        error={createTaskError}
        message={t("Error occured while creating task, please try again or contact us.")}
        codes={{
          BAD_USER_INPUT: t(
            "Provided data is invalid. Check if provided dates are not colliding with each other."
          ),
        }}
      />
    </Box>
  );
}
