import { ApolloError } from "@apollo/client";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import React from "react";
import { UseFormMethods } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import FormTextFieldWrapper from "../../../components/FormTextFieldWrapper/FormTextFieldWrapper";
import IdentityLayout from "../common/IdentityLayout";
import { SignInFormData } from "./SignInFormData";

interface Props {
  formMethods: UseFormMethods<SignInFormData>;
  loading?: boolean;
  error?: ApolloError;
  onFormSubmit: (formData: SignInFormData) => void;
}

export default function SignInPageView({
  loading,
  formMethods,
  onFormSubmit,
  error,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { handleSubmit, errors, register } = formMethods;
  return (
    <IdentityLayout loading={loading}>
      <Box pb={3}>
        <Typography variant="h6">{t("Sign in")}</Typography>
      </Box>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <FormTextFieldWrapper>
          <TextField
            name="email"
            label={t("Email")}
            fullWidth
            autoFocus
            variant="outlined"
            inputRef={register}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </FormTextFieldWrapper>
        <Box pb={5}>
          <TextField
            name="password"
            label={t("Password")}
            type="password"
            fullWidth
            variant="outlined"
            inputRef={register}
            error={!!errors.password}
            helperText={errors.password?.message}
          />
        </Box>
        <Box pb={2}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            fullWidth
            size="large"
          >
            {t("Sign in")}
          </Button>
        </Box>
      </form>
      <ErrorDisplay error={error} message={error?.message} />
    </IdentityLayout>
  );
}
