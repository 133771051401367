import { ApolloError } from "@apollo/client";
import AddIcon from "@material-ui/icons/Add";
import { Pagination } from "@material-ui/lab";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import FullScreenLoader from "../../../components/FullScreenLoader/FullScreenLoader";
import { Permissions } from "../../../generated/types";
import { QueryVariablesEnum } from "../../../interfaces/QueryVariables";
import BasicMenuLayout from "../../../layouts/BasicMenuLayout";
import { PATROL, WORKPLACE } from "../../../routing/paths";
import IsUserAuthorised from "../../../utils/AuthUtils/IsUserAuthorised";
import { SHORT_DATE_FORMAT_WITH_HOUR } from "../../../utils/consts";
import theme from "../../../utils/theme";
import { ListPatrolFragment } from "../graphql/fragments.generated";
import {
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";

interface Props {
  loading?: boolean;
  workplaceId: string;
  patrols?: ListPatrolFragment[];
  pageSize: number;
  onPageSizeChange: (newSize: number) => void;
  onPageNumberChange: (newNumber: number) => void;
  pagesCount: number;
  pageNumber: number;
  patrolsLoadingError?: ApolloError;
  addPatrol: () => void;
  createPatrolError?: ApolloError;
}

const useStyles = makeStyles({
  tableHead: {
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.secondary.main,
  },
  content: {
    maxHeight: `calc(100% - ${theme.spacing(12)}px)`,
    height: `calc(100% - ${theme.spacing(12)}px)`,
  },
  patrolItem: {
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.secondary.light,
  },
  patrolDate: {
    width: "40%",
  },
  patrolDetails: {
    width: "20%",
  },
});

export default function PatrolsListPageView({
  loading,
  workplaceId,
  patrols,
  onPageSizeChange,
  onPageNumberChange,
  pageNumber,
  pageSize,
  pagesCount,
  patrolsLoadingError,
  createPatrolError,
  addPatrol,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <BasicMenuLayout>
      <FullScreenLoader open={loading} />
      <Box display="flex" alignItems="center" mb={2}>
        <IsUserAuthorised
          checkedWorkplaceId={workplaceId}
          permission={Permissions.CreatePatrol}
        >
          <Box>
            <Button
              color="primary"
              variant="contained"
              onClick={(): void => addPatrol()}
              size="small"
            >
              <AddIcon fontSize="small" />
              &nbsp;
              {t("Add new patrol")}
            </Button>
          </Box>
        </IsUserAuthorised>
      </Box>
      <Box display="flex" px={1} pb={1.5} className={classes.tableHead}>
        <Box className={classes.patrolDate}>
          <Typography variant="caption">{t("Start date")}</Typography>
        </Box>
        <Box className={classes.patrolDate}>
          <Typography variant="caption">{t("Finish date")}</Typography>
        </Box>
      </Box>
      {patrols && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          className={classes.content}
        >
          <Box>
            {patrols.map((patrol) => (
              <Box
                key={`patrollistitem${patrol.id}`}
                display="flex"
                px={1}
                pt={1.5}
                pb={0.7}
                className={classes.patrolItem}
                alignItems="center"
              >
                <Box className={classes.patrolDate}>
                  <Typography variant="body2">
                    {moment(patrol.startDate).format(SHORT_DATE_FORMAT_WITH_HOUR)}
                  </Typography>
                </Box>
                <Box className={classes.patrolDate}>
                  {patrol.endDate ? (
                    <Typography variant="body2">
                      {moment(patrol.endDate).format(SHORT_DATE_FORMAT_WITH_HOUR)}
                    </Typography>
                  ) : (
                    <Typography variant="body2">{t("No finish date")}</Typography>
                  )}
                </Box>

                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className={classes.patrolDetails}
                >
                  <NavLink
                    to={
                      workplaceId
                        ? `${WORKPLACE + PATROL}?${
                            QueryVariablesEnum.WORKPLACEID
                          }=${workplaceId}&${QueryVariablesEnum.PATROLID}=${patrol.id}`
                        : ""
                    }
                  >
                    <Button variant="contained" size="small" color="primary">
                      {t("Details")}
                    </Button>
                  </NavLink>
                </Box>
              </Box>
            ))}
          </Box>
          <Box display="flex" py={1} justifyContent="space-between">
            <Box width="80px">
              <TextField
                select
                fullWidth
                variant="outlined"
                label={t("Page size")}
                value={pageSize}
                size="small"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onPageSizeChange(Number(e.target.value))
                }
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </TextField>
            </Box>
            <Pagination
              size="small"
              count={pagesCount}
              color="primary"
              page={pageNumber}
              boundaryCount={1}
              siblingCount={0}
              onChange={(e, page) => onPageNumberChange(page)}
            />
          </Box>
        </Box>
      )}
      <ErrorDisplay
        error={patrolsLoadingError}
        message={t(
          "Error occured while getting patrols, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={createPatrolError}
        message={t(
          "Error occured while creating new patrol, please try again or contact us."
        )}
      />
    </BasicMenuLayout>
  );
}
