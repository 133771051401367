import { makeStyles, Typography } from "@material-ui/core";
import LabelIcon from "@material-ui/icons/Label";
import React from "react";
import theme, { blueColor, successColor } from "../../../utils/theme";

interface Props {
  friendlyId: number;
  isChild?: boolean;
  isArchived?: boolean;
  isParentArchived?: boolean;
  workplaceShortName?: string;
}

const useStyles = makeStyles({
  childFriendlyId: {
    color: successColor,
  },
  taskFriendly: {
    color: blueColor,
  },
  archived: {
    color: theme.palette.secondary.main,
  },
});

export default function TaskFriendlyId({
  friendlyId,
  isChild,
  isArchived,
  isParentArchived,
  workplaceShortName,
}: Props): JSX.Element {
  const classes = useStyles();

  const getLabelIconClass = (): string => {
    if (isArchived || isParentArchived) {
      return classes.archived;
    }
    if (isChild) {
      return classes.childFriendlyId;
    }
    return classes.taskFriendly;
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ display: "flex" }}>
        <LabelIcon fontSize="small" className={getLabelIconClass()} />
        &nbsp;
        <Typography variant="body2">
          {workplaceShortName && `${workplaceShortName}-`}
          {friendlyId}
        </Typography>
      </div>
    </div>
  );
}
