import React, { useMemo } from "react";
import Assignees from "../../../../../components/Assignees/Assignees";
import { User } from "../../../../../generated/types";
import useGetWorkplaceUsers from "../../../../../hooks/useGetWorkplaceUsers";
import generateUserNameString from "../../../../../utils/generateUserNameString";
import { useChangeTaskAssigneesMutation } from "../../../graphql/mutation.generated";

interface Props {
  assignees: Partial<User>[];
  taskId: string;
  disabled: boolean;
}
export default function TaskAssignees({
  assignees,
  taskId,
  disabled,
}: Props): JSX.Element {
  const {
    error: usersFetchingError,
    loading: loadingUsers,
    workplaceUsers,
  } = useGetWorkplaceUsers();

  const [
    changeTaskAssigneesMutation,
    { error: changeAssigneesError, loading: changeAssigneesLoading },
  ] = useChangeTaskAssigneesMutation();

  const onAssigneesChange = async (value: { id: string }[]): Promise<void> => {
    const assigneesIds = value.map((v) => v.id);
    try {
      await changeTaskAssigneesMutation({
        variables: {
          changeTaskAssigneesData: {
            id: taskId,
            assigneesIds,
          },
        },
      });
    } catch (e) {
      //
    }
  };

  const assigneesIds = useMemo(() => {
    return assignees.map((a) => ({ id: a.id }));
  }, [assignees]);

  const namesToDisplay = useMemo(() => {
    return (
      workplaceUsers?.map((u) => ({ optionId: u.id, name: generateUserNameString(u) })) ||
      []
    );
  }, [workplaceUsers]);

  const usersToPick = useMemo(() => {
    return workplaceUsers?.map((u) => ({ id: u.id })) || [];
  }, [workplaceUsers]);

  return (
    <Assignees
      assignees={assigneesIds}
      namesToDisplay={namesToDisplay}
      onChange={onAssigneesChange}
      usersToPick={usersToPick}
      changeAssigneesError={changeAssigneesError}
      fetchingUsersError={usersFetchingError}
      loading={loadingUsers || changeAssigneesLoading}
      disabled={disabled}
    />
  );
}
