import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import TaskStatusesDisplay from "../../../../components/TaskStatusesDisplay/TaskStatusesDisplay";
import theme from "../../../../utils/theme";
import TaskFriendlyId from "../../common/TaskFriendlyId";
import {
  TaskDetailsChildFragment,
  TaskDetailsFragment,
} from "../../graphql/fragment.generated";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { useTranslation } from "react-i18next";
import AddChildTaskDialog from "./AddChildTaskDialog/AddChildTaskDialog";

interface Props {
  childTasks?: TaskDetailsChildFragment[];
  onChildClick: (childId: string) => void;
  workplaceShortName: string;
  onChildAddModeChange: (open: boolean) => void;
  childAddMode: boolean;
  parentTask: TaskDetailsFragment;
  workplaceId?: string;
  canUserEditTask: boolean;
}
export default function ChildTasksView({
  childTasks,
  onChildClick,
  workplaceShortName,
  onChildAddModeChange,
  childAddMode,
  parentTask,
  workplaceId,
  canUserEditTask,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
          <Box display="flex" alignItems="center">
            <ListAltIcon color="secondary" />
            <Box ml={0.5} mr={1.5}>
              <Typography variant="h6">{t("Child tasks")}</Typography>
            </Box>
          </Box>
          <Box>
            {canUserEditTask && (
              <Button size="small" onClick={(): void => onChildAddModeChange(true)}>
                + {t("Add child")}
              </Button>
            )}
          </Box>
        </Box>
        {childTasks && Boolean(childTasks.length) && (
          <Box mb={1}>
            {childTasks.map((childData) => (
              <Box
                key={`taskChildren${childData.id}`}
                border={`1px solid ${theme.palette.divider}`}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                py={1.3}
                px={0.8}
              >
                <Box
                  width="75%"
                  display="flex"
                  style={{ cursor: "pointer" }}
                  onClick={(): void => onChildClick(childData.id)}
                >
                  <Box mr={1}>
                    <TaskFriendlyId
                      friendlyId={childData.friendlyId}
                      isChild
                      workplaceShortName={workplaceShortName}
                    />
                  </Box>
                  <Typography variant="body2">{childData.name}</Typography>
                </Box>
                <Box width="25%" display="flex" justifyContent="flex-end">
                  <TaskStatusesDisplay
                    statusName={childData.status.name}
                    statusType={childData.status.type}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        )}
        {!childTasks?.length && (
          <Box my={1}>
            <Typography variant="caption">
              {t("Task has not any child issues")}
            </Typography>
          </Box>
        )}
      </div>
      {childAddMode && workplaceId && (
        <AddChildTaskDialog
          parentTask={parentTask}
          onChildTaskAdded={(): void => onChildAddModeChange(false)}
          onClose={(): void => onChildAddModeChange(false)}
          workplaceId={workplaceId}
        />
      )}
    </>
  );
}
