import { ApolloError } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomSnackbar from "../CustomSnackbar/CustomSnackbar";

interface Props {
  error?: ApolloError;
  message?: string;
  codes?: CodesMessages;
}

interface CodesMessages {
  BAD_USER_INPUT: string;
}

function generateErrorMessageContent(
  errors: ApolloError | undefined,
  defaultMessage: string,
  codesMessages?: CodesMessages
): string {
  if (errors && errors.graphQLErrors.length) {
    const myError = errors.graphQLErrors[0];
    if (myError?.extensions) {
      const { code, transactionId } = myError.extensions;
      if (code && codesMessages && codesMessages[code]) {
        return `${codesMessages[code]} (ID: ${transactionId || ""})`;
      }
      return `${defaultMessage} (ID: ${transactionId || ""})`;
    }
  }
  return defaultMessage || "";
}

export default function ErrorDisplay({ error, message, codes }: Props): JSX.Element {
  const { t } = useTranslation();

  const [displayError, setDisplayError] = useState<ApolloError | null>(null);

  useEffect(() => {
    if (error) {
      setDisplayError(error);
    }
  }, [error]);

  const defaultMessage: string =
    message || t("Error occured, please try again or contact us.");

  if (displayError) {
    return (
      <CustomSnackbar
        open={Boolean(displayError)}
        message={generateErrorMessageContent(displayError, defaultMessage, codes)}
        handleClose={(): void => setDisplayError(null)}
      />
    );
  }
  return <div />;
}
