import moment from "moment";
import React, { useContext, useMemo } from "react";
import { UserContext } from "../../../context/UserContext/UserContextProvider";
import { Permissions } from "../../../generated/types";
import useManageUrlQueryVariables from "../../../hooks/useManageUrlQueryVariables";
import useLazyIsUserAuthorised from "../../../utils/AuthUtils/useLazyIsUserAuthorised";
import { GET_PATROL } from "../graphql/query";
import { GetPatrolQuery, useGetPatrolQuery } from "../graphql/query.generated";
import PatrolPageView from "./PatrolPageView";
import {
  useCreatePatrolEventMutation,
  useUpdatePatrolEndDateMutation,
} from "../graphql/mutation.generated";

export default function PatrolPage(): JSX.Element {
  const { id: userId } = useContext(UserContext);
  const { patrolId, workplaceId } = useManageUrlQueryVariables();
  const { isUserAuthorised } = useLazyIsUserAuthorised();

  const {
    data: patrolData,
    loading: loadingPatrolData,
    error: patrolDataLoadingError,
  } = useGetPatrolQuery({ variables: { id: patrolId } });

  const [
    createPatrolEventMutation,
    { loading: createEventLoading, error: createEventError },
  ] = useCreatePatrolEventMutation();

  const [
    updateEndDateMutation,
    { error: finishPatrolError, loading: finishPatrolLoading },
  ] = useUpdatePatrolEndDateMutation();

  // TODO create helper that checks permissions and permissions_own
  const canUserEdit = useMemo<boolean>((): boolean => {
    if (isUserAuthorised(Permissions.EditPatrol, workplaceId)) {
      return true;
    }
    if (patrolData?.getPatrol && userId) {
      const creatorId = patrolData.getPatrol.createdBy?.id;
      const isUserCreator = creatorId === userId;
      const isUserAssigned = patrolData.getPatrol.assignees?.find((a) => a.id === userId);
      if (
        (isUserCreator || isUserAssigned) &&
        isUserAuthorised(Permissions.EditPatrolOwn, workplaceId)
      ) {
        return true;
      }
    }
    return false;
  }, [isUserAuthorised, patrolData, userId, workplaceId]);

  const addPatrolEvent = async (): Promise<void> => {
    try {
      await createPatrolEventMutation({
        variables: {
          createPatrolEventData: {
            patrolId,
            eventDate: moment().toISOString(),
          },
        },
        update: (cache, response) => {
          if (response.data?.createPatrolEvent) {
            const cachedPatrol = cache.readQuery<GetPatrolQuery>({
              query: GET_PATROL,
              variables: { id: patrolId },
            });
            if (cachedPatrol) {
              const createdPatrolEvent = response.data.createPatrolEvent;

              let newPatrolEvents = cachedPatrol.getPatrolEvents?.length
                ? [...cachedPatrol.getPatrolEvents, createdPatrolEvent]
                : [createdPatrolEvent];

              if (newPatrolEvents.length > 1) {
                newPatrolEvents = newPatrolEvents.sort((a, b) => {
                  if (moment(a.eventDate).isBefore(moment(b.eventDate))) {
                    return -1;
                  }
                  return 1;
                });
              }

              const newPatrolQuery: GetPatrolQuery = {
                ...cachedPatrol,
                getPatrolEvents: newPatrolEvents,
              };
              cache.writeQuery<GetPatrolQuery>({
                query: GET_PATROL,
                variables: { id: patrolId },
                data: newPatrolQuery,
              });
            }
          }
        },
      });
    } catch (e) {
      //
    }
  };

  const onPatrolFinish = () => {
    onFinishDateChange();
  };

  const onFinishDateChange = async (): Promise<void> => {
    const newValue = moment().toISOString();
    try {
      await updateEndDateMutation({
        variables: { updatePatrolEndDateData: { id: patrolId, endDate: newValue } },
      });
    } catch (e) {
      //
    }
  };

  return (
    <PatrolPageView
      loading={loadingPatrolData || createEventLoading || finishPatrolLoading}
      onPatrolEventAdd={addPatrolEvent}
      patrolEvents={patrolData?.getPatrolEvents || []}
      patrolData={patrolData?.getPatrol}
      patrolDataError={patrolDataLoadingError}
      canUserEdit={canUserEdit}
      isPatrolFinished={Boolean(patrolData?.getPatrol.endDate)}
      onPatrolFinish={onPatrolFinish}
      createEventError={createEventError}
      finishPatrolError={finishPatrolError}
    />
  );
}
