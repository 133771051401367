import { Box, CircularProgress, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Attachment } from "../../generated/types";
import SingleAttachment from "./SingleAttachment";

interface AttachmentWithAddings extends Attachment {
  attachmentWrapperId: string; // for example TaskAttachment ID
}
interface Props {
  attachments: AttachmentWithAddings[];
  onAttachmentDelete: (attachmentId: string) => Promise<void>;
  fetchingLoading?: boolean;
  canUserEdit?: boolean;
}

export default function Attachments({
  attachments,
  onAttachmentDelete,
  fetchingLoading,
  canUserEdit,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Box>
        {Boolean(attachments.length) &&
          attachments.map((attachment) => (
            <Box key={`attachment${attachment.id}`} mb={0.5}>
              <SingleAttachment
                fileName={attachment.filename}
                mimetype={attachment.mimetype}
                uri={attachment.uri}
                onDelete={(): Promise<void> =>
                  onAttachmentDelete(attachment.attachmentWrapperId)
                }
                canUserEdit={canUserEdit}
              />
            </Box>
          ))}
        {Boolean(!attachments.length) && (
          <Typography align="center" variant="caption">
            {t("No attachments added")}
          </Typography>
        )}
      </Box>
      {fetchingLoading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress size={30} />
        </Box>
      )}
    </>
  );
}
