import { Box, makeStyles, Typography } from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import { TaskStatusType } from "../../generated/types";
import theme, {
  blueColor,
  blueColorBackground,
  successBackground,
  successColor,
} from "../../utils/theme";

interface Props {
  statusName: string;
  statusType: TaskStatusType;
  size?: "small" | "medium";
}

const useStyles = makeStyles({
  status: { borderRadius: theme.shape.borderRadius },
  TODO: {
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary.light,
  },
  IN_PROGRESS: {
    color: blueColor,
    backgroundColor: blueColorBackground,
  },
  DONE: {
    color: successColor,
    backgroundColor: successBackground,
  },
});

export default function TaskStatusesDisplay({
  statusName,
  statusType,
  size = "small",
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classnames(classes[statusType], classes.status)} px={0.5}>
      <Typography variant={size === "small" ? "caption" : "body1"}>
        {statusName}
      </Typography>
    </Box>
  );
}
