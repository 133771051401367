import { Box, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import UserIcon from "../../../../../components/UserIcon/UserIcon";
import { User } from "../../../../../generated/types";
import { SHORT_DATE_FORMAT_WITH_HOUR } from "../../../../../utils/consts";
import generateUserNameString from "../../../../../utils/generateUserNameString";
import theme from "../../../../../utils/theme";

interface Props {
  content: string;
  author?: User;
  createdAt: string;
  updatedAt: string;
}

const useStyles = makeStyles({
  commentWrapper: {
    minWidth: "60%",
    maxWidth: "80%",
  },
  commentValue: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
  },
  captions: {
    fontSize: "8px",
    fontWeight: 500,
  },
});

export default function SingleComment({
  content,
  author,
  createdAt,
  updatedAt,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box display="flex">
      <Box className={classes.commentWrapper}>
        {author?.firstName && author.lastName && (
          <Box display="flex" alignItems="center" mb={0.5}>
            <Box mr={0.5}>
              <UserIcon name={generateUserNameString(author)} size="small" />
            </Box>
            <Typography variant="caption" color="secondary" className={classes.captions}>
              {author.firstName} {author.lastName} {t("wrote")}:
            </Typography>
          </Box>
        )}

        <Box className={classes.commentValue} py={1} px={2}>
          <Typography variant="body2">{content}</Typography>
        </Box>
        <Box display="flex" mt={0}>
          <Box mr={2}>
            <Typography variant="caption" color="secondary" className={classes.captions}>
              {t("Created at")} {moment(createdAt).format(SHORT_DATE_FORMAT_WITH_HOUR)}
            </Typography>
          </Box>
          {updatedAt && (
            <Box>
              <Typography
                variant="caption"
                color="secondary"
                className={classes.captions}
              >
                {t("Updated at")} {moment(createdAt).format(SHORT_DATE_FORMAT_WITH_HOUR)}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
