import { useContext } from "react";
import { UserContext } from "../../context/UserContext/UserContextProvider";
import { WorkplaceContext } from "../../context/WorkplaceContext/WorkplaceContextProvider";
import { Permissions } from "../../generated/types";
import useManageUrlQueryVariables from "../../hooks/useManageUrlQueryVariables";

interface ReturnObject {
  isUserAuthorised: (
    permission: Permissions[] | Permissions,
    checkedWorkplaceId?: string
  ) => boolean;
}

export default function useLazyIsUserAuthorised(): ReturnObject {
  const { permissions: userPermissions } = useContext(UserContext);
  const { chosenWorkplaceId } = useContext(WorkplaceContext);
  const { workplaceId: urlWorkplaceId } = useManageUrlQueryVariables();

  const isUserAuthorised = (
    permission: Permissions | Permissions[],
    checkedWorkplaceId?: string
  ): boolean => {
    const preparedCheckedPermissions = Array.isArray(permission)
      ? permission
      : [permission];
    let isAuth = false;

    for (let i = 0; i < preparedCheckedPermissions.length; i += 1) {
      if (userPermissions?.systemPermissions.length) {
        if (userPermissions.systemPermissions.includes(preparedCheckedPermissions[i])) {
          isAuth = true;
          break;
        }
      }

      if (userPermissions?.companyPermissions.length) {
        if (userPermissions.companyPermissions.includes(preparedCheckedPermissions[i])) {
          isAuth = true;
          break;
        }
      }

      if (userPermissions && Object.keys(userPermissions.workplacePermissions).length) {
        if (
          checkedWorkplaceId &&
          userPermissions.workplacePermissions[checkedWorkplaceId]?.includes(
            preparedCheckedPermissions[i]
          )
        ) {
          isAuth = true;
          break;
        }
        if (
          chosenWorkplaceId &&
          userPermissions.workplacePermissions[chosenWorkplaceId]?.includes(
            preparedCheckedPermissions[i]
          )
        ) {
          isAuth = true;
          break;
        }
        if (
          urlWorkplaceId &&
          userPermissions.workplacePermissions[urlWorkplaceId]?.includes(
            preparedCheckedPermissions[i]
          )
        ) {
          isAuth = true;
          break;
        }
      }
    }

    return isAuth;
  };

  return { isUserAuthorised };
}
