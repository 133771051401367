import { Box } from "@material-ui/core";
import React from "react";

import FullScreenLoader from "../../../components/FullScreenLoader/FullScreenLoader";
import Logo from "../../../components/Logo/Logo";
import { PropsChild } from "../../../interfaces/PropsChild";

interface Props extends PropsChild {
  loading?: boolean;
}

export default function IdentityLayout({ children, loading }: Props): JSX.Element {
  return (
    <Box boxSizing="border-box" px={3} pt={4} pb={2}>
      <Box display="flex" justifyContent="center" pb={5}>
        <Logo />
      </Box>
      {children}

      {loading && <FullScreenLoader open />}
    </Box>
  );
}
