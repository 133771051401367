import React, { useContext, useMemo } from "react";
import { UserContext } from "../../../../context/UserContext/UserContextProvider";
import { useGetTaskCommentsQuery } from "../../graphql/query.generated";
import TaskCommentsView from "./TaskCommentsView";

interface Props {
  taskId: string;
  onCommentsClose: () => void;
}
export default function TaskComments({ taskId, onCommentsClose }: Props): JSX.Element {
  const { id: userId } = useContext(UserContext);
  const {
    data: commentsData,
    error: commentsLoadingError,
    loading: commentsLoading,
  } = useGetTaskCommentsQuery({ variables: { taskId }, fetchPolicy: "network-only" });

  const comments = useMemo(() => {
    if (commentsData?.getTaskComments) {
      return commentsData.getTaskComments;
    }
    return [];
  }, [commentsData?.getTaskComments]);

  return (
    <TaskCommentsView
      comments={comments}
      taskId={taskId}
      userId={userId || undefined}
      commentsLoading={commentsLoading}
      commentsLoadingError={commentsLoadingError}
      notDeletableComments={false}
      onCommentsClose={onCommentsClose}
    />
  );
}
