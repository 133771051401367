import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import DueDateDisplay from "../../../../components/DueDateDisplay/DueDateDisplay";
import FinishDateDisplay from "../../../../components/FinishDateDisplay/FinishDateDisplay";
import { TaskStatusType } from "../../../../generated/types";
import theme from "../../../../utils/theme";
import TaskFriendlyId from "../../common/TaskFriendlyId";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import UserIcon from "../../../../components/UserIcon/UserIcon";
import TaskStatusesDisplay from "../../../../components/TaskStatusesDisplay/TaskStatusesDisplay";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

interface Props {
  name: string;
  friendlyId: number;
  dueDate?: string;
  finishedDate?: string;
  location?: string;
  assignee?: { firstName: string; lastName: string };
  statusName: string;
  statusType: TaskStatusType;
  workplaceShortName?: string;
  onChildrenViewChange?: () => void;
  onClick: () => void;
  childrenDisplayed?: boolean;
  isChild?: boolean;
}

const useStyles = makeStyles({
  extendArrowWrapper: {
    backgroundColor: "#fff",
    zIndex: 10,
  },
});

export default function SingleTaskView({
  friendlyId,
  name,
  statusName,
  statusType,
  assignee,
  dueDate,
  finishedDate,
  location,
  workplaceShortName,
  onClick,
  onChildrenViewChange,
  childrenDisplayed,
  isChild,
}: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Box
      borderBottom={`1px solid ${theme.palette.divider}`}
      position="relative"
      pb={isChild ? 0.5 : 1}
      mb={isChild ? 0.75 : 2}
      px={isChild ? 1 : 0}
    >
      <Box onClick={onClick} display="flex" alignItems="center" mb={0.5}>
        <Box width="20%" mr={1}>
          <TaskFriendlyId
            friendlyId={friendlyId}
            workplaceShortName={workplaceShortName}
            isChild={isChild}
          />
        </Box>
        <Typography>{name}</Typography>
      </Box>
      <Box
        onClick={onClick}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        {finishedDate && (
          <Box width="25%">
            <FinishDateDisplay finishDate={finishedDate} />
          </Box>
        )}
        {!finishedDate && dueDate && (
          <Box width="25%">
            <DueDateDisplay date={dueDate} />
          </Box>
        )}
        {location && (
          <Box width="35%" display="flex" alignItems="center">
            <Box mr={0.5}>
              <LocationOnIcon color="secondary" fontSize="small" />
            </Box>
            <Typography variant="body2" color="secondary">
              {location}
            </Typography>
          </Box>
        )}
        {assignee && (
          <Box width="15%">
            <UserIcon
              size="small"
              firstName={assignee.firstName || ""}
              lastName={assignee.lastName || ""}
            />
          </Box>
        )}
        <TaskStatusesDisplay
          statusName={statusName}
          statusType={statusType}
          size="small"
        />
      </Box>
      {onChildrenViewChange && !isChild && (
        <Box
          position="absolute"
          bottom="0"
          width="100%"
          display="flex"
          justifyContent="center"
          style={{ transform: "translateY(50%)" }}
        >
          <Box
            px={1}
            className={classes.extendArrowWrapper}
            onClick={(): void => onChildrenViewChange()}
          >
            {childrenDisplayed ? (
              <IconButton size="small">
                <ExpandLessIcon />
              </IconButton>
            ) : (
              <IconButton size="small">
                <ExpandMoreIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
