import { Box } from "@material-ui/core";
import React from "react";
import PatrolAssignees from "./infoFields/assignees/PatrolAssignees";
import PatrolDescription from "./infoFields/description/PatrolDescription";
import PatrolFinishDate from "./infoFields/finishDate/PatrolFinishDate";
import PatrolLocation from "./infoFields/location/PatrolLocation";
import PatrolStartDate from "./infoFields/startDate/PatrolStartDate";

interface Props {
  startDate: string;
  endDate?: string;
  assignees?: string[];
  description?: string;
  location?: string;
  patrolId: string;
  disabled?: boolean;
}
export default function PatrolInfoView({
  assignees,
  description,
  endDate,
  location,
  startDate,
  patrolId,
  disabled,
}: Props): JSX.Element {
  return (
    <div>
      <Box mb={1}>
        <PatrolStartDate
          patrolId={patrolId}
          patrolStartDate={startDate}
          disabled={disabled}
        />
      </Box>
      <Box mb={1}>
        <PatrolFinishDate
          patrolId={patrolId}
          patrolFinishDate={endDate}
          disabled={disabled}
        />
      </Box>
      <Box mb={1}>
        <PatrolAssignees
          assigneesIds={assignees || []}
          patrolId={patrolId}
          disabled={disabled}
        />
      </Box>
      <Box mb={1}>
        <PatrolLocation patrolId={patrolId} location={location} disabled={disabled} />
      </Box>
      <Box mb={1}>
        <PatrolDescription
          patrolId={patrolId}
          description={description}
          disabled={disabled}
        />
      </Box>
    </div>
  );
}
