import React, { useContext, useEffect } from "react";
import { WorkplaceContext } from "../../../../../context/WorkplaceContext/WorkplaceContextProvider";
import { useChangeTaskStatusMutation } from "../../../graphql/mutation.generated";
import { useGetWorkplaceTaskStatusesLazyQuery } from "../../../graphql/query.generated";
import TaskStatusPickerView from "./TaskStatusPickerView";

interface Props {
  taskId: string;
  statusId: string;
  disabled: boolean;
}
export default function TaskStatusPicker({
  taskId,
  statusId,
  disabled,
}: Props): JSX.Element {
  const { chosenWorkplaceId } = useContext(WorkplaceContext);

  const [
    getWorkplaceTaskStatuses,
    { data: statusesData, error: statusesFetchingError },
  ] = useGetWorkplaceTaskStatusesLazyQuery();

  const [
    changeTaskStatusMutation,
    { error: changeStatusError },
  ] = useChangeTaskStatusMutation();

  useEffect(() => {
    if (chosenWorkplaceId) {
      getWorkplaceTaskStatuses({ variables: { workplaceId: chosenWorkplaceId } });
    }
  }, [chosenWorkplaceId, getWorkplaceTaskStatuses]);

  const onStatusChange = async (newStatusId: string): Promise<void> => {
    try {
      await changeTaskStatusMutation({
        variables: { changeTaskStatusData: { id: taskId, taskStatusId: newStatusId } },
      });
    } catch (error) {
      //
    }
  };
  return (
    <>
      {statusesData?.getWorkplaceTaskStatuses.length && (
        <TaskStatusPickerView
          onStatusChange={onStatusChange}
          statusId={statusId}
          statuses={statusesData.getWorkplaceTaskStatuses}
          changeStatusError={changeStatusError}
          statusesFetchingError={statusesFetchingError}
          disabled={disabled}
        />
      )}
    </>
  );
}
