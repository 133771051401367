import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
export type WorkplaceUserFragment = { __typename?: 'User', id: any, firstName?: Types.Maybe<string>, lastName?: Types.Maybe<string> };

export const WorkplaceUserFragmentDoc = gql`
    fragment WorkplaceUser on User {
  id
  firstName
  lastName
}
    `;