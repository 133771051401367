import React, { useState } from "react";
import { useUpdatePatrolEventLocationMutation } from "../../../../../graphql/mutation.generated";
import PatrolEventLocationView from "./PatrolEventLocationView";

interface Props {
  patrolEventId: string;
  eventLocation?: string;
  disabled?: boolean;
}
export default function PatrolEventLocation({
  eventLocation,
  patrolEventId,
  disabled,
}: Props): JSX.Element {
  const [locationState, setLocationState] = useState<string | undefined>(eventLocation);

  const [
    updateLocationMutation,
    { error, loading },
  ] = useUpdatePatrolEventLocationMutation();

  const onLocationChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setLocationState(event.target.value);
  };

  const onLocationBlur = async (): Promise<void> => {
    if (locationState !== eventLocation) {
      try {
        await updateLocationMutation({
          variables: {
            updatePatrolEventLocationData: {
              id: patrolEventId,
              location: locationState,
            },
          },
        });
      } catch (e) {
        //
      }
    }
  };

  return (
    <PatrolEventLocationView
      locationState={locationState || ""}
      onLocationBlur={onLocationBlur}
      onLocationChange={onLocationChange}
      disabled={disabled}
      error={error}
      loading={loading}
    />
  );
}
