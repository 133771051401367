import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { QueryVariablesEnum } from "../../../../interfaces/QueryVariables";
import { TASK_DETAILS, WORKPLACE } from "../../../../routing/paths";
import theme from "../../../../utils/theme";
import { TasksListFragment } from "../../graphql/fragment.generated";
import SingleTaskView from "./SingleTaskView";

interface Props {
  task: TasksListFragment;
  workplaceShortName?: string;
  workplaceId?: string;
}
export default function SingleTask({
  task: {
    name,
    assignees,
    friendlyId,
    children,
    status,
    dueDate,
    finishedDate,
    location,
    id,
  },
  workplaceShortName,
  workplaceId,
}: Props): JSX.Element {
  const history = useHistory();

  const [viewChildren, setViewChildren] = useState<boolean>(false);

  const onTaskClick = (clickedId: string): void => {
    if (workplaceId) {
      history.push(
        `${WORKPLACE}${TASK_DETAILS}?${QueryVariablesEnum.WORKPLACEID}=${workplaceId}&${QueryVariablesEnum.TASKID}=${clickedId}`
      );
    }
  };

  const onChildrenViewChange = (): void => {
    setViewChildren(!viewChildren);
  };
  return (
    <>
      <SingleTaskView
        name={name}
        friendlyId={friendlyId}
        statusName={status.name}
        statusType={status.type}
        assignee={
          assignees.length
            ? {
                firstName: assignees[0].firstName || "",
                lastName: assignees[0].lastName || "",
              }
            : undefined
        }
        dueDate={dueDate}
        finishedDate={finishedDate}
        location={location || ""}
        workplaceShortName={workplaceShortName}
        onClick={() => onTaskClick(id)}
        onChildrenViewChange={children.length ? onChildrenViewChange : undefined}
        childrenDisplayed={viewChildren}
      />
      {Boolean(children.length) && viewChildren && (
        <Box mb={2} bgcolor={theme.palette.primary.light}>
          {children.map((childTask) => (
            <SingleTaskView
              key={`childTask${childTask.id}`}
              name={childTask.name}
              friendlyId={childTask.friendlyId}
              statusName={childTask.status.name}
              statusType={childTask.status.type}
              assignee={
                childTask.assignees.length
                  ? {
                      firstName: childTask.assignees[0].firstName || "",
                      lastName: childTask.assignees[0].lastName || "",
                    }
                  : undefined
              }
              dueDate={childTask.dueDate}
              finishedDate={childTask.finishedDate}
              location={childTask.location || ""}
              workplaceShortName={workplaceShortName}
              onClick={() => onTaskClick(childTask.id)}
              isChild
            />
          ))}
        </Box>
      )}
    </>
  );
}
