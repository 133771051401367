import {
  Box,
  CircularProgress,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../../components/ErrorDisplay/ErrorDisplay";
import { SHORT_DATE_FORMAT_WITH_HOUR } from "../../../../../utils/consts";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { ApolloError } from "@apollo/client";
import ConfirmDialog from "../../../../../components/ConfirmDialog/ConfirmDialog";
import EditCommentDialog from "./EditCommentDialog";
import theme, { blueColorBackground } from "../../../../../utils/theme";

interface Props {
  loading?: boolean;
  notDeletable?: boolean;
  content: string;
  createdAt: string;
  updatedAt?: string;

  openDeleteCommentDialog: () => void;
  deleteCommentDialogOpen: boolean;
  closeDeleteCommentDialog: () => void;
  deleteComment: () => void;
  deleteCommentError?: ApolloError;

  openEditCommentDialog: () => void;
  editCommentDialogOpen: boolean;
  closeEditCommentDialog: () => void;
  editComment: (value: string) => void;
  editCommentError?: ApolloError;
}

const useStyles = makeStyles({
  commentWrapper: {
    minWidth: "60%",
    maxWidth: "80%",
  },
  commentValue: {
    backgroundColor: blueColorBackground,
    borderRadius: theme.shape.borderRadius,
  },
  captions: {
    fontSize: "8px",
    fontWeight: 500,
  },
});

export default function OwnCommentView({
  openEditCommentDialog,
  loading,
  notDeletable,
  openDeleteCommentDialog,
  content,
  createdAt,
  updatedAt,
  deleteCommentDialogOpen,
  closeDeleteCommentDialog,
  deleteComment,
  editCommentDialogOpen,
  closeEditCommentDialog,
  editComment,
  deleteCommentError,
  editCommentError,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Box display="flex" alignItems="center">
          <Box mr={1}>{loading && <CircularProgress size={20} />}</Box>
          <Box>
            <IconButton size="small" onClick={openEditCommentDialog}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Box>
          {!notDeletable && (
            <Box>
              <IconButton onClick={openDeleteCommentDialog} size="small">
                <DeleteForeverIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box className={classes.commentWrapper}>
          <Box>
            <Typography variant="caption" color="secondary" className={classes.captions}>
              {t("You wrote")}:
            </Typography>
          </Box>
          <Box className={classes.commentValue} py={1} px={2}>
            <Typography variant="body2">{content}</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={0}>
            <Box mr={2}>
              <Typography
                variant="caption"
                color="secondary"
                className={classes.captions}
              >
                {t("Created at")} {moment(createdAt).format(SHORT_DATE_FORMAT_WITH_HOUR)}
              </Typography>
            </Box>
            {updatedAt && (
              <Box>
                <Typography
                  variant="caption"
                  color="secondary"
                  className={classes.captions}
                >
                  {t("Updated at")}{" "}
                  {moment(createdAt).format(SHORT_DATE_FORMAT_WITH_HOUR)}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <ConfirmDialog
        open={deleteCommentDialogOpen}
        onDecline={closeDeleteCommentDialog}
        onConfirm={deleteComment}
        dialogContent={t(
          "Your comment will be deleted, this action can not be reversed, are you sure?"
        )}
        dialogTitle={t("Are you sure?")}
      />
      {editCommentDialogOpen && (
        <EditCommentDialog
          content={content}
          onClose={closeEditCommentDialog}
          open
          onSubmit={editComment}
        />
      )}
      <ErrorDisplay
        error={deleteCommentError}
        message={t(
          "Error occured while deleting comment, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={editCommentError}
        message={t(
          "Error occured while updating comment, please try again or contact us."
        )}
      />
    </>
  );
}
