import { yupResolver } from "@hookform/resolvers/yup";
import { TFunction } from "i18next";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";
import { requiredStringValidator } from "../../../../../utils/formValidators";

interface Props {
  open: boolean;
  content: string;
  onSubmit: (newContent: string) => void;
  onClose: () => void;
}

interface FormValue {
  comment: string;
}

const schema = (t: TFunction): yup.AnyObjectSchema =>
  yup.object().shape({
    comment: requiredStringValidator(t),
  });

export default function EditCommentDialog({
  open,
  content,
  onSubmit,
  onClose,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { register, handleSubmit, errors } = useForm<FormValue>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      comment: content,
    },
  });

  const onCommentSubmit = (formValue: FormValue): void => {
    onSubmit(formValue.comment);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Box px={4} py={3}>
        <form onSubmit={handleSubmit(onCommentSubmit)}>
          <DialogTitle>{t("Comment edit")}</DialogTitle>
          <TextField
            multiline
            rows={6}
            rowsMax={6}
            name="comment"
            label={t("Comment content")}
            fullWidth
            autoFocus
            variant="outlined"
            inputRef={register}
            error={!!errors.comment}
            helperText={errors.comment?.message}
          />
          <DialogContent />

          <DialogActions>
            <Button onClick={(): void => onClose()} color="secondary">
              {t("Cancel")}
            </Button>
            <Button type="submit" color="primary">
              {t("Confirm")}
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
}
