import { Box, CircularProgress, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  message?: string;
  open?: boolean;
}

export default function LoadingSnackbar({ message, open }: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Snackbar open={open}>
      <MuiAlert severity="warning" elevation={6} icon={() => <div />}>
        <Box display="flex" justifyContent="center" alignItems="center">
          {message || `${t("Loading")}...`}
          <Box ml={1}>
            <CircularProgress size={20} />
          </Box>
        </Box>
      </MuiAlert>
    </Snackbar>
  );
}
