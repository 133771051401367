import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { WorkplaceContext } from "../../context/WorkplaceContext/WorkplaceContextProvider";
import { QueryVariablesEnum } from "../../interfaces/QueryVariables";
import { PATROLS_LIST, TASKS, WORKPLACE } from "../../routing/paths";
import { MenuLink } from "./MenuLink";
import MenuView from "./MenuView";

interface Props {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  workplaceChooseDisabled?: boolean;
}
export default function Menu({
  open,
  onClose,
  onOpen,
  workplaceChooseDisabled,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { loading: workplacesLoading, workplaces, chosenWorkplaceId } = useContext(
    WorkplaceContext
  );

  const links: MenuLink[] = [
    {
      name: t("Tasks"),
      url: chosenWorkplaceId
        ? `${WORKPLACE + TASKS}?${QueryVariablesEnum.WORKPLACEID}=${chosenWorkplaceId}`
        : "",
      // subMenu: [
      //   {
      //     name: t("Kanban"),
      //     url: chosenWorkplaceId
      //       ? `${WORKPLACE + TASKS + KANBAN}?${
      //           QueryVariablesEnum.WORKPLACEID
      //         }=${chosenWorkplaceId}`
      //       : "",
      //   },
      //   {
      //     name: t("Task list"),
      //     url: chosenWorkplaceId
      //       ? `${WORKPLACE + TASKS + TASKS_LIST}?${
      //           QueryVariablesEnum.WORKPLACEID
      //         }=${chosenWorkplaceId}`
      //       : "",
      //   },
      //   {
      //     name: t("Archive"),
      //     url: chosenWorkplaceId
      //       ? `${WORKPLACE + TASKS_ARCHIVE}?${
      //           QueryVariablesEnum.WORKPLACEID
      //         }=${chosenWorkplaceId}`
      //       : "",
      //   },
      // ],
    },
    {
      name: t("Patrols list"),
      url: chosenWorkplaceId
        ? `${WORKPLACE + PATROLS_LIST}?${
            QueryVariablesEnum.WORKPLACEID
          }=${chosenWorkplaceId}`
        : "",
    },
  ];

  return (
    <MenuView
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      loading={workplacesLoading}
      workplaces={workplaces}
      workplaceChooseDisabled={workplaceChooseDisabled}
      menuLinks={links}
    />
  );
}
