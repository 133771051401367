import { ApolloError } from "@apollo/client";
import { TextField } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import EditableInputAdornment from "../../../../../../../components/EditableInputAdornment/EditableInputAdornment";
import ErrorDisplay from "../../../../../../../components/ErrorDisplay/ErrorDisplay";

interface Props {
  name: string;
  onNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onNameBlur: () => void;
  disabled?: boolean;
  loading?: boolean;
  error?: ApolloError;
}
export default function PatrolEventNameView({
  name,
  onNameBlur,
  onNameChange,
  disabled,
  error,
  loading,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <TextField
        fullWidth
        multiline
        InputProps={{
          disableUnderline: true,
          startAdornment: <EditableInputAdornment display={Boolean(name) && !disabled} />,
        }}
        value={name}
        label={t("Event name")}
        placeholder={t("Add event title")}
        onBlur={onNameBlur}
        onChange={onNameChange}
        disabled={disabled || Boolean(loading)}
      />
      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while updating patrol event name, try again or contact us."
        )}
      />
    </>
  );
}
