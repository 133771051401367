import { ApolloError } from "@apollo/client";
import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import BasicMenuLayout from "../../../layouts/BasicMenuLayout";
import theme from "../../../utils/theme";
import { TaskDetailsFragment } from "../graphql/fragment.generated";
import ActionButtons from "./ActionButtons/ActionButtons";
import ChildTasks from "./ChildTasks/ChildTasks";
import TaskAttachments from "./TaskAttachments/TaskAttachments";
import TaskBasicInfo from "./TaskBasicInfo/TaskBasicInfo";
import TaskComments from "./TaskComments/TaskComments";
import TaskMoreMenu from "./TaskMoreMenu/TaskMoreMenu";

const useStyles = makeStyles({
  section: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
});

interface Props {
  loading?: boolean;
  taskDataError?: ApolloError;
  task?: TaskDetailsFragment;
  canUserEditTask: boolean;
  canUserEditAttachments: boolean;
  canUserEditTaskStatus: boolean;
  canUserArchiveTask: boolean;
  onCommentsButtonClick: () => void;
  commentsOpen: boolean;
  onCommentsClose: () => void;
  workplaceId?: string;
}
export default function TaskPageView({
  loading,
  taskDataError,
  task,
  canUserEditAttachments,
  canUserEditTask,
  canUserEditTaskStatus,
  onCommentsButtonClick,
  commentsOpen,
  onCommentsClose,
  workplaceId,
  canUserArchiveTask,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <BasicMenuLayout px={0} loading={loading} workplaceChooseDisabled>
      {task && (
        <>
          <Box px={1.5} mb={8} position="relative">
            <Box position="absolute" right="5px" top="0">
              <TaskMoreMenu
                taskId={task.id}
                workplaceId={workplaceId}
                isTaskArchived={task.isArchived}
                parentTaskId={task.parent?.id}
                canUserArchiveTask={canUserArchiveTask}
                canUserEditTask={canUserEditTask}
              />
            </Box>

            <Box className={classes.section}>
              <TaskBasicInfo
                task={task}
                canUserEditTask={canUserEditTask}
                canUserEditTaskStatus={canUserEditTaskStatus}
              />
            </Box>
            <Box className={classes.section}>
              <TaskAttachments canUserEdit={canUserEditAttachments} taskId={task.id} />
            </Box>
            {!task.parent && (
              <Box className={classes.section}>
                <ChildTasks
                  childTasks={task.children}
                  parentTask={task}
                  workplaceId={workplaceId}
                  canUserEditTask={canUserEditTask}
                />
              </Box>
            )}

            {commentsOpen && (
              <Box
                position="fixed"
                zIndex="100"
                bgcolor="#fff"
                top="0"
                left="0"
                height={`calc(100vh - ${theme.spacing(14)}px)`}
                width="100%"
                p={1.5}
                boxSizing="border-box"
                mt={6}
              >
                <TaskComments onCommentsClose={onCommentsClose} taskId={task.id} />
              </Box>
            )}
          </Box>
          <ActionButtons
            taskId={task.id}
            onCommentsButtonClick={onCommentsButtonClick}
            canUserEditAttachments={canUserEditAttachments}
          />
        </>
      )}
      <ErrorDisplay
        error={taskDataError}
        message={t(
          "Error occured while fetching task data, please try again later or contact us."
        )}
      />
    </BasicMenuLayout>
  );
}
