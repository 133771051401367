import { InputAdornment, MenuItem, TextField } from "@material-ui/core";
import { LocationOn } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { Workplace } from "../../generated/types";

interface Props {
  workplaces?: Workplace[];
  workplaceChooseDisabled?: boolean;
  chosenWorkplaceId: string;
  onWorkplaceChange: (event) => void;
}
export default function WorkplacePickerView({
  workplaces,
  workplaceChooseDisabled,
  chosenWorkplaceId,
  onWorkplaceChange,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <TextField
      label={t("Current workplace")}
      disabled={workplaceChooseDisabled}
      select
      value={chosenWorkplaceId}
      onChange={onWorkplaceChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LocationOn />
          </InputAdornment>
        ),
      }}
      variant="outlined"
      fullWidth
    >
      {workplaces?.map((workplace) => (
        <MenuItem key={workplace.id} value={workplace.id}>
          {workplace.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
