import { ApolloError } from "@apollo/client";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import React from "react";
import { UseFormMethods } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import BasicMenuLayout from "../../../layouts/BasicMenuLayout";
import { AccountSettingsFormData } from "./AccountSettingsFormData";

interface Props {
  loading?: boolean;
  error?: ApolloError;
  userEmail?: string;
  formMethods: UseFormMethods<AccountSettingsFormData>;
  onFormSubmit: (formData: AccountSettingsFormData) => void;
  onLogout: () => void;
}
export default function AccountSettingsPageView({
  error,
  loading,
  userEmail,
  formMethods,
  onFormSubmit,
  onLogout,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { handleSubmit, register, formState } = formMethods;

  return (
    <BasicMenuLayout loading={loading}>
      <Box mb={3} mt={1}>
        <Button onClick={() => onLogout()} fullWidth variant="outlined">
          {t("Logout")}
        </Button>
      </Box>
      <Box mb={2}>
        <Typography variant="h5" align="center">
          {t("Account settings")}
        </Typography>
      </Box>
      <Box pb={2}>
        <TextField
          name="email"
          label={t("Email")}
          fullWidth
          variant="outlined"
          disabled
          InputLabelProps={{
            shrink: true,
          }}
          value={userEmail}
        />
      </Box>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Box pb={2}>
          <TextField
            name="firstName"
            label={t("First name")}
            fullWidth
            variant="outlined"
            inputRef={register}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box pb={2}>
          <TextField
            name="lastName"
            label={t("Last name")}
            fullWidth
            variant="outlined"
            inputRef={register}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box pb={2}>
          <TextField
            name="phoneNumber"
            label={t("Phone number")}
            fullWidth
            variant="outlined"
            inputRef={register}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Button
          disabled={!formState.isDirty}
          color="primary"
          variant="contained"
          type="submit"
          fullWidth
          size="large"
        >
          {t("Submit changes")}
        </Button>
      </form>

      <ErrorDisplay
        error={error}
        message={t(
          "Error occured while updating user data, please try again or contact us."
        )}
      />
    </BasicMenuLayout>
  );
}
