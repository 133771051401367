export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: any;
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: any;
  /** The VatNumber scalar type represents a valid European vat number with uppercase country code on the beginning, for example PL7922101799. Valid EU Vat number should contain between 4 and 15 characters, start with 2 character country code and contain only digits after country code. Currently supported countries: PL */
  VatNumber: any;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Query = {
  __typename?: 'Query';
  getWorkplaceTaskStatuses: Array<TaskStatus>;
  getCategories: Array<Category>;
  getTaskComments: Array<Comment>;
  getCompany: Company;
  getCompanies: Array<Company>;
  getCompanyRoles: Array<CompanyRole>;
  getLabels: Array<Label>;
  getNotifications: GetNotificationsResponse;
  getUnreadNotificationsCount: Scalars['Int'];
  getPatrolEventAttachments: Array<PatrolEventAttachment>;
  getPatrolEvent: PatrolEvent;
  getPatrolEvents: Array<PatrolEvent>;
  getTaskPatrolEvent?: Maybe<PatrolEvent>;
  getPatrols: GetPatrolsResponse;
  getPatrol: Patrol;
  getTaskAttachments: Array<TaskAttachment>;
  getWorkplaceTasks: Array<Task>;
  getWorkplaceArchivedTasks: GetWorkplaceArchivedTasksResponse;
  getTask: Task;
  getPatrolEventTasks: Array<Task>;
  getUserCompanyRolesForCompany: Array<UserCompanyRole>;
  getUser: User;
  getAllUsers: Array<User>;
  getCompanyUsers: Array<User>;
  getWorkplaceUsers: Array<User>;
  getUserWorkplaceRolesForWorkplace: Array<UserWorkplaceRole>;
  getWorkplace: Workplace;
  getWorkplaces: Array<Workplace>;
  getWorkplaceRoles: Array<WorkplaceRole>;
};


export type QueryGetWorkplaceTaskStatusesArgs = {
  workplaceId: Scalars['UUID'];
};


export type QueryGetCategoriesArgs = {
  companyId: Scalars['UUID'];
};


export type QueryGetTaskCommentsArgs = {
  taskId: Scalars['UUID'];
};


export type QueryGetCompanyArgs = {
  id: Scalars['UUID'];
};


export type QueryGetCompanyRolesArgs = {
  companyId: Scalars['UUID'];
};


export type QueryGetLabelsArgs = {
  companyId: Scalars['UUID'];
};


export type QueryGetNotificationsArgs = {
  paginationData: PaginationData;
};


export type QueryGetPatrolEventAttachmentsArgs = {
  patrolEventId: Scalars['UUID'];
};


export type QueryGetPatrolEventArgs = {
  id: Scalars['UUID'];
};


export type QueryGetPatrolEventsArgs = {
  patrolId: Scalars['UUID'];
};


export type QueryGetTaskPatrolEventArgs = {
  taskId: Scalars['UUID'];
};


export type QueryGetPatrolsArgs = {
  filters?: Maybe<PatrolFilters>;
  paginationData: PaginationData;
  workplaceId: Scalars['UUID'];
};


export type QueryGetPatrolArgs = {
  id: Scalars['UUID'];
};


export type QueryGetTaskAttachmentsArgs = {
  taskId: Scalars['UUID'];
};


export type QueryGetWorkplaceTasksArgs = {
  filters?: Maybe<TaskFilters>;
  workplaceId: Scalars['UUID'];
};


export type QueryGetWorkplaceArchivedTasksArgs = {
  filters?: Maybe<ArchivedTaskFilters>;
  paginationData: PaginationData;
  workplaceId: Scalars['UUID'];
};


export type QueryGetTaskArgs = {
  id: Scalars['UUID'];
};


export type QueryGetPatrolEventTasksArgs = {
  patrolEventId: Scalars['UUID'];
};


export type QueryGetUserCompanyRolesForCompanyArgs = {
  companyId: Scalars['UUID'];
};


export type QueryGetCompanyUsersArgs = {
  companyId: Scalars['UUID'];
};


export type QueryGetWorkplaceUsersArgs = {
  includeInactive?: Maybe<Scalars['Boolean']>;
  workplaceId: Scalars['UUID'];
};


export type QueryGetUserWorkplaceRolesForWorkplaceArgs = {
  workplaceId: Scalars['UUID'];
};


export type QueryGetWorkplaceArgs = {
  id: Scalars['UUID'];
};


export type QueryGetWorkplacesArgs = {
  companyId: Scalars['UUID'];
};


export type QueryGetWorkplaceRolesArgs = {
  companyId: Scalars['UUID'];
};

/** Task status */
export type TaskStatus = {
  __typename?: 'TaskStatus';
  id: Scalars['UUID'];
  name: Scalars['String'];
  type: TaskStatusType;
  order: Scalars['Int'];
};


export enum TaskStatusType {
  Todo = 'TODO',
  InProgress = 'IN_PROGRESS',
  Done = 'DONE'
}

/** Task category */
export type Category = {
  __typename?: 'Category';
  id: Scalars['UUID'];
  name: Scalars['String'];
};

/** Task comment */
export type Comment = {
  __typename?: 'Comment';
  id: Scalars['UUID'];
  content: Scalars['String'];
  author?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

/** The user type */
export type User = {
  __typename?: 'User';
  id: Scalars['UUID'];
  email: Scalars['EmailAddress'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  userSystemRole?: Maybe<UserSystemRole>;
  userCompanyRoles?: Maybe<Array<UserCompanyRole>>;
  userWorkplaceRoles?: Maybe<Array<UserWorkplaceRole>>;
};


/** The user system role type */
export type UserSystemRole = {
  __typename?: 'UserSystemRole';
  id: Scalars['UUID'];
  systemRole: SystemRole;
};

/** The system role type */
export type SystemRole = {
  __typename?: 'SystemRole';
  id: Scalars['UUID'];
  name: Scalars['String'];
  permissions: Array<Permissions>;
  builtin: Scalars['Boolean'];
};

export enum Permissions {
  ViewSystemRoles = 'view_system_roles',
  ViewSystemUsers = 'view_system_users',
  CreateSystemUser = 'create_system_user',
  EditSystemUserRole = 'edit_system_user_role',
  DeleteSystemUser = 'delete_system_user',
  CreateCompany = 'create_company',
  ViewCompany = 'view_company',
  EditCompany = 'edit_company',
  DeleteCompany = 'delete_company',
  ViewCompanyRoles = 'view_company_roles',
  ViewCompanyUsers = 'view_company_users',
  CreateCompanyUser = 'create_company_user',
  EditCompanyUserRole = 'edit_company_user_role',
  DeleteCompanyUser = 'delete_company_user',
  ViewTerms = 'view_terms',
  CreateTerm = 'create_term',
  EditTerm = 'edit_term',
  DeleteTerm = 'delete_term',
  ViewWorkplace = 'view_workplace',
  CreateWorkplace = 'create_workplace',
  EditWorkplace = 'edit_workplace',
  DeleteWorkplace = 'delete_workplace',
  ViewWorkplaceRoles = 'view_workplace_roles',
  ViewWorkplaceUsers = 'view_workplace_users',
  CreateWorkplaceUser = 'create_workplace_user',
  EditWorkplaceUserRole = 'edit_workplace_user_role',
  DeleteWorkplaceUser = 'delete_workplace_user',
  ViewTasks = 'view_tasks',
  CreateTask = 'create_task',
  EditTask = 'edit_task',
  EditTaskStatus = 'edit_task_status',
  DeleteTask = 'delete_task',
  ArchiveTask = 'archive_task',
  ViewTasksOwn = 'view_tasks_own',
  EditTaskOwn = 'edit_task_own',
  EditTaskStatusOwn = 'edit_task_status_own',
  DeleteTaskOwn = 'delete_task_own',
  ArchiveTaskOwn = 'archive_task_own',
  ViewPatrols = 'view_patrols',
  CreatePatrol = 'create_patrol',
  EditPatrol = 'edit_patrol',
  DeletePatrol = 'delete_patrol',
  ViewPatrolsOwn = 'view_patrols_own',
  EditPatrolOwn = 'edit_patrol_own',
  DeletePatrolOwn = 'delete_patrol_own'
}

/** The user company role type */
export type UserCompanyRole = {
  __typename?: 'UserCompanyRole';
  id: Scalars['UUID'];
  companyRole: CompanyRole;
  company: Company;
};

/** The company role type */
export type CompanyRole = {
  __typename?: 'CompanyRole';
  id: Scalars['UUID'];
  name: Scalars['String'];
  permissions: Array<Permissions>;
  company?: Maybe<Array<Company>>;
  builtin: Scalars['Boolean'];
  default: Scalars['Boolean'];
};

/** The company type */
export type Company = {
  __typename?: 'Company';
  id: Scalars['UUID'];
  name: Scalars['String'];
  vatNumber: Scalars['VatNumber'];
  address: Scalars['String'];
  city: Scalars['String'];
  postCode: Scalars['String'];
  country: Country;
};


export enum Country {
  Poland = 'Poland'
}

/** The user workplace role type */
export type UserWorkplaceRole = {
  __typename?: 'UserWorkplaceRole';
  id: Scalars['UUID'];
  workplaceRole: WorkplaceRole;
  workplace: Workplace;
};

/** The workplace role type */
export type WorkplaceRole = {
  __typename?: 'WorkplaceRole';
  id: Scalars['UUID'];
  name: Scalars['String'];
  permissions: Array<Permissions>;
  company?: Maybe<Array<Company>>;
  builtin: Scalars['Boolean'];
  default: Scalars['Boolean'];
};

/** The workplace type */
export type Workplace = {
  __typename?: 'Workplace';
  id: Scalars['UUID'];
  name: Scalars['String'];
  shortName: Scalars['String'];
};


/** Task label */
export type Label = {
  __typename?: 'Label';
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type GetNotificationsResponse = {
  __typename?: 'GetNotificationsResponse';
  items: Array<Notification>;
  totalCount: Scalars['Int'];
};

/** User notification */
export type Notification = {
  __typename?: 'Notification';
  id: Scalars['UUID'];
  sourceUser?: Maybe<User>;
  patrol?: Maybe<Patrol>;
  task?: Maybe<Task>;
  read: Scalars['Boolean'];
  sourceType: NotificationSourceType;
  type: NotificationType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  customData?: Maybe<NotificationCustomData>;
};

/** Patrol */
export type Patrol = {
  __typename?: 'Patrol';
  id: Scalars['UUID'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  assignees?: Maybe<Array<User>>;
  createdBy?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

/** Task status */
export type Task = {
  __typename?: 'Task';
  id: Scalars['UUID'];
  friendlyId: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  status: TaskStatus;
  order: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  reportedDate?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  isArchived: Scalars['Boolean'];
  children: Array<Task>;
  parent?: Maybe<Task>;
  categories: Array<Category>;
  labels: Array<Label>;
  createdBy?: Maybe<User>;
  reporter?: Maybe<User>;
  assignees: Array<User>;
  finishedDate?: Maybe<Scalars['DateTime']>;
};

export enum NotificationSourceType {
  Patrol = 'PATROL',
  Task = 'TASK',
  System = 'SYSTEM'
}

export enum NotificationType {
  AssignedToTask = 'ASSIGNED_TO_TASK',
  UnassignedFromTask = 'UNASSIGNED_FROM_TASK',
  AssignedReporterToTask = 'ASSIGNED_REPORTER_TO_TASK',
  UnassignedReporterFromTask = 'UNASSIGNED_REPORTER_FROM_TASK',
  ArchivedTask = 'ARCHIVED_TASK',
  CommentedTask = 'COMMENTED_TASK',
  ChangedTaskStatus = 'CHANGED_TASK_STATUS',
  ChangedTaskDueDate = 'CHANGED_TASK_DUE_DATE',
  DeletedTask = 'DELETED_TASK',
  AssignedToPatrol = 'ASSIGNED_TO_PATROL',
  UnassignedFromPatrol = 'UNASSIGNED_FROM_PATROL',
  DeletedPatrol = 'DELETED_PATROL'
}

/** Custom data of notification, data differs based on notification type. */
export type NotificationCustomData = {
  __typename?: 'NotificationCustomData';
  commentId?: Maybe<Scalars['UUID']>;
  dueDate?: Maybe<Scalars['String']>;
  patrolId?: Maybe<Scalars['UUID']>;
  statusId?: Maybe<Scalars['UUID']>;
  statusType?: Maybe<TaskStatusType>;
  targetUsersIds?: Maybe<Array<Scalars['UUID']>>;
  taskId?: Maybe<Scalars['UUID']>;
  taskName?: Maybe<Scalars['String']>;
};

/** Input object for pagination. "limit" sets a number of items per page, "offset" sets an offset (number of an element to start). */
export type PaginationData = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

/** Patrol Event Attachment Relational Object */
export type PatrolEventAttachment = {
  __typename?: 'PatrolEventAttachment';
  id: Scalars['UUID'];
  attachment: Attachment;
  createdBy?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

/** Attachment */
export type Attachment = {
  __typename?: 'Attachment';
  id: Scalars['UUID'];
  filename: Scalars['String'];
  mimetype: MimeType;
  encoding: Scalars['String'];
  uri: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum MimeType {
  Bmp = 'bmp',
  Gif = 'gif',
  Jpeg = 'jpeg',
  Png = 'png',
  Tiff = 'tiff',
  Pdf = 'pdf',
  Mp4 = 'mp4'
}

/** Patrol Event */
export type PatrolEvent = {
  __typename?: 'PatrolEvent';
  id: Scalars['UUID'];
  eventDate: Scalars['DateTime'];
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  patrolEventAttachments?: Maybe<Array<PatrolEventAttachment>>;
};

export type GetPatrolsResponse = {
  __typename?: 'GetPatrolsResponse';
  items: Array<Patrol>;
  totalCount: Scalars['Int'];
};

/** Input object for patrol filters */
export type PatrolFilters = {
  startDate?: Maybe<DateFilter>;
};

/** Input object for date filter */
export type DateFilter = {
  minDate?: Maybe<Scalars['DateTime']>;
  maxDate?: Maybe<Scalars['DateTime']>;
};

/** Task Attachment Relational Object */
export type TaskAttachment = {
  __typename?: 'TaskAttachment';
  id: Scalars['UUID'];
  attachment: Attachment;
  createdBy?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

/** Input object for task filters */
export type TaskFilters = {
  assigneesIds?: Maybe<Array<Scalars['UUID']>>;
  categoriesIds?: Maybe<Array<Scalars['UUID']>>;
  createdById?: Maybe<Scalars['UUID']>;
  dueDate?: Maybe<DateFilter>;
  finishedDate?: Maybe<DateFilter>;
  labelsIds?: Maybe<Array<Scalars['UUID']>>;
  reportedDate?: Maybe<DateFilter>;
  reporterId?: Maybe<Scalars['UUID']>;
  taskStatusId?: Maybe<Scalars['UUID']>;
};

export type GetWorkplaceArchivedTasksResponse = {
  __typename?: 'GetWorkplaceArchivedTasksResponse';
  items: Array<Task>;
  totalCount: Scalars['Int'];
};

/** Input object for archived task filters */
export type ArchivedTaskFilters = {
  createdById?: Maybe<Scalars['UUID']>;
  dueDate?: Maybe<DateFilter>;
  finishedDate?: Maybe<DateFilter>;
  reportedDate?: Maybe<DateFilter>;
  reporterId?: Maybe<Scalars['UUID']>;
  taskStatusId?: Maybe<Scalars['UUID']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createTaskStatus: TaskStatus;
  updateTaskStatusesOrder: Scalars['Boolean'];
  deleteTaskStatus: Scalars['Boolean'];
  editTaskStatus: TaskStatus;
  createCategory: Category;
  deleteCategory: Scalars['Boolean'];
  updateCategory: Category;
  createComment: Comment;
  deleteComment: Scalars['Boolean'];
  updateComment: Comment;
  createCompany: Company;
  updateCompany: Company;
  contactUs: Scalars['Boolean'];
  createLabel: Label;
  deleteLabel: Scalars['Boolean'];
  updateLabel: Label;
  setNotificationAsRead: Scalars['Boolean'];
  setAllNotificationsAsRead: Scalars['Boolean'];
  uploadPatrolEventAttachment: PatrolEventAttachment;
  uploadPatrolEventAttachments: Array<PatrolEventAttachment>;
  deletePatrolEventAttachment: Scalars['Boolean'];
  createPatrolEvent: PatrolEvent;
  updatePatrolEventAction: PatrolEvent;
  updatePatrolEventDescription: PatrolEvent;
  updatePatrolEventEventDate: PatrolEvent;
  updatePatrolEventLocation: PatrolEvent;
  updatePatrolEventName: PatrolEvent;
  deletePatrolEvent: Scalars['Boolean'];
  createPatrol: Patrol;
  updatePatrolDescription: Patrol;
  updatePatrolLocation: Patrol;
  updatePatrolEndDate: Patrol;
  updatePatrolStartDate: Patrol;
  changePatrolAssignees: Patrol;
  deletePatrol: Scalars['Boolean'];
  uploadTaskAttachment: TaskAttachment;
  uploadTaskAttachments: Array<TaskAttachment>;
  deleteTaskAttachment: Scalars['Boolean'];
  createTask: Task;
  deleteTask: Scalars['Boolean'];
  updateTaskName: Task;
  updateTaskDescription: Task;
  updateTaskLocation: Task;
  updateTaskDueDate: Task;
  updateTaskReportedDate: Task;
  updateTasksOrders: Scalars['Boolean'];
  updateTaskIsArchived: Task;
  changeTaskStatus: Task;
  changeTaskCategories: Task;
  changeTaskLabels: Task;
  changeTaskParent: Task;
  changeTaskAssignees: Task;
  changeTaskReporter: Task;
  addUserToCompany: UserCompanyRole;
  changeUserCompanyRole: UserCompanyRole;
  deleteUserCompanyRole: Scalars['Boolean'];
  signIn: SignInResponse;
  signUp: Scalars['Boolean'];
  signUpFromInvitation: Scalars['Boolean'];
  activateAccount: Scalars['Boolean'];
  requestPasswordResetMail: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  updateUser: User;
  deleteUser: Scalars['Boolean'];
  addUserToWorkplace: UserWorkplaceRole;
  changeUserWorkplaceRole: UserWorkplaceRole;
  deleteUserWorkplaceRole: Scalars['Boolean'];
  createWorkplace: Workplace;
  updateWorkplace: Workplace;
  deleteWorkplace: Scalars['Boolean'];
};


export type MutationCreateTaskStatusArgs = {
  createTaskStatusData: CreateTaskStatusData;
};


export type MutationUpdateTaskStatusesOrderArgs = {
  updateTaskStatusesOrderData: Array<UpdateTaskStatusOrderData>;
};


export type MutationDeleteTaskStatusArgs = {
  replaceStatusId: Scalars['String'];
  taskStatusId: Scalars['String'];
};


export type MutationEditTaskStatusArgs = {
  editTaskStatusData: EditTaskStatusData;
};


export type MutationCreateCategoryArgs = {
  createCategoryData: CreateCategoryData;
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['UUID'];
};


export type MutationUpdateCategoryArgs = {
  updateCategoryData: UpdateCategoryData;
};


export type MutationCreateCommentArgs = {
  createCommentData: CreateCommentData;
};


export type MutationDeleteCommentArgs = {
  id: Scalars['UUID'];
};


export type MutationUpdateCommentArgs = {
  updateCommentData: UpdateCommentData;
};


export type MutationCreateCompanyArgs = {
  companyData: CreateCompanyData;
};


export type MutationUpdateCompanyArgs = {
  updateCompanyData: UpdateCompanyData;
};


export type MutationContactUsArgs = {
  contactUsData: ContactUsData;
};


export type MutationCreateLabelArgs = {
  createLabelData: CreateLabelData;
};


export type MutationDeleteLabelArgs = {
  id: Scalars['UUID'];
};


export type MutationUpdateLabelArgs = {
  updateLabelData: UpdateLabelData;
};


export type MutationSetNotificationAsReadArgs = {
  id: Scalars['UUID'];
};


export type MutationUploadPatrolEventAttachmentArgs = {
  file: Scalars['Upload'];
  patrolEventId: Scalars['UUID'];
};


export type MutationUploadPatrolEventAttachmentsArgs = {
  files: Array<Scalars['Upload']>;
  patrolEventId: Scalars['UUID'];
};


export type MutationDeletePatrolEventAttachmentArgs = {
  id: Scalars['UUID'];
};


export type MutationCreatePatrolEventArgs = {
  createPatrolEventData: CreatePatrolEventData;
};


export type MutationUpdatePatrolEventActionArgs = {
  updatePatrolEventActionData: UpdatePatrolEventActionData;
};


export type MutationUpdatePatrolEventDescriptionArgs = {
  updatePatrolEventDescriptionData: UpdatePatrolEventDescriptionData;
};


export type MutationUpdatePatrolEventEventDateArgs = {
  updatePatrolEventEventDateData: UpdatePatrolEventEventDateData;
};


export type MutationUpdatePatrolEventLocationArgs = {
  updatePatrolEventLocationData: UpdatePatrolEventLocationData;
};


export type MutationUpdatePatrolEventNameArgs = {
  updatePatrolEventNameData: UpdatePatrolEventNameData;
};


export type MutationDeletePatrolEventArgs = {
  id: Scalars['UUID'];
};


export type MutationCreatePatrolArgs = {
  createPatrolData: CreatePatrolData;
};


export type MutationUpdatePatrolDescriptionArgs = {
  updatePatrolDescriptionData: UpdatePatrolDescriptionData;
};


export type MutationUpdatePatrolLocationArgs = {
  updatePatrolLocationData: UpdatePatrolLocationData;
};


export type MutationUpdatePatrolEndDateArgs = {
  updatePatrolEndDateData: UpdatePatrolEndDateData;
};


export type MutationUpdatePatrolStartDateArgs = {
  updatePatrolStartDateData: UpdatePatrolStartDateData;
};


export type MutationChangePatrolAssigneesArgs = {
  changePatrolAssigneesData: ChangePatrolAssigneesData;
};


export type MutationDeletePatrolArgs = {
  id: Scalars['UUID'];
};


export type MutationUploadTaskAttachmentArgs = {
  file: Scalars['Upload'];
  taskId: Scalars['UUID'];
};


export type MutationUploadTaskAttachmentsArgs = {
  files: Array<Scalars['Upload']>;
  taskId: Scalars['UUID'];
};


export type MutationDeleteTaskAttachmentArgs = {
  id: Scalars['UUID'];
};


export type MutationCreateTaskArgs = {
  createTaskData: CreateTaskData;
};


export type MutationDeleteTaskArgs = {
  id: Scalars['UUID'];
};


export type MutationUpdateTaskNameArgs = {
  updateTaskNameData: UpdateTaskNameData;
};


export type MutationUpdateTaskDescriptionArgs = {
  updateTaskDescriptionData: UpdateTaskDescriptionData;
};


export type MutationUpdateTaskLocationArgs = {
  updateTaskLocationData: UpdateTaskLocationData;
};


export type MutationUpdateTaskDueDateArgs = {
  updateTaskDueDateData: UpdateTaskDueDateData;
};


export type MutationUpdateTaskReportedDateArgs = {
  updateTaskReportedDateData: UpdateTaskReportedDateData;
};


export type MutationUpdateTasksOrdersArgs = {
  updateTasksOrdersData: Array<UpdateTaskOrderData>;
};


export type MutationUpdateTaskIsArchivedArgs = {
  updateTaskIsArchivedData: UpdateTaskIsArchivedData;
};


export type MutationChangeTaskStatusArgs = {
  changeTaskStatusData: ChangeTaskStatusData;
};


export type MutationChangeTaskCategoriesArgs = {
  changeTaskCategoriesData: ChangeTaskCategoriesData;
};


export type MutationChangeTaskLabelsArgs = {
  changeTaskLabelsData: ChangeTaskLabelsData;
};


export type MutationChangeTaskParentArgs = {
  changeTaskParentData: ChangeTaskParentData;
};


export type MutationChangeTaskAssigneesArgs = {
  changeTaskAssigneesData: ChangeTaskAssigneesData;
};


export type MutationChangeTaskReporterArgs = {
  changeTaskReporterData: ChangeTaskReporterData;
};


export type MutationAddUserToCompanyArgs = {
  addUserToCompanyData: AddUserToCompanyData;
};


export type MutationChangeUserCompanyRoleArgs = {
  changeUserCompanyRoleData: ChangeUserCompanyRoleData;
};


export type MutationDeleteUserCompanyRoleArgs = {
  id: Scalars['UUID'];
};


export type MutationSignInArgs = {
  signInData: SignInData;
};


export type MutationSignUpArgs = {
  signUpData: SignUpData;
};


export type MutationSignUpFromInvitationArgs = {
  signUpFromInvitationData: SignUpFromInvitationData;
};


export type MutationActivateAccountArgs = {
  token: Scalars['String'];
};


export type MutationRequestPasswordResetMailArgs = {
  email: Scalars['EmailAddress'];
};


export type MutationResetPasswordArgs = {
  resetPasswordData: ResetPasswordData;
};


export type MutationUpdateUserArgs = {
  updateUserData: UpdateUserData;
};


export type MutationDeleteUserArgs = {
  email: Scalars['EmailAddress'];
};


export type MutationAddUserToWorkplaceArgs = {
  addUserToWorkplaceData: AddUserToWorkplaceData;
};


export type MutationChangeUserWorkplaceRoleArgs = {
  changeUserWorkplaceRoleData: ChangeUserWorkplaceRoleData;
};


export type MutationDeleteUserWorkplaceRoleArgs = {
  id: Scalars['UUID'];
};


export type MutationCreateWorkplaceArgs = {
  workplaceData: CreateWorkplaceData;
};


export type MutationUpdateWorkplaceArgs = {
  updateWorkplaceData: UpdateWorkplaceData;
};


export type MutationDeleteWorkplaceArgs = {
  deleteWorkplaceData: DeleteWorkplaceData;
};

/** The object that is required as createTaskStatus argument */
export type CreateTaskStatusData = {
  name: Scalars['String'];
  workplaceId: Scalars['UUID'];
  type: TaskStatusType;
};

/** The object that is required as editTaskStatusesOrder argument */
export type UpdateTaskStatusOrderData = {
  id: Scalars['UUID'];
  order: Scalars['Int'];
};

/** The object that is required as createTaskStatus argument */
export type EditTaskStatusData = {
  id: Scalars['UUID'];
  name: Scalars['String'];
  type: TaskStatusType;
};

/** Input object for task category creation */
export type CreateCategoryData = {
  name: Scalars['String'];
  companyId: Scalars['UUID'];
};

/** Input object for task category edit */
export type UpdateCategoryData = {
  name: Scalars['String'];
  categoryId: Scalars['UUID'];
};

/** Input object for task comment creation */
export type CreateCommentData = {
  content: Scalars['String'];
  taskId: Scalars['UUID'];
};

/** Input object for task comment update */
export type UpdateCommentData = {
  id: Scalars['UUID'];
  content: Scalars['String'];
};

/** The object that is required as createCompany argument */
export type CreateCompanyData = {
  address: Scalars['String'];
  city: Scalars['String'];
  country: Country;
  name: Scalars['String'];
  postCode: Scalars['String'];
  vatNumber: Scalars['VatNumber'];
};

/** The object that is required as updateCompany argument */
export type UpdateCompanyData = {
  id: Scalars['UUID'];
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  name?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['VatNumber']>;
};

/** Input object for contact us */
export type ContactUsData = {
  subject: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  fromSite: Scalars['String'];
  captcha: Scalars['String'];
};

/** Input object for task label creation */
export type CreateLabelData = {
  name: Scalars['String'];
  companyId: Scalars['UUID'];
};

/** Input object for task label edit */
export type UpdateLabelData = {
  name: Scalars['String'];
  labelId: Scalars['UUID'];
};


/** Input object for patrol event creation */
export type CreatePatrolEventData = {
  patrolId: Scalars['UUID'];
  eventDate: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
};

/** Input object for patrol event action edit */
export type UpdatePatrolEventActionData = {
  id: Scalars['UUID'];
  action?: Maybe<Scalars['String']>;
};

/** Input object for patrol event description edit */
export type UpdatePatrolEventDescriptionData = {
  id: Scalars['UUID'];
  description?: Maybe<Scalars['String']>;
};

/** Input object for patrol event eventDate edit */
export type UpdatePatrolEventEventDateData = {
  id: Scalars['UUID'];
  eventDate: Scalars['DateTime'];
};

/** Input object for patrol event location edit */
export type UpdatePatrolEventLocationData = {
  id: Scalars['UUID'];
  location?: Maybe<Scalars['String']>;
};

/** Input object for patrol event name edit */
export type UpdatePatrolEventNameData = {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
};

/** Input object for patrol creation */
export type CreatePatrolData = {
  workplaceId: Scalars['UUID'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  assigneesIds?: Maybe<Array<Scalars['UUID']>>;
};

/** Input object for patrol description edit */
export type UpdatePatrolDescriptionData = {
  id: Scalars['UUID'];
  description?: Maybe<Scalars['String']>;
};

/** Input object for patrol location edit */
export type UpdatePatrolLocationData = {
  id: Scalars['UUID'];
  location?: Maybe<Scalars['String']>;
};

/** Input object for patrol end date edit */
export type UpdatePatrolEndDateData = {
  id: Scalars['UUID'];
  endDate?: Maybe<Scalars['DateTime']>;
};

/** Input object for patrol start date edit */
export type UpdatePatrolStartDateData = {
  id: Scalars['UUID'];
  startDate: Scalars['DateTime'];
};

/** Input object for patrol assignees change */
export type ChangePatrolAssigneesData = {
  id: Scalars['UUID'];
  assigneesIds: Array<Scalars['UUID']>;
};

/** Input object for task creation */
export type CreateTaskData = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['UUID']>;
  workplaceId: Scalars['UUID'];
  taskStatusId?: Maybe<Scalars['UUID']>;
  order?: Maybe<Scalars['Int']>;
  categoriesIds?: Maybe<Array<Scalars['UUID']>>;
  labelsIds?: Maybe<Array<Scalars['UUID']>>;
  reportedDate?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  reporterId?: Maybe<Scalars['UUID']>;
  assigneesIds?: Maybe<Array<Scalars['UUID']>>;
  patrolEventId?: Maybe<Scalars['UUID']>;
};

/** Input object for task title edit */
export type UpdateTaskNameData = {
  id: Scalars['UUID'];
  name: Scalars['String'];
};

/** Input object for task description edit */
export type UpdateTaskDescriptionData = {
  id: Scalars['UUID'];
  description?: Maybe<Scalars['String']>;
};

/** Input object for task location edit */
export type UpdateTaskLocationData = {
  id: Scalars['UUID'];
  location?: Maybe<Scalars['String']>;
};

/** Input object for task due date edit */
export type UpdateTaskDueDateData = {
  id: Scalars['UUID'];
  dueDate?: Maybe<Scalars['DateTime']>;
};

/** Input object for task reported date edit */
export type UpdateTaskReportedDateData = {
  id: Scalars['UUID'];
  reportedDate?: Maybe<Scalars['DateTime']>;
};

/** Input object for task order edit */
export type UpdateTaskOrderData = {
  id: Scalars['UUID'];
  order: Scalars['Int'];
};

/** Input object for task isArchived edit */
export type UpdateTaskIsArchivedData = {
  id: Scalars['UUID'];
  isArchived: Scalars['Boolean'];
};

/** Input object for task status change */
export type ChangeTaskStatusData = {
  id: Scalars['UUID'];
  taskStatusId: Scalars['UUID'];
};

/** Input object for task categories change */
export type ChangeTaskCategoriesData = {
  id: Scalars['UUID'];
  categoriesIds: Array<Scalars['UUID']>;
};

/** Input object for task labels change */
export type ChangeTaskLabelsData = {
  id: Scalars['UUID'];
  labelsIds: Array<Scalars['UUID']>;
};

/** Input object for task parent change */
export type ChangeTaskParentData = {
  id: Scalars['UUID'];
  parentId?: Maybe<Scalars['UUID']>;
};

/** Input object for task assignees change */
export type ChangeTaskAssigneesData = {
  id: Scalars['UUID'];
  assigneesIds: Array<Scalars['UUID']>;
};

/** Input object for task location edit */
export type ChangeTaskReporterData = {
  id: Scalars['UUID'];
  reporterId?: Maybe<Scalars['UUID']>;
};

/** The object that is required as addUserToCompany argument */
export type AddUserToCompanyData = {
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  companyId: Scalars['UUID'];
  companyRoleId: Scalars['UUID'];
};

/** The object that is required as changeUserCompanyRole argument */
export type ChangeUserCompanyRoleData = {
  id: Scalars['UUID'];
  companyRoleId: Scalars['UUID'];
};

/** The response object that is returned after successful sign in */
export type SignInResponse = {
  __typename?: 'SignInResponse';
  access_token: Scalars['String'];
  token_type: Scalars['String'];
};

/** The object that is required as sign in argument */
export type SignInData = {
  email: Scalars['EmailAddress'];
  password: Scalars['String'];
};

/** The object that is required as sign up argument */
export type SignUpData = {
  email: Scalars['EmailAddress'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
};

/** The object that is required as sign up from invitation argument */
export type SignUpFromInvitationData = {
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

/** The object that is required as reset password argument */
export type ResetPasswordData = {
  password: Scalars['String'];
  token: Scalars['String'];
};

/** The object that is required as update user argument */
export type UpdateUserData = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
};

/** The object that is required as addUserToWorkplace argument */
export type AddUserToWorkplaceData = {
  email: Scalars['EmailAddress'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  workplaceId: Scalars['UUID'];
  workplaceRoleId: Scalars['UUID'];
};

/** The object that is required as changeUserWorkplaceRole argument */
export type ChangeUserWorkplaceRoleData = {
  id: Scalars['UUID'];
  workplaceRoleId: Scalars['UUID'];
};

/** The object that is required as createWorkplace argument */
export type CreateWorkplaceData = {
  name: Scalars['String'];
  shortName: Scalars['String'];
  companyId: Scalars['UUID'];
};

/** The object that is required as updateWorkplace argument */
export type UpdateWorkplaceData = {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

/** The object that is required as deleteWorkplace argument */
export type DeleteWorkplaceData = {
  id: Scalars['UUID'];
  name: Scalars['String'];
};
