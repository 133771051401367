import { User } from "../generated/types";

export default function generateUserNameString(userObject: Partial<User>): string {
  const { firstName, email, lastName } = userObject;
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  if (email) {
    return email;
  }
  return "";
}
