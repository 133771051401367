import * as Types from '../../../generated/types';

import { TasksListFragment, TaskDetailsFragment, TaskAttachmentFragment, TaskWithoutChildrenAndParentsFragment } from './fragment.generated';
import { gql } from '@apollo/client';
import { TasksListFragmentDoc, TaskDetailsFragmentDoc, TaskAttachmentFragmentDoc, TaskWithoutChildrenAndParentsFragmentDoc } from './fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetTasksListQueryVariables = Types.Exact<{
  filters?: Types.Maybe<Types.TaskFilters>;
  workplaceId: Types.Scalars['UUID'];
}>;


export type GetTasksListQuery = { __typename?: 'Query', getWorkplaceTasks: Array<(
    { __typename?: 'Task' }
    & TasksListFragment
  )> };

export type GetTaskDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type GetTaskDetailsQuery = { __typename?: 'Query', getTask: (
    { __typename?: 'Task' }
    & TaskDetailsFragment
  ) };

export type GetWorkplaceTaskStatusesQueryVariables = Types.Exact<{
  workplaceId: Types.Scalars['UUID'];
}>;


export type GetWorkplaceTaskStatusesQuery = { __typename?: 'Query', getWorkplaceTaskStatuses: Array<{ __typename?: 'TaskStatus', id: any, name: string, order: number, type: Types.TaskStatusType }> };

export type GetWorkplaceCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['UUID'];
}>;


export type GetWorkplaceCategoriesQuery = { __typename?: 'Query', getCategories: Array<{ __typename?: 'Category', id: any, name: string }> };

export type GetWorkplaceLabelsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['UUID'];
}>;


export type GetWorkplaceLabelsQuery = { __typename?: 'Query', getLabels: Array<{ __typename?: 'Label', id: any, name: string }> };

export type GetTaskAttachmentsQueryVariables = Types.Exact<{
  taskId: Types.Scalars['UUID'];
}>;


export type GetTaskAttachmentsQuery = { __typename?: 'Query', getTaskAttachments: Array<(
    { __typename?: 'TaskAttachment' }
    & TaskAttachmentFragment
  )> };

export type GetTaskCommentsQueryVariables = Types.Exact<{
  taskId: Types.Scalars['UUID'];
}>;


export type GetTaskCommentsQuery = { __typename?: 'Query', getTaskComments: Array<{ __typename?: 'Comment', id: any, content: string, createdAt: any, updatedAt: any, author?: Types.Maybe<{ __typename?: 'User', id: any, firstName?: Types.Maybe<string>, lastName?: Types.Maybe<string>, email: any, isActive: boolean }> }> };


export const GetTasksListDocument = gql`
    query GetTasksList($filters: TaskFilters, $workplaceId: UUID!) {
  getWorkplaceTasks(filters: $filters, workplaceId: $workplaceId) {
    ...TasksList
  }
}
    ${TasksListFragmentDoc}`;

/**
 * __useGetTasksListQuery__
 *
 * To run a query within a React component, call `useGetTasksListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      workplaceId: // value for 'workplaceId'
 *   },
 * });
 */
export function useGetTasksListQuery(baseOptions: Apollo.QueryHookOptions<GetTasksListQuery, GetTasksListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTasksListQuery, GetTasksListQueryVariables>(GetTasksListDocument, options);
      }
export function useGetTasksListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTasksListQuery, GetTasksListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTasksListQuery, GetTasksListQueryVariables>(GetTasksListDocument, options);
        }
export type GetTasksListQueryHookResult = ReturnType<typeof useGetTasksListQuery>;
export type GetTasksListLazyQueryHookResult = ReturnType<typeof useGetTasksListLazyQuery>;
export type GetTasksListQueryResult = Apollo.QueryResult<GetTasksListQuery, GetTasksListQueryVariables>;
export const GetTaskDetailsDocument = gql`
    query GetTaskDetails($id: UUID!) {
  getTask(id: $id) {
    ...TaskDetails
  }
}
    ${TaskDetailsFragmentDoc}`;

/**
 * __useGetTaskDetailsQuery__
 *
 * To run a query within a React component, call `useGetTaskDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetTaskDetailsQuery, GetTaskDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskDetailsQuery, GetTaskDetailsQueryVariables>(GetTaskDetailsDocument, options);
      }
export function useGetTaskDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskDetailsQuery, GetTaskDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskDetailsQuery, GetTaskDetailsQueryVariables>(GetTaskDetailsDocument, options);
        }
export type GetTaskDetailsQueryHookResult = ReturnType<typeof useGetTaskDetailsQuery>;
export type GetTaskDetailsLazyQueryHookResult = ReturnType<typeof useGetTaskDetailsLazyQuery>;
export type GetTaskDetailsQueryResult = Apollo.QueryResult<GetTaskDetailsQuery, GetTaskDetailsQueryVariables>;
export const GetWorkplaceTaskStatusesDocument = gql`
    query GetWorkplaceTaskStatuses($workplaceId: UUID!) {
  getWorkplaceTaskStatuses(workplaceId: $workplaceId) {
    id
    name
    order
    type
  }
}
    `;

/**
 * __useGetWorkplaceTaskStatusesQuery__
 *
 * To run a query within a React component, call `useGetWorkplaceTaskStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkplaceTaskStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkplaceTaskStatusesQuery({
 *   variables: {
 *      workplaceId: // value for 'workplaceId'
 *   },
 * });
 */
export function useGetWorkplaceTaskStatusesQuery(baseOptions: Apollo.QueryHookOptions<GetWorkplaceTaskStatusesQuery, GetWorkplaceTaskStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkplaceTaskStatusesQuery, GetWorkplaceTaskStatusesQueryVariables>(GetWorkplaceTaskStatusesDocument, options);
      }
export function useGetWorkplaceTaskStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkplaceTaskStatusesQuery, GetWorkplaceTaskStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkplaceTaskStatusesQuery, GetWorkplaceTaskStatusesQueryVariables>(GetWorkplaceTaskStatusesDocument, options);
        }
export type GetWorkplaceTaskStatusesQueryHookResult = ReturnType<typeof useGetWorkplaceTaskStatusesQuery>;
export type GetWorkplaceTaskStatusesLazyQueryHookResult = ReturnType<typeof useGetWorkplaceTaskStatusesLazyQuery>;
export type GetWorkplaceTaskStatusesQueryResult = Apollo.QueryResult<GetWorkplaceTaskStatusesQuery, GetWorkplaceTaskStatusesQueryVariables>;
export const GetWorkplaceCategoriesDocument = gql`
    query GetWorkplaceCategories($companyId: UUID!) {
  getCategories(companyId: $companyId) {
    id
    name
  }
}
    `;

/**
 * __useGetWorkplaceCategoriesQuery__
 *
 * To run a query within a React component, call `useGetWorkplaceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkplaceCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkplaceCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetWorkplaceCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetWorkplaceCategoriesQuery, GetWorkplaceCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkplaceCategoriesQuery, GetWorkplaceCategoriesQueryVariables>(GetWorkplaceCategoriesDocument, options);
      }
export function useGetWorkplaceCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkplaceCategoriesQuery, GetWorkplaceCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkplaceCategoriesQuery, GetWorkplaceCategoriesQueryVariables>(GetWorkplaceCategoriesDocument, options);
        }
export type GetWorkplaceCategoriesQueryHookResult = ReturnType<typeof useGetWorkplaceCategoriesQuery>;
export type GetWorkplaceCategoriesLazyQueryHookResult = ReturnType<typeof useGetWorkplaceCategoriesLazyQuery>;
export type GetWorkplaceCategoriesQueryResult = Apollo.QueryResult<GetWorkplaceCategoriesQuery, GetWorkplaceCategoriesQueryVariables>;
export const GetWorkplaceLabelsDocument = gql`
    query GetWorkplaceLabels($companyId: UUID!) {
  getLabels(companyId: $companyId) {
    id
    name
  }
}
    `;

/**
 * __useGetWorkplaceLabelsQuery__
 *
 * To run a query within a React component, call `useGetWorkplaceLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkplaceLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkplaceLabelsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetWorkplaceLabelsQuery(baseOptions: Apollo.QueryHookOptions<GetWorkplaceLabelsQuery, GetWorkplaceLabelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkplaceLabelsQuery, GetWorkplaceLabelsQueryVariables>(GetWorkplaceLabelsDocument, options);
      }
export function useGetWorkplaceLabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkplaceLabelsQuery, GetWorkplaceLabelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkplaceLabelsQuery, GetWorkplaceLabelsQueryVariables>(GetWorkplaceLabelsDocument, options);
        }
export type GetWorkplaceLabelsQueryHookResult = ReturnType<typeof useGetWorkplaceLabelsQuery>;
export type GetWorkplaceLabelsLazyQueryHookResult = ReturnType<typeof useGetWorkplaceLabelsLazyQuery>;
export type GetWorkplaceLabelsQueryResult = Apollo.QueryResult<GetWorkplaceLabelsQuery, GetWorkplaceLabelsQueryVariables>;
export const GetTaskAttachmentsDocument = gql`
    query GetTaskAttachments($taskId: UUID!) {
  getTaskAttachments(taskId: $taskId) {
    ...TaskAttachment
  }
}
    ${TaskAttachmentFragmentDoc}`;

/**
 * __useGetTaskAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetTaskAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskAttachmentsQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskAttachmentsQuery(baseOptions: Apollo.QueryHookOptions<GetTaskAttachmentsQuery, GetTaskAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskAttachmentsQuery, GetTaskAttachmentsQueryVariables>(GetTaskAttachmentsDocument, options);
      }
export function useGetTaskAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskAttachmentsQuery, GetTaskAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskAttachmentsQuery, GetTaskAttachmentsQueryVariables>(GetTaskAttachmentsDocument, options);
        }
export type GetTaskAttachmentsQueryHookResult = ReturnType<typeof useGetTaskAttachmentsQuery>;
export type GetTaskAttachmentsLazyQueryHookResult = ReturnType<typeof useGetTaskAttachmentsLazyQuery>;
export type GetTaskAttachmentsQueryResult = Apollo.QueryResult<GetTaskAttachmentsQuery, GetTaskAttachmentsQueryVariables>;
export const GetTaskCommentsDocument = gql`
    query GetTaskComments($taskId: UUID!) {
  getTaskComments(taskId: $taskId) {
    id
    content
    author {
      id
      firstName
      lastName
      email
      isActive
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetTaskCommentsQuery__
 *
 * To run a query within a React component, call `useGetTaskCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskCommentsQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskCommentsQuery(baseOptions: Apollo.QueryHookOptions<GetTaskCommentsQuery, GetTaskCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskCommentsQuery, GetTaskCommentsQueryVariables>(GetTaskCommentsDocument, options);
      }
export function useGetTaskCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskCommentsQuery, GetTaskCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskCommentsQuery, GetTaskCommentsQueryVariables>(GetTaskCommentsDocument, options);
        }
export type GetTaskCommentsQueryHookResult = ReturnType<typeof useGetTaskCommentsQuery>;
export type GetTaskCommentsLazyQueryHookResult = ReturnType<typeof useGetTaskCommentsLazyQuery>;
export type GetTaskCommentsQueryResult = Apollo.QueryResult<GetTaskCommentsQuery, GetTaskCommentsQueryVariables>;