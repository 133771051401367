import { ApolloError } from "@apollo/client";
import { Box, Button, Divider, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import BasicMenuLayout from "../../../layouts/BasicMenuLayout";
import { PatrolEventFragment, PatrolFragment } from "../graphql/fragments.generated";
import PatrolEvents from "./patrolEvents/PatrolEvents";
import PatrolInfo from "./patrolInfo/PatrolInfo";

interface Props {
  loading?: boolean;
  patrolEvents: PatrolEventFragment[];
  patrolData?: PatrolFragment;
  onPatrolEventAdd: () => void;
  patrolDataError?: ApolloError;
  createEventError?: ApolloError;
  finishPatrolError?: ApolloError;
  canUserEdit: boolean;
  onPatrolFinish: () => void;
  isPatrolFinished: boolean;
}
export default function PatrolPageView({
  patrolEvents,
  onPatrolEventAdd,
  loading,
  patrolData,
  patrolDataError,
  canUserEdit,
  onPatrolFinish,
  isPatrolFinished,
  createEventError,
  finishPatrolError,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <BasicMenuLayout loading={loading} workplaceChooseDisabled>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Box width="45%">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={(): void => onPatrolEventAdd()}
          >
            {t("Add event")}
          </Button>
        </Box>
        <Box width="45%">
          <Button
            disabled={isPatrolFinished}
            onClick={() => onPatrolFinish()}
            fullWidth
            variant="outlined"
            color="secondary"
          >
            {t("Finish patrol")}
          </Button>
        </Box>
      </Box>
      <Box mb={2}>
        <PatrolEvents canUserEdit={canUserEdit} patrolEvents={patrolEvents} />
      </Box>
      <Box>
        <Box mb={1}>
          <Typography variant="caption">{t("Patrol information")}</Typography>
          <Divider />
        </Box>

        {patrolData && <PatrolInfo disabled={!canUserEdit} patrolData={patrolData} />}
      </Box>
      <ErrorDisplay
        error={patrolDataError}
        message={t(
          "Error occured while getting patrol data, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={createEventError}
        message={t(
          "Error occured while creating new patrol event, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={finishPatrolError}
        message={t(
          "Error occured while setting patrol's finish date, please try again or contact us."
        )}
      />
    </BasicMenuLayout>
  );
}
