import { CircularProgress, makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box/Box";
import React from "react";
import { black15Opacity } from "../../utils/theme";

const useStyles = makeStyles({
  wrapper: {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100vh",
    width: "100vw",
    zIndex: 1000,
  },
});

export default function FullScreenLoader({
  open,
  bgColor = black15Opacity,
}: {
  open?: boolean;
  bgColor?: string;
}): JSX.Element {
  const classes = useStyles();
  if (open) {
    return (
      <Box
        className={classes.wrapper}
        style={{ backgroundColor: bgColor }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }
  return <div />;
}
