import { Box, IconButton, Typography } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ImageIcon from "@material-ui/icons/Image";
import MovieIcon from "@material-ui/icons/Movie";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import React, { useState } from "react";
import { MimeType } from "../../generated/types";
import ImageDialog from "../ImageDialog/ImageDialog";
import VideoDialog from "../VideoDialog/VideoDialog";

interface Props {
  fileName: string;
  mimetype: MimeType;
  uri: string;
  onDelete: () => void;
  canUserEdit?: boolean;
}

const imageMimeTpes = [
  MimeType.Bmp,
  MimeType.Gif,
  MimeType.Jpeg,
  MimeType.Png,
  MimeType.Tiff,
];

export default function SingleAttachment({
  fileName,
  mimetype,
  uri,
  onDelete,
  canUserEdit,
}: Props): JSX.Element {
  const [openVideoDialog, setOpenVideoDialog] = useState<boolean>(false);
  const [openImageDialog, setOpenImageDialog] = useState<boolean>(false);

  const onPdfDownload = (): void => {
    const link = document.createElement("a");
    link.href = uri;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const itemClicked = () => {
    if (mimetype === MimeType.Pdf) {
      onPdfDownload();
    }
    if (mimetype === MimeType.Mp4) {
      setOpenVideoDialog(true);
    } else {
      setOpenImageDialog(true);
    }
  };

  const getMimeIcon = (): JSX.Element => {
    if (mimetype === MimeType.Pdf) {
      return <PictureAsPdfIcon fontSize="small" />;
    }
    if (mimetype === MimeType.Mp4) {
      return <MovieIcon fontSize="small" />;
    }
    return <ImageIcon fontSize="small" />;
  };

  return (
    <Box display="flex" alignItems="center" mb={0.5}>
      <Box mr={1}>{getMimeIcon()}</Box>

      <Typography
        onClick={() => itemClicked()}
        variant="body2"
        style={{
          width: "80%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {fileName}
      </Typography>
      {canUserEdit && (
        <IconButton size="small" onClick={() => onDelete()}>
          <DeleteForeverIcon fontSize="small" />
        </IconButton>
      )}
      {mimetype === MimeType.Mp4 && (
        <VideoDialog
          onClose={(): void => setOpenVideoDialog(false)}
          open={openVideoDialog}
          videoSrc={uri}
        />
      )}
      {imageMimeTpes.includes(mimetype) && (
        <ImageDialog
          imgSrc={uri}
          onClose={(): void => setOpenImageDialog(false)}
          open={openImageDialog}
        />
      )}
    </Box>
  );
}
