import { ApolloError } from "@apollo/client";
import { Box, CircularProgress } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../../components/ErrorDisplay/ErrorDisplay";
import MultiSelectWithSearch from "../../../../../components/MultiSelectWithSearch/MultiSelectWithSearch";

interface Props {
  categoriesToPick: { id: string }[];
  categories: { id: string }[];
  namesToDisplay: { name: string; optionId: string }[];
  onChange: (value: { id: string }[]) => void;
  loading?: boolean;
  changingCategoryError?: ApolloError;
  fetchingCategoryError?: ApolloError;
  disabled?: boolean;
}
export default function TaskCategoriesView({
  categories,
  categoriesToPick,
  namesToDisplay,
  onChange,
  changingCategoryError,
  disabled,
  fetchingCategoryError,
  loading,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <Box position="relative">
        <MultiSelectWithSearch<{ id: string }>
          size="small"
          options={categoriesToPick}
          pickedValues={categories}
          namesToDisplay={namesToDisplay}
          label={t("Categories")}
          placeholder={t("+ Add categories")}
          onChange={onChange}
          disabled={disabled || loading}
        />
        {loading && (
          <Box position="absolute" top="50%" left="50%">
            <CircularProgress size={20} />
          </Box>
        )}
      </Box>
      <ErrorDisplay
        error={changingCategoryError}
        message={t(
          "Error occured while changing categories, please try again or contact us."
        )}
      />
      <ErrorDisplay
        error={fetchingCategoryError}
        message={t(
          "Error occured while loading categories, please try again or contact us."
        )}
      />
    </>
  );
}
