import { yupResolver } from "@hookform/resolvers/yup";
import { TFunction } from "i18next";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useAuthToken } from "../../../utils/AuthUtils/authToken";
import { emailValidator, requiredStringValidator } from "../../../utils/formValidators";
import { useSignInMutation } from "../graphql/mutation.generated";
import { SignInFormData } from "./SignInFormData";
import SignInPageView from "./SignInPageView";

const schema = (t: TFunction): yup.AnyObjectSchema =>
  yup.object().shape({
    email: emailValidator(t),
    password: requiredStringValidator(t),
  });

export default function SignInPage(): JSX.Element {
  const { t } = useTranslation();
  const setAuthToken = useAuthToken()[1];
  const history = useHistory();

  const formMethods = useForm<SignInFormData>({
    resolver: yupResolver(schema(t)),
  });

  const [signInMutation, { loading, error }] = useSignInMutation();

  const onFormSubmit = async (data: SignInFormData): Promise<void> => {
    try {
      const res = await signInMutation({
        variables: {
          request: data,
        },
      });
      if (res.data?.signIn.access_token) {
        setAuthToken(res.data.signIn.access_token);
        history.push("/");
      }
    } catch (err) {
      //
    }
  };

  return (
    <SignInPageView
      formMethods={formMethods}
      onFormSubmit={onFormSubmit}
      loading={loading}
      error={error}
    />
  );
}
