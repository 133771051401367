import React from "react";
import { useDeletePatrolEventMutation } from "../../../../graphql/mutation.generated";
import PatrolEventMoreMenuView from "./PatrolEventMoreMenuView";

interface Props {
  patrolEventId: string;
}

export default function PatrolEventMoreMenu({ patrolEventId }: Props): JSX.Element {
  const [deletePatrolEventMutation, { error, loading }] = useDeletePatrolEventMutation();

  const onPatrolEventDelete = async (): Promise<void> => {
    try {
      await deletePatrolEventMutation({
        variables: {
          id: patrolEventId,
        },
        update: (cache, res) => {
          if (res.data?.deletePatrolEvent) {
            cache.modify({
              fields: {
                getPatrolEvents(existingEvents = [], { readField }) {
                  return existingEvents.filter(
                    (eventRef) => patrolEventId !== readField("id", eventRef)
                  );
                },
              },
            });
          }
        },
      });
    } catch (e) {
      //
    }
  };
  return (
    <PatrolEventMoreMenuView
      onPatrolEventDelete={onPatrolEventDelete}
      error={error}
      loading={loading}
    />
  );
}
