import React from "react";
import { PatrolFragment } from "../../graphql/fragments.generated";
import PatrolInfoView from "./PatrolInfoView";

interface Props {
  patrolData: PatrolFragment;
  disabled?: boolean;
}
export default function PatrolInfo({ patrolData, disabled }: Props): JSX.Element {
  const { id, startDate, assignees, description, endDate, location } = patrolData;
  return (
    <PatrolInfoView
      patrolId={id}
      startDate={startDate}
      assignees={assignees?.map((a) => a.id) || []}
      description={description || ""}
      endDate={endDate}
      location={location || ""}
      disabled={disabled}
    />
  );
}
