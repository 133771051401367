import { gql } from "@apollo/client";

export const listPatrolFragment = gql`
  fragment ListPatrol on Patrol {
    id
    startDate
    endDate
  }
`;

export const patrolEventFragment = gql`
  fragment PatrolEvent on PatrolEvent {
    id
    eventDate
    location
    name
    description
    action
    createdAt
    updatedAt
  }
`;

export const patrolFragment = gql`
  ${patrolEventFragment}
  fragment Patrol on Patrol {
    id
    startDate
    endDate
    description
    location
    assignees {
      id
      firstName
      lastName
      email
    }
    createdBy {
      id
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
`;
