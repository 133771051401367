import React, { useEffect, useState } from "react";
import { useUpdateTaskLocationMutation } from "../../../graphql/mutation.generated";
import TaskLocationView from "./TaskLocationView";

interface Props {
  taskId: string;
  location?: string;
  disabled: boolean;
}
export default function TaskLocation({ location, taskId, disabled }: Props): JSX.Element {
  const [locationState, setLocationState] = useState<string>(location || "");

  useEffect(() => {
    if (location) {
      setLocationState(location);
    }
  }, [location]);

  const [updateTaskLocationMutation, { error }] = useUpdateTaskLocationMutation();

  const changeLocationState = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setLocationState(event.target.value);
  };

  const onBlur = async (): Promise<void> => {
    if (locationState !== location) {
      try {
        await updateTaskLocationMutation({
          variables: {
            updateTaskLocationData: {
              id: taskId,
              location: locationState,
            },
          },
        });
      } catch (e) {
        //
      }
    }
  };
  return (
    <TaskLocationView
      error={error}
      changeLocationState={changeLocationState}
      location={locationState}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
}
