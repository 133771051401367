import * as Types from '../../../generated/types';

import { TaskAttachmentFragment, TaskWithoutChildrenAndParentsFragment } from './fragment.generated';
import { gql } from '@apollo/client';
import { TaskAttachmentFragmentDoc, TaskWithoutChildrenAndParentsFragmentDoc } from './fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ChangeTaskStatusMutationVariables = Types.Exact<{
  changeTaskStatusData: Types.ChangeTaskStatusData;
}>;


export type ChangeTaskStatusMutation = { __typename?: 'Mutation', changeTaskStatus: { __typename?: 'Task', id: any, finishedDate?: Types.Maybe<any>, status: { __typename?: 'TaskStatus', id: any, name: string, type: Types.TaskStatusType } } };

export type ChangeTaskAssigneesMutationVariables = Types.Exact<{
  changeTaskAssigneesData: Types.ChangeTaskAssigneesData;
}>;


export type ChangeTaskAssigneesMutation = { __typename?: 'Mutation', changeTaskAssignees: { __typename?: 'Task', id: any, assignees: Array<{ __typename?: 'User', id: any }> } };

export type UpdateTaskDescriptionMutationVariables = Types.Exact<{
  updateTaskDescriptionData: Types.UpdateTaskDescriptionData;
}>;


export type UpdateTaskDescriptionMutation = { __typename?: 'Mutation', updateTaskDescription: { __typename?: 'Task', id: any, description?: Types.Maybe<string> } };

export type UpdateTaskReportedDateMutationVariables = Types.Exact<{
  updateTaskReportedDateData: Types.UpdateTaskReportedDateData;
}>;


export type UpdateTaskReportedDateMutation = { __typename?: 'Mutation', updateTaskReportedDate: { __typename?: 'Task', id: any, reportedDate?: Types.Maybe<any> } };

export type UpdateTaskNameMutationVariables = Types.Exact<{
  updateTaskNameData: Types.UpdateTaskNameData;
}>;


export type UpdateTaskNameMutation = { __typename?: 'Mutation', updateTaskName: { __typename?: 'Task', id: any, name: string } };

export type UpdateTaskLocationMutationVariables = Types.Exact<{
  updateTaskLocationData: Types.UpdateTaskLocationData;
}>;


export type UpdateTaskLocationMutation = { __typename?: 'Mutation', updateTaskLocation: { __typename?: 'Task', id: any, location?: Types.Maybe<string> } };

export type UpdateTaskDueDateMutationVariables = Types.Exact<{
  updateTaskDueDateData: Types.UpdateTaskDueDateData;
}>;


export type UpdateTaskDueDateMutation = { __typename?: 'Mutation', updateTaskDueDate: { __typename?: 'Task', id: any, dueDate?: Types.Maybe<any> } };

export type ChangeTaskReporterMutationVariables = Types.Exact<{
  changeTaskReporterData: Types.ChangeTaskReporterData;
}>;


export type ChangeTaskReporterMutation = { __typename?: 'Mutation', changeTaskReporter: { __typename?: 'Task', id: any, reporter?: Types.Maybe<{ __typename?: 'User', id: any }> } };

export type ChangeTaskCategoriesMutationVariables = Types.Exact<{
  changeTaskCategoriesData: Types.ChangeTaskCategoriesData;
}>;


export type ChangeTaskCategoriesMutation = { __typename?: 'Mutation', changeTaskCategories: { __typename?: 'Task', id: any, categories: Array<{ __typename?: 'Category', id: any }> } };

export type ChangeTaskLabelsMutationVariables = Types.Exact<{
  changeTaskLabelsData: Types.ChangeTaskLabelsData;
}>;


export type ChangeTaskLabelsMutation = { __typename?: 'Mutation', changeTaskLabels: { __typename?: 'Task', id: any, labels: Array<{ __typename?: 'Label', id: any }> } };

export type UploadTaskAttachmentsMutationVariables = Types.Exact<{
  files: Array<Types.Scalars['Upload']> | Types.Scalars['Upload'];
  taskId: Types.Scalars['UUID'];
}>;


export type UploadTaskAttachmentsMutation = { __typename?: 'Mutation', uploadTaskAttachments: Array<(
    { __typename?: 'TaskAttachment' }
    & TaskAttachmentFragment
  )> };

export type DeleteTaskAttachmentMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteTaskAttachmentMutation = { __typename?: 'Mutation', deleteTaskAttachment: boolean };

export type CreateCommentMutationVariables = Types.Exact<{
  createCommentData: Types.CreateCommentData;
}>;


export type CreateCommentMutation = { __typename?: 'Mutation', createComment: { __typename?: 'Comment', id: any, content: string, createdAt: any, updatedAt: any, author?: Types.Maybe<{ __typename?: 'User', id: any, firstName?: Types.Maybe<string>, lastName?: Types.Maybe<string>, email: any, isActive: boolean }> } };

export type UpdateCommentMutationVariables = Types.Exact<{
  updateCommentData: Types.UpdateCommentData;
}>;


export type UpdateCommentMutation = { __typename?: 'Mutation', updateComment: { __typename?: 'Comment', id: any, content: string, updatedAt: any } };

export type CreateTaskMutationVariables = Types.Exact<{
  createTaskData: Types.CreateTaskData;
}>;


export type CreateTaskMutation = { __typename?: 'Mutation', createTask: (
    { __typename?: 'Task' }
    & TaskWithoutChildrenAndParentsFragment
  ) };

export type DeleteCommentMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteCommentMutation = { __typename?: 'Mutation', deleteComment: boolean };

export type DeleteTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type DeleteTaskMutation = { __typename?: 'Mutation', deleteTask: boolean };

export type UpdateTaskIsArchivedMutationVariables = Types.Exact<{
  updateTaskIsArchivedData: Types.UpdateTaskIsArchivedData;
}>;


export type UpdateTaskIsArchivedMutation = { __typename?: 'Mutation', updateTaskIsArchived: { __typename?: 'Task', id: any, isArchived: boolean } };


export const ChangeTaskStatusDocument = gql`
    mutation ChangeTaskStatus($changeTaskStatusData: ChangeTaskStatusData!) {
  changeTaskStatus(changeTaskStatusData: $changeTaskStatusData) {
    id
    status {
      id
      name
      type
    }
    finishedDate
  }
}
    `;
export type ChangeTaskStatusMutationFn = Apollo.MutationFunction<ChangeTaskStatusMutation, ChangeTaskStatusMutationVariables>;

/**
 * __useChangeTaskStatusMutation__
 *
 * To run a mutation, you first call `useChangeTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTaskStatusMutation, { data, loading, error }] = useChangeTaskStatusMutation({
 *   variables: {
 *      changeTaskStatusData: // value for 'changeTaskStatusData'
 *   },
 * });
 */
export function useChangeTaskStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTaskStatusMutation, ChangeTaskStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTaskStatusMutation, ChangeTaskStatusMutationVariables>(ChangeTaskStatusDocument, options);
      }
export type ChangeTaskStatusMutationHookResult = ReturnType<typeof useChangeTaskStatusMutation>;
export type ChangeTaskStatusMutationResult = Apollo.MutationResult<ChangeTaskStatusMutation>;
export type ChangeTaskStatusMutationOptions = Apollo.BaseMutationOptions<ChangeTaskStatusMutation, ChangeTaskStatusMutationVariables>;
export const ChangeTaskAssigneesDocument = gql`
    mutation ChangeTaskAssignees($changeTaskAssigneesData: ChangeTaskAssigneesData!) {
  changeTaskAssignees(changeTaskAssigneesData: $changeTaskAssigneesData) {
    id
    assignees {
      id
    }
  }
}
    `;
export type ChangeTaskAssigneesMutationFn = Apollo.MutationFunction<ChangeTaskAssigneesMutation, ChangeTaskAssigneesMutationVariables>;

/**
 * __useChangeTaskAssigneesMutation__
 *
 * To run a mutation, you first call `useChangeTaskAssigneesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTaskAssigneesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTaskAssigneesMutation, { data, loading, error }] = useChangeTaskAssigneesMutation({
 *   variables: {
 *      changeTaskAssigneesData: // value for 'changeTaskAssigneesData'
 *   },
 * });
 */
export function useChangeTaskAssigneesMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTaskAssigneesMutation, ChangeTaskAssigneesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTaskAssigneesMutation, ChangeTaskAssigneesMutationVariables>(ChangeTaskAssigneesDocument, options);
      }
export type ChangeTaskAssigneesMutationHookResult = ReturnType<typeof useChangeTaskAssigneesMutation>;
export type ChangeTaskAssigneesMutationResult = Apollo.MutationResult<ChangeTaskAssigneesMutation>;
export type ChangeTaskAssigneesMutationOptions = Apollo.BaseMutationOptions<ChangeTaskAssigneesMutation, ChangeTaskAssigneesMutationVariables>;
export const UpdateTaskDescriptionDocument = gql`
    mutation UpdateTaskDescription($updateTaskDescriptionData: UpdateTaskDescriptionData!) {
  updateTaskDescription(updateTaskDescriptionData: $updateTaskDescriptionData) {
    id
    description
  }
}
    `;
export type UpdateTaskDescriptionMutationFn = Apollo.MutationFunction<UpdateTaskDescriptionMutation, UpdateTaskDescriptionMutationVariables>;

/**
 * __useUpdateTaskDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateTaskDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskDescriptionMutation, { data, loading, error }] = useUpdateTaskDescriptionMutation({
 *   variables: {
 *      updateTaskDescriptionData: // value for 'updateTaskDescriptionData'
 *   },
 * });
 */
export function useUpdateTaskDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskDescriptionMutation, UpdateTaskDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskDescriptionMutation, UpdateTaskDescriptionMutationVariables>(UpdateTaskDescriptionDocument, options);
      }
export type UpdateTaskDescriptionMutationHookResult = ReturnType<typeof useUpdateTaskDescriptionMutation>;
export type UpdateTaskDescriptionMutationResult = Apollo.MutationResult<UpdateTaskDescriptionMutation>;
export type UpdateTaskDescriptionMutationOptions = Apollo.BaseMutationOptions<UpdateTaskDescriptionMutation, UpdateTaskDescriptionMutationVariables>;
export const UpdateTaskReportedDateDocument = gql`
    mutation UpdateTaskReportedDate($updateTaskReportedDateData: UpdateTaskReportedDateData!) {
  updateTaskReportedDate(updateTaskReportedDateData: $updateTaskReportedDateData) {
    id
    reportedDate
  }
}
    `;
export type UpdateTaskReportedDateMutationFn = Apollo.MutationFunction<UpdateTaskReportedDateMutation, UpdateTaskReportedDateMutationVariables>;

/**
 * __useUpdateTaskReportedDateMutation__
 *
 * To run a mutation, you first call `useUpdateTaskReportedDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskReportedDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskReportedDateMutation, { data, loading, error }] = useUpdateTaskReportedDateMutation({
 *   variables: {
 *      updateTaskReportedDateData: // value for 'updateTaskReportedDateData'
 *   },
 * });
 */
export function useUpdateTaskReportedDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskReportedDateMutation, UpdateTaskReportedDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskReportedDateMutation, UpdateTaskReportedDateMutationVariables>(UpdateTaskReportedDateDocument, options);
      }
export type UpdateTaskReportedDateMutationHookResult = ReturnType<typeof useUpdateTaskReportedDateMutation>;
export type UpdateTaskReportedDateMutationResult = Apollo.MutationResult<UpdateTaskReportedDateMutation>;
export type UpdateTaskReportedDateMutationOptions = Apollo.BaseMutationOptions<UpdateTaskReportedDateMutation, UpdateTaskReportedDateMutationVariables>;
export const UpdateTaskNameDocument = gql`
    mutation updateTaskName($updateTaskNameData: UpdateTaskNameData!) {
  updateTaskName(updateTaskNameData: $updateTaskNameData) {
    id
    name
  }
}
    `;
export type UpdateTaskNameMutationFn = Apollo.MutationFunction<UpdateTaskNameMutation, UpdateTaskNameMutationVariables>;

/**
 * __useUpdateTaskNameMutation__
 *
 * To run a mutation, you first call `useUpdateTaskNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskNameMutation, { data, loading, error }] = useUpdateTaskNameMutation({
 *   variables: {
 *      updateTaskNameData: // value for 'updateTaskNameData'
 *   },
 * });
 */
export function useUpdateTaskNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskNameMutation, UpdateTaskNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskNameMutation, UpdateTaskNameMutationVariables>(UpdateTaskNameDocument, options);
      }
export type UpdateTaskNameMutationHookResult = ReturnType<typeof useUpdateTaskNameMutation>;
export type UpdateTaskNameMutationResult = Apollo.MutationResult<UpdateTaskNameMutation>;
export type UpdateTaskNameMutationOptions = Apollo.BaseMutationOptions<UpdateTaskNameMutation, UpdateTaskNameMutationVariables>;
export const UpdateTaskLocationDocument = gql`
    mutation UpdateTaskLocation($updateTaskLocationData: UpdateTaskLocationData!) {
  updateTaskLocation(updateTaskLocationData: $updateTaskLocationData) {
    id
    location
  }
}
    `;
export type UpdateTaskLocationMutationFn = Apollo.MutationFunction<UpdateTaskLocationMutation, UpdateTaskLocationMutationVariables>;

/**
 * __useUpdateTaskLocationMutation__
 *
 * To run a mutation, you first call `useUpdateTaskLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskLocationMutation, { data, loading, error }] = useUpdateTaskLocationMutation({
 *   variables: {
 *      updateTaskLocationData: // value for 'updateTaskLocationData'
 *   },
 * });
 */
export function useUpdateTaskLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskLocationMutation, UpdateTaskLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskLocationMutation, UpdateTaskLocationMutationVariables>(UpdateTaskLocationDocument, options);
      }
export type UpdateTaskLocationMutationHookResult = ReturnType<typeof useUpdateTaskLocationMutation>;
export type UpdateTaskLocationMutationResult = Apollo.MutationResult<UpdateTaskLocationMutation>;
export type UpdateTaskLocationMutationOptions = Apollo.BaseMutationOptions<UpdateTaskLocationMutation, UpdateTaskLocationMutationVariables>;
export const UpdateTaskDueDateDocument = gql`
    mutation UpdateTaskDueDate($updateTaskDueDateData: UpdateTaskDueDateData!) {
  updateTaskDueDate(updateTaskDueDateData: $updateTaskDueDateData) {
    id
    dueDate
  }
}
    `;
export type UpdateTaskDueDateMutationFn = Apollo.MutationFunction<UpdateTaskDueDateMutation, UpdateTaskDueDateMutationVariables>;

/**
 * __useUpdateTaskDueDateMutation__
 *
 * To run a mutation, you first call `useUpdateTaskDueDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskDueDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskDueDateMutation, { data, loading, error }] = useUpdateTaskDueDateMutation({
 *   variables: {
 *      updateTaskDueDateData: // value for 'updateTaskDueDateData'
 *   },
 * });
 */
export function useUpdateTaskDueDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskDueDateMutation, UpdateTaskDueDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskDueDateMutation, UpdateTaskDueDateMutationVariables>(UpdateTaskDueDateDocument, options);
      }
export type UpdateTaskDueDateMutationHookResult = ReturnType<typeof useUpdateTaskDueDateMutation>;
export type UpdateTaskDueDateMutationResult = Apollo.MutationResult<UpdateTaskDueDateMutation>;
export type UpdateTaskDueDateMutationOptions = Apollo.BaseMutationOptions<UpdateTaskDueDateMutation, UpdateTaskDueDateMutationVariables>;
export const ChangeTaskReporterDocument = gql`
    mutation ChangeTaskReporter($changeTaskReporterData: ChangeTaskReporterData!) {
  changeTaskReporter(changeTaskReporterData: $changeTaskReporterData) {
    id
    reporter {
      id
    }
  }
}
    `;
export type ChangeTaskReporterMutationFn = Apollo.MutationFunction<ChangeTaskReporterMutation, ChangeTaskReporterMutationVariables>;

/**
 * __useChangeTaskReporterMutation__
 *
 * To run a mutation, you first call `useChangeTaskReporterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTaskReporterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTaskReporterMutation, { data, loading, error }] = useChangeTaskReporterMutation({
 *   variables: {
 *      changeTaskReporterData: // value for 'changeTaskReporterData'
 *   },
 * });
 */
export function useChangeTaskReporterMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTaskReporterMutation, ChangeTaskReporterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTaskReporterMutation, ChangeTaskReporterMutationVariables>(ChangeTaskReporterDocument, options);
      }
export type ChangeTaskReporterMutationHookResult = ReturnType<typeof useChangeTaskReporterMutation>;
export type ChangeTaskReporterMutationResult = Apollo.MutationResult<ChangeTaskReporterMutation>;
export type ChangeTaskReporterMutationOptions = Apollo.BaseMutationOptions<ChangeTaskReporterMutation, ChangeTaskReporterMutationVariables>;
export const ChangeTaskCategoriesDocument = gql`
    mutation ChangeTaskCategories($changeTaskCategoriesData: ChangeTaskCategoriesData!) {
  changeTaskCategories(changeTaskCategoriesData: $changeTaskCategoriesData) {
    id
    categories {
      id
    }
  }
}
    `;
export type ChangeTaskCategoriesMutationFn = Apollo.MutationFunction<ChangeTaskCategoriesMutation, ChangeTaskCategoriesMutationVariables>;

/**
 * __useChangeTaskCategoriesMutation__
 *
 * To run a mutation, you first call `useChangeTaskCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTaskCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTaskCategoriesMutation, { data, loading, error }] = useChangeTaskCategoriesMutation({
 *   variables: {
 *      changeTaskCategoriesData: // value for 'changeTaskCategoriesData'
 *   },
 * });
 */
export function useChangeTaskCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTaskCategoriesMutation, ChangeTaskCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTaskCategoriesMutation, ChangeTaskCategoriesMutationVariables>(ChangeTaskCategoriesDocument, options);
      }
export type ChangeTaskCategoriesMutationHookResult = ReturnType<typeof useChangeTaskCategoriesMutation>;
export type ChangeTaskCategoriesMutationResult = Apollo.MutationResult<ChangeTaskCategoriesMutation>;
export type ChangeTaskCategoriesMutationOptions = Apollo.BaseMutationOptions<ChangeTaskCategoriesMutation, ChangeTaskCategoriesMutationVariables>;
export const ChangeTaskLabelsDocument = gql`
    mutation ChangeTaskLabels($changeTaskLabelsData: ChangeTaskLabelsData!) {
  changeTaskLabels(changeTaskLabelsData: $changeTaskLabelsData) {
    id
    labels {
      id
    }
  }
}
    `;
export type ChangeTaskLabelsMutationFn = Apollo.MutationFunction<ChangeTaskLabelsMutation, ChangeTaskLabelsMutationVariables>;

/**
 * __useChangeTaskLabelsMutation__
 *
 * To run a mutation, you first call `useChangeTaskLabelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTaskLabelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTaskLabelsMutation, { data, loading, error }] = useChangeTaskLabelsMutation({
 *   variables: {
 *      changeTaskLabelsData: // value for 'changeTaskLabelsData'
 *   },
 * });
 */
export function useChangeTaskLabelsMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTaskLabelsMutation, ChangeTaskLabelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTaskLabelsMutation, ChangeTaskLabelsMutationVariables>(ChangeTaskLabelsDocument, options);
      }
export type ChangeTaskLabelsMutationHookResult = ReturnType<typeof useChangeTaskLabelsMutation>;
export type ChangeTaskLabelsMutationResult = Apollo.MutationResult<ChangeTaskLabelsMutation>;
export type ChangeTaskLabelsMutationOptions = Apollo.BaseMutationOptions<ChangeTaskLabelsMutation, ChangeTaskLabelsMutationVariables>;
export const UploadTaskAttachmentsDocument = gql`
    mutation UploadTaskAttachments($files: [Upload!]!, $taskId: UUID!) {
  uploadTaskAttachments(files: $files, taskId: $taskId) {
    ...TaskAttachment
  }
}
    ${TaskAttachmentFragmentDoc}`;
export type UploadTaskAttachmentsMutationFn = Apollo.MutationFunction<UploadTaskAttachmentsMutation, UploadTaskAttachmentsMutationVariables>;

/**
 * __useUploadTaskAttachmentsMutation__
 *
 * To run a mutation, you first call `useUploadTaskAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadTaskAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadTaskAttachmentsMutation, { data, loading, error }] = useUploadTaskAttachmentsMutation({
 *   variables: {
 *      files: // value for 'files'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useUploadTaskAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<UploadTaskAttachmentsMutation, UploadTaskAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadTaskAttachmentsMutation, UploadTaskAttachmentsMutationVariables>(UploadTaskAttachmentsDocument, options);
      }
export type UploadTaskAttachmentsMutationHookResult = ReturnType<typeof useUploadTaskAttachmentsMutation>;
export type UploadTaskAttachmentsMutationResult = Apollo.MutationResult<UploadTaskAttachmentsMutation>;
export type UploadTaskAttachmentsMutationOptions = Apollo.BaseMutationOptions<UploadTaskAttachmentsMutation, UploadTaskAttachmentsMutationVariables>;
export const DeleteTaskAttachmentDocument = gql`
    mutation DeleteTaskAttachment($id: UUID!) {
  deleteTaskAttachment(id: $id)
}
    `;
export type DeleteTaskAttachmentMutationFn = Apollo.MutationFunction<DeleteTaskAttachmentMutation, DeleteTaskAttachmentMutationVariables>;

/**
 * __useDeleteTaskAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteTaskAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskAttachmentMutation, { data, loading, error }] = useDeleteTaskAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskAttachmentMutation, DeleteTaskAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskAttachmentMutation, DeleteTaskAttachmentMutationVariables>(DeleteTaskAttachmentDocument, options);
      }
export type DeleteTaskAttachmentMutationHookResult = ReturnType<typeof useDeleteTaskAttachmentMutation>;
export type DeleteTaskAttachmentMutationResult = Apollo.MutationResult<DeleteTaskAttachmentMutation>;
export type DeleteTaskAttachmentMutationOptions = Apollo.BaseMutationOptions<DeleteTaskAttachmentMutation, DeleteTaskAttachmentMutationVariables>;
export const CreateCommentDocument = gql`
    mutation CreateComment($createCommentData: CreateCommentData!) {
  createComment(createCommentData: $createCommentData) {
    id
    content
    author {
      id
      firstName
      lastName
      email
      isActive
    }
    createdAt
    updatedAt
  }
}
    `;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      createCommentData: // value for 'createCommentData'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const UpdateCommentDocument = gql`
    mutation UpdateComment($updateCommentData: UpdateCommentData!) {
  updateComment(updateCommentData: $updateCommentData) {
    id
    content
    updatedAt
  }
}
    `;
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      updateCommentData: // value for 'updateCommentData'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, options);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<UpdateCommentMutation, UpdateCommentMutationVariables>;
export const CreateTaskDocument = gql`
    mutation CreateTask($createTaskData: CreateTaskData!) {
  createTask(createTaskData: $createTaskData) {
    ...TaskWithoutChildrenAndParents
  }
}
    ${TaskWithoutChildrenAndParentsFragmentDoc}`;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      createTaskData: // value for 'createTaskData'
 *   },
 * });
 */
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($id: UUID!) {
  deleteComment(id: $id)
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const DeleteTaskDocument = gql`
    mutation DeleteTask($id: UUID!) {
  deleteTask(id: $id)
}
    `;
export type DeleteTaskMutationFn = Apollo.MutationFunction<DeleteTaskMutation, DeleteTaskMutationVariables>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument, options);
      }
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const UpdateTaskIsArchivedDocument = gql`
    mutation UpdateTaskIsArchived($updateTaskIsArchivedData: UpdateTaskIsArchivedData!) {
  updateTaskIsArchived(updateTaskIsArchivedData: $updateTaskIsArchivedData) {
    id
    isArchived
  }
}
    `;
export type UpdateTaskIsArchivedMutationFn = Apollo.MutationFunction<UpdateTaskIsArchivedMutation, UpdateTaskIsArchivedMutationVariables>;

/**
 * __useUpdateTaskIsArchivedMutation__
 *
 * To run a mutation, you first call `useUpdateTaskIsArchivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskIsArchivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskIsArchivedMutation, { data, loading, error }] = useUpdateTaskIsArchivedMutation({
 *   variables: {
 *      updateTaskIsArchivedData: // value for 'updateTaskIsArchivedData'
 *   },
 * });
 */
export function useUpdateTaskIsArchivedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskIsArchivedMutation, UpdateTaskIsArchivedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskIsArchivedMutation, UpdateTaskIsArchivedMutationVariables>(UpdateTaskIsArchivedDocument, options);
      }
export type UpdateTaskIsArchivedMutationHookResult = ReturnType<typeof useUpdateTaskIsArchivedMutation>;
export type UpdateTaskIsArchivedMutationResult = Apollo.MutationResult<UpdateTaskIsArchivedMutation>;
export type UpdateTaskIsArchivedMutationOptions = Apollo.BaseMutationOptions<UpdateTaskIsArchivedMutation, UpdateTaskIsArchivedMutationVariables>;