import React, { useEffect, useMemo, useState } from "react";
import { useDeleteTaskAttachmentMutation } from "../../graphql/mutation.generated";
import { useGetTaskAttachmentsLazyQuery } from "../../graphql/query.generated";
import TaskAttachmentsView from "./TaskAttachmentsView";

interface Props {
  taskId: string;
  canUserEdit: boolean;
}
export default function TaskAttachments({ taskId, canUserEdit }: Props): JSX.Element {
  const [attachmentsOpen, setAttachmentsOpen] = useState(false);

  const [
    getAttachementsQuery,
    {
      data: attachmentsData,
      loading: attachmentsLoading,
      error: attachmentsFetchingError,
    },
  ] = useGetTaskAttachmentsLazyQuery();

  const [
    deleteTaskAttachmentMutation,
    { error: deleteTaskAttachmentError, loading: deleteAttachmentLoading },
  ] = useDeleteTaskAttachmentMutation();

  useEffect(() => {
    if (taskId && attachmentsOpen) {
      getAttachementsQuery({ variables: { taskId } });
    }
  }, [attachmentsOpen, getAttachementsQuery, taskId]);

  const onAttachmentDelete = async (taskAttachmentId: string): Promise<void> => {
    try {
      await deleteTaskAttachmentMutation({
        variables: { id: taskAttachmentId },
        update: (cache, res) => {
          if (res.data?.deleteTaskAttachment) {
            cache.modify({
              fields: {
                getTaskAttachments(existingAttachments = [], { readField }) {
                  return existingAttachments.filter(
                    (attachmentRef) => taskAttachmentId !== readField("id", attachmentRef)
                  );
                },
              },
            });
          }
        },
      });
    } catch (e) {
      //
    }
  };

  const parsedAttachmentsData = useMemo(() => {
    if (attachmentsData) {
      return attachmentsData.getTaskAttachments.map((a) => ({
        attachment: a.attachment,
        attachmentWrapperId: a.id,
      }));
    }
    return undefined;
  }, [attachmentsData]);

  return (
    <TaskAttachmentsView
      attachmentsOpened={attachmentsOpen}
      canUserEdit={canUserEdit}
      changeAttachmentsOpen={(): void => setAttachmentsOpen(!attachmentsOpen)}
      onAttachmentDelete={onAttachmentDelete}
      attachmentsData={parsedAttachmentsData}
      attachmentsFetchingError={attachmentsFetchingError}
      deleteAttachmentError={deleteTaskAttachmentError}
      loading={attachmentsLoading || deleteAttachmentLoading}
    />
  );
}
