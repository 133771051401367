import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { QueryVariablesEnum } from "../../../../interfaces/QueryVariables";
import { TASKS, TASK_DETAILS, WORKPLACE } from "../../../../routing/paths";
import {
  useDeleteTaskMutation,
  useUpdateTaskIsArchivedMutation,
} from "../../graphql/mutation.generated";
import TaskMoreMenuView from "./TaskMoreMenuView";

interface Props {
  taskId: string;
  parentTaskId?: string;
  workplaceId?: string;
  isTaskArchived: boolean;
  canUserEditTask: boolean;
  canUserArchiveTask: boolean;
}
export default function TaskMoreMenu({
  taskId,
  parentTaskId,
  workplaceId,
  isTaskArchived,
  canUserArchiveTask,
  canUserEditTask,
}: Props): JSX.Element {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [
    deleteTaskMutation,
    { error: deleteTaskError, loading: deleteTaskLoading },
  ] = useDeleteTaskMutation();

  const [
    archiveTaskMutation,
    { loading: archiveTaskLoading, error: archiveTaskError },
  ] = useUpdateTaskIsArchivedMutation();

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const onDeleteTask = async (): Promise<void> => {
    try {
      const res = await deleteTaskMutation({
        variables: { id: taskId },
        update: (cache, response) => {
          if (response?.data?.deleteTask) {
            if (parentTaskId) {
              cache.modify({
                id: cache.identify({ __typename: "Task", id: parentTaskId }),
                fields: {
                  children(existingChildren = [], { readField }) {
                    return existingChildren.filter(
                      (taskRef) => taskId !== readField("id", taskRef)
                    );
                  },
                },
              });
            } else {
              cache.modify({
                fields: {
                  getWorkplaceTasks(existingTasks = [], { readField }) {
                    return existingTasks.filter(
                      (taskRef) => taskId !== readField("id", taskRef)
                    );
                  },
                },
              });
            }
          }
        },
      });
      if (res) {
        handleClose();
        onTaskDeleted();
      }
    } catch (e) {
      //
    }
  };

  const onTaskDeleted = () => {
    if (workplaceId) {
      if (parentTaskId) {
        history.push(
          `${WORKPLACE}${TASK_DETAILS}?${QueryVariablesEnum.WORKPLACEID}=${workplaceId}&${QueryVariablesEnum.TASKID}=${parentTaskId}`
        );
      } else {
        history.push(
          `${WORKPLACE}${TASKS}?${QueryVariablesEnum.WORKPLACEID}=${workplaceId}`
        );
      }
    }
  };

  const onArchiveChange = async (): Promise<void> => {
    try {
      await archiveTaskMutation({
        variables: {
          updateTaskIsArchivedData: {
            id: taskId,
            isArchived: !isTaskArchived,
          },
        },
        update: (cache, res) => {
          if (res.data?.updateTaskIsArchived.isArchived) {
            let archivedTaskRef;
            cache.modify({
              fields: {
                getWorkplaceTasks(existingTasks = [], { readField }) {
                  archivedTaskRef = existingTasks.find(
                    (taskRef) => taskId === readField("id", taskRef)
                  );
                  return existingTasks.filter(
                    (taskRef) => taskId !== readField("id", taskRef)
                  );
                },
              },
            });
            cache.modify({
              fields: {
                getWorkplaceArchivedTasks(existingArchivedTasks = {}) {
                  if (
                    archivedTaskRef &&
                    existingArchivedTasks.items &&
                    existingArchivedTasks.items.length
                  ) {
                    return {
                      ...existingArchivedTasks,
                      items: [archivedTaskRef, ...existingArchivedTasks.items],
                    };
                  }
                  return existingArchivedTasks;
                },
              },
            });
          }

          if (
            res.data?.updateTaskIsArchived &&
            !res.data.updateTaskIsArchived.isArchived
          ) {
            let unArchivedTaskRef;
            cache.modify({
              fields: {
                getWorkplaceArchivedTasks(existingArchivedTasks, { readField }) {
                  unArchivedTaskRef = existingArchivedTasks.items.find(
                    (taskRef) => taskId === readField("id", taskRef)
                  );
                  return {
                    ...existingArchivedTasks,
                    items: existingArchivedTasks.items.filter(
                      (taskRef) => taskId !== readField("id", taskRef)
                    ),
                  };
                },
              },
            });
            cache.modify({
              fields: {
                getWorkplaceTasks(existingTasks = []) {
                  if (unArchivedTaskRef) {
                    return [...existingTasks, unArchivedTaskRef];
                  }
                  return existingTasks;
                },
              },
            });
          }
        },
      });
    } catch (e) {
      //
    }
  };

  return (
    <TaskMoreMenuView
      anchorEl={anchorEl}
      handleButtonClick={handleButtonClick}
      handleClose={handleClose}
      isTaskArchived={isTaskArchived}
      onArchiveTask={onArchiveChange}
      onDeleteTask={onDeleteTask}
      deleteTaskError={deleteTaskError}
      loading={archiveTaskLoading || deleteTaskLoading}
      archiveTaskError={archiveTaskError}
      canUserArchiveTask={canUserArchiveTask}
      canUserEditTask={canUserEditTask}
    />
  );
}
