import { ApolloError } from "@apollo/client";
import { Box, IconButton } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../components/ErrorDisplay/ErrorDisplay";
import SuccessDisplay from "../../../../components/SuccessDisplay/SuccessDisplay";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import theme, { black100Opacity } from "../../../../utils/theme";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import LoadingSnackbar from "../../../../components/LoadingSnackbar/LoadingSnackbar";
import CommentIcon from "@material-ui/icons/Comment";

interface Props {
  onFileAdd: (event: React.ChangeEvent<HTMLInputElement>) => void;
  addAttachmentSuccess: boolean;
  closeAttachmentAddSuccess: () => void;
  uploadFileError?: ApolloError;
  loading?: boolean;
  onMessagesOpen: () => void;
  canUserEditAttachments: boolean;
}
export default function ActionButtonsView({
  addAttachmentSuccess,
  closeAttachmentAddSuccess,
  onFileAdd,
  uploadFileError,
  loading,
  onMessagesOpen,
  canUserEditAttachments,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        bgcolor={theme.palette.primary.main}
        width="100%"
        position="fixed"
        bottom="0"
        px={2}
        boxSizing="border-box"
        height={theme.spacing(8)}
      >
        <Box>
          <label htmlFor="camera-file">
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="camera-file"
              type="file"
              capture="camera"
              onChange={onFileAdd}
            />
            <IconButton component="span" disabled={!canUserEditAttachments}>
              <AddAPhotoIcon fontSize="large" htmlColor={black100Opacity} />
            </IconButton>
          </label>
        </Box>
        <Box>
          <label htmlFor="file">
            <input
              style={{ display: "none" }}
              id="file"
              type="file"
              accept="image/*,video/*,audio/*"
              onChange={onFileAdd}
            />
            <IconButton component="span" disabled={!canUserEditAttachments}>
              <AddPhotoAlternateIcon fontSize="large" htmlColor={black100Opacity} />
            </IconButton>
          </label>
        </Box>
        <Box>
          <IconButton onClick={(): void => onMessagesOpen()}>
            <CommentIcon fontSize="large" htmlColor={black100Opacity} />
          </IconButton>
        </Box>
      </Box>
      <SuccessDisplay
        message={t("File added.")}
        open={addAttachmentSuccess}
        onClose={closeAttachmentAddSuccess}
        autoHideDuration={4000}
      />
      <LoadingSnackbar open={Boolean(loading)} />
      <ErrorDisplay
        error={uploadFileError}
        message={t(
          "Error occured while uploading files, please try again or contact us."
        )}
      />
    </>
  );
}
