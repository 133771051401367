import { ApolloError } from "@apollo/client";
import { useContext, useEffect, useMemo } from "react";
import { WorkplaceContext } from "../context/WorkplaceContext/WorkplaceContextProvider";
import { WorkplaceUserFragment } from "./graphql/fragment.generated";
import { useGetWorkplaceUsersLazyQuery } from "./graphql/query.generated";

interface ReturnObject {
  loading?: boolean;
  error?: ApolloError;
  workplaceUsers?: WorkplaceUserFragment[];
}

export default function useGetWorkplaceUsers(): ReturnObject {
  const { chosenWorkplaceId } = useContext(WorkplaceContext);

  const [getWorkplaceUsers, { data, loading, error }] = useGetWorkplaceUsersLazyQuery();

  useEffect(() => {
    if (chosenWorkplaceId) {
      getWorkplaceUsers({
        variables: { workplaceId: chosenWorkplaceId, includeInactive: true },
      });
    }
  }, [chosenWorkplaceId, getWorkplaceUsers]);

  const workplaceUsers = useMemo<WorkplaceUserFragment[] | undefined>(() => {
    if (data) {
      return data.getWorkplaceUsers.filter((user) => user.firstName && user.lastName);
    }
    return undefined;
  }, [data]);

  return {
    workplaceUsers,
    loading,
    error,
  };
}
