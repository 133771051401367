import { ApolloError } from "@apollo/client";
import { Box, TextField, IconButton } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import ErrorDisplay from "../../../../../components/ErrorDisplay/ErrorDisplay";
import SendIcon from "@material-ui/icons/Send";

interface Props {
  onCommentSubmit: (event: React.ChangeEvent<HTMLFormElement>) => void;
  commentContent: string;
  onCommentChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  createCommentLoading?: boolean;
  createCommentError?: ApolloError;
}
export default function CreateCommentView({
  commentContent,
  onCommentChange,
  onCommentSubmit,
  createCommentError,
  createCommentLoading,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Box>
      <form onSubmit={onCommentSubmit}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px={1}
          bgcolor="#fff"
        >
          <Box width="90%">
            <TextField
              fullWidth
              value={commentContent}
              onChange={onCommentChange}
              disabled={createCommentLoading}
              variant="outlined"
              placeholder={t("New comment content")}
              label={t("Add new comment")}
              multiline
              size="small"
            />
          </Box>
          <Box width="8%">
            <IconButton type="submit" color="primary" size="small">
              <SendIcon />
            </IconButton>
          </Box>
        </Box>
      </form>
      <ErrorDisplay
        error={createCommentError}
        message={t(
          "Error occured while adding your comment, please try again or contact us."
        )}
      />
    </Box>
  );
}
