import React from "react";
import CustomSnackbar from "../CustomSnackbar/CustomSnackbar";

interface Props {
  message: string;
  open?: boolean;
  onClose: () => void;
  autoHideDuration?: number;
}

export default function SuccessDisplay({
  message,
  open,
  onClose,
  autoHideDuration,
}: Props): JSX.Element {
  if (open) {
    return (
      <CustomSnackbar
        open
        message={message}
        severity="success"
        handleClose={(): void => onClose()}
        autoHideDuration={autoHideDuration}
      />
    );
  }
  return <div />;
}
